export { OrderDirection as ContactBooksOrderModes } from '@or-sdk/contacts';
export const SpecialCharactersRegexp = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
export var ContactBooksViewModes;
(function (ContactBooksViewModes) {
    ContactBooksViewModes["GRID"] = "GRID";
    ContactBooksViewModes["TABLE"] = "TABLE";
})(ContactBooksViewModes || (ContactBooksViewModes = {}));
export var SchemaItemTypes;
(function (SchemaItemTypes) {
    SchemaItemTypes["ARRAY_OF_OBJECTS"] = "array of objects";
    SchemaItemTypes["ARRAY_OF_STRINGS"] = "array of strings";
    SchemaItemTypes["ARRAY_OF_IDENTIFIERS"] = "array of identifiers";
    SchemaItemTypes["STRING"] = "string";
    SchemaItemTypes["IDENTIFIER_OBJECT"] = "identifier object";
    SchemaItemTypes["MD"] = "md";
    SchemaItemTypes["INTEGER"] = "integer";
    SchemaItemTypes["DOUBLE"] = "double";
    SchemaItemTypes["DATE"] = "date";
})(SchemaItemTypes || (SchemaItemTypes = {}));
export var ArrayOfObjectPropertyTypes;
(function (ArrayOfObjectPropertyTypes) {
    ArrayOfObjectPropertyTypes["STRING"] = "string";
    ArrayOfObjectPropertyTypes["ARRAY"] = "array";
})(ArrayOfObjectPropertyTypes || (ArrayOfObjectPropertyTypes = {}));
export var ExportFormats;
(function (ExportFormats) {
    ExportFormats["CSV"] = "csv";
    ExportFormats["JSON"] = "json";
})(ExportFormats || (ExportFormats = {}));
export var CompositeColumnKeys;
(function (CompositeColumnKeys) {
    CompositeColumnKeys["NAME"] = "composite_name";
})(CompositeColumnKeys || (CompositeColumnKeys = {}));
export var SharingState;
(function (SharingState) {
    SharingState["WAITING"] = "waiting";
    SharingState["SHARED"] = "shared";
    SharingState["DELETED_FROM_TARGET"] = "deleted from target";
    SharingState["CANCELED"] = "canceled";
})(SharingState || (SharingState = {}));
export var BookSharingState;
(function (BookSharingState) {
    BookSharingState["NOT_SHARED"] = "not shared";
    BookSharingState["NEED_CONFIRMATION"] = "need confirmation";
    BookSharingState["SHARED"] = "shared";
    BookSharingState["DELETED_FROM_SOURCE"] = "Deleted form source account";
    BookSharingState["CANCELED"] = "Canceled by owner";
})(BookSharingState || (BookSharingState = {}));
export var MigrationsStatus;
(function (MigrationsStatus) {
    MigrationsStatus["UNDEFINED"] = "UNDEFINED";
    MigrationsStatus["IN_PENDING"] = "IN_PENDING";
    MigrationsStatus["RESOLVED"] = "RESOLVED";
    MigrationsStatus["FAILED"] = "FAILED";
    MigrationsStatus["TO_DO"] = "TO_DO";
    MigrationsStatus["NOT_STARTED"] = "NOT_STARTED";
})(MigrationsStatus || (MigrationsStatus = {}));

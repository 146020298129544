import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
    class: "contact-book-schema-editor flex flex-col gap-[24px] items-stretch",
    "data-test": "contact-book-schema-editor"
};
const _hoisted_2 = { class: "w-full flex flex-col gap-md" };
const _hoisted_3 = { class: "add-row flex gap-md items-center w-full" };
const _hoisted_4 = { class: "w-full flex flex-col gap-md" };
const _hoisted_5 = { class: "add-column flex flex-col gap-sm items-center w-full" };
const _hoisted_6 = { class: "add-row flex gap-md items-center w-full" };
const _hoisted_7 = { class: "typography-body-2-bold" };
const _hoisted_8 = { class: "typography-body-2-bold" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_books_schema_editor_preset_item = _resolveComponent("contact-books-schema-editor-preset-item");
    const _component_or_select = _resolveComponent("or-select");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_expansion_panel = _resolveComponent("or-expansion-panel");
    const _component_contact_books_schema_editor_item = _resolveComponent("contact-books-schema-editor-item");
    const _component_or_icon = _resolveComponent("or-icon");
    const _component_i18n_t = _resolveComponent("i18n-t");
    const _component_OrConfirmV3 = _resolveComponent("OrConfirmV3");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_or_expansion_panel, {
            ref: "fieldGroupsExpansionPanelRef",
            class: "gap-md",
            label: _ctx.$t('contactBooks.schemaEditor.fieldGroups')
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newSchemaPresets, (schemaPreset, index) => {
                        return (_openBlock(), _createBlock(_component_contact_books_schema_editor_preset_item, {
                            key: schemaPreset.id,
                            "schema-preset": schemaPreset,
                            onDelete: ($event) => (_ctx.handlePresetDelete(index)),
                            onUnlink: ($event) => (_ctx.handlePresetUnlink(index))
                        }, null, 8, ["schema-preset", "onDelete", "onUnlink"]));
                    }), 128)),
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_or_select, {
                            ref: "schemaPresetSelectRef",
                            modelValue: _ctx.schemaPresetIdToAdd,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.schemaPresetIdToAdd) = $event)),
                            class: "add-preset-select grow min-w-0",
                            label: _ctx.$t('contactBooks.schemaEditor.fieldGroup'),
                            options: _ctx.defaultPresetsOptionsToAdd,
                            placeholder: _ctx.$t('contactBooks.schemaEditor.enterFieldGroupName'),
                            "enable-search": "",
                            "search-function": _ctx.searchSchemaPresetsOptions
                        }, null, 8, ["modelValue", "label", "options", "placeholder", "search-function"]),
                        _createVNode(_component_or_button, {
                            class: "add-button !mt-[22px]",
                            variant: "outlined",
                            color: "primary",
                            disabled: !_ctx.schemaPresetIdToAdd,
                            onClick: _ctx.handleAddSchemaPreset
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('common.add')), 1)
                            ]),
                            _: 1
                        }, 8, ["disabled", "onClick"])
                    ])
                ])
            ]),
            _: 1
        }, 8, ["label"]),
        _createVNode(_component_or_expansion_panel, {
            ref: "fieldsExpansionPanelRef",
            class: "gap-md",
            label: _ctx.$t('contactBooks.schemaEditor.fields')
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newFieldSchemas, (fieldSchema, index) => {
                        var _a;
                        return (_openBlock(), _createBlock(_component_contact_books_schema_editor_item, {
                            key: _ctx.getOrCreateTemporaryKey(fieldSchema),
                            "all-items": _ctx.newFieldSchemas,
                            "field-schema": fieldSchema,
                            "editor-items": _ctx.editorItems,
                            "is-deletable": _ctx.checkIsFieldSchemaDeletable(fieldSchema),
                            "undeletable-properties": (_a = _ctx.requiredFieldsProperties) === null || _a === void 0 ? void 0 : _a[fieldSchema.id],
                            "schema-presets": _ctx.fieldSchemasIdsToPresetsNames[fieldSchema.id],
                            onDelete: ($event) => (_ctx.handleFieldDelete(index)),
                            onUpdate: ($event) => (_ctx.updateField(index, $event))
                        }, null, 8, ["all-items", "field-schema", "editor-items", "is-deletable", "undeletable-properties", "schema-presets", "onDelete", "onUpdate"]));
                    }), 128)),
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_or_select, {
                                ref: "fieldSchemaSelectRef",
                                modelValue: _ctx.fieldSchemaIdToAdd,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.fieldSchemaIdToAdd) = $event)),
                                class: "add-column-select grow min-w-0",
                                label: _ctx.$t('contactBooks.schemaEditor.fieldName'),
                                options: _ctx.defaultFieldSchemasToAddOptions,
                                placeholder: _ctx.$t('contactBooks.schemaEditor.enterFieldName'),
                                "enable-search": "",
                                "search-function": _ctx.searchFieldSchemasOptions
                            }, {
                                noSearchResultsTemplate: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('contactBooks.schemaEditor.wantToCreateNewField')), 1)
                                ]),
                                _: 1
                            }, 8, ["modelValue", "label", "options", "placeholder", "search-function"]),
                            _createVNode(_component_or_button, {
                                class: "add-button !mt-[22px]",
                                variant: "outlined",
                                color: "primary",
                                disabled: !_ctx.fieldSchemaIdToAdd,
                                onClick: _ctx.handleAddColumn
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('common.add')), 1)
                                ]),
                                _: 1
                            }, 8, ["disabled", "onClick"])
                        ]),
                        _createVNode(_component_or_button, {
                            class: "create-button !px-none self-start",
                            variant: "text",
                            "data-test": "create-new-field-button",
                            onClick: _ctx.handleCreateNewColumn
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_or_icon, { icon: "add" }),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('contactBooks.schemaEditor.createNewField')), 1)
                            ]),
                            _: 1
                        }, 8, ["onClick"])
                    ])
                ])
            ]),
            _: 1
        }, 8, ["label"]),
        _createVNode(_component_OrConfirmV3, {
            ref: "deleteFieldGroupConfirmRef",
            "title-text": _ctx.$t('contactBooks.schemaEditor.deleteFieldGroupTitle'),
            "confirm-button-text": _ctx.$t('common.delete'),
            "cancel-button-text": _ctx.$t('common.cancel'),
            "confirm-type": "d",
            onConfirm: _ctx.deleteSelectedPreset,
            onClose: _cache[2] || (_cache[2] = ($event) => (_ctx.presetIndexToDelete = undefined))
        }, {
            default: _withCtx(() => [
                _createVNode(_component_i18n_t, {
                    keypath: "contactBooks.schemaEditor.deleteFieldGroupText",
                    scope: "global",
                    tag: "span",
                    class: "typography-body-2-regular"
                }, {
                    name: _withCtx(() => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.fieldGroupToDelete), 1)
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["title-text", "confirm-button-text", "cancel-button-text", "onConfirm"]),
        _createVNode(_component_OrConfirmV3, {
            ref: "deleteFieldConfirmRef",
            "title-text": _ctx.$t('contactBooks.schemaEditor.deleteFieldTitle'),
            "confirm-button-text": _ctx.$t('common.delete'),
            "cancel-button-text": _ctx.$t('common.cancel'),
            "confirm-type": "d",
            onConfirm: _ctx.deleteSelectedField,
            onClose: _cache[3] || (_cache[3] = ($event) => (_ctx.fieldIndexToDelete = undefined))
        }, {
            default: _withCtx(() => [
                _createVNode(_component_i18n_t, {
                    keypath: "contactBooks.schemaEditor.deleteFieldText",
                    scope: "global",
                    tag: "span",
                    class: "typography-body-2-regular"
                }, {
                    name: _withCtx(() => [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.fieldToDelete), 1)
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["title-text", "confirm-button-text", "cancel-button-text", "onConfirm"])
    ]));
}

import _ from 'lodash';
import { defineStore } from 'pinia';
import { ContactsApiV2 } from '@/api';
export const useFieldSchemasStore = defineStore('fieldSchemas', {
    state: () => ({
        items: [],
        total: null,
        paginationPage: 1,
        paginationLimit: 20,
    }),
    getters: {
        getFieldSchemaById: (state) => {
            return (id) => {
                return _.find(state.items, { id });
            };
        },
        getFieldSchemaByLabel: (state) => {
            return (label) => {
                return _.find(state.items, { label });
            };
        },
    },
    actions: {
        async getFieldSchemas() {
            try {
                const resp = await ContactsApiV2.fieldSchemaApi.listSchemas({
                    size: this.paginationLimit,
                    from: (this.paginationPage - 1) * this.paginationLimit,
                });
                this.total = resp.total;
                this.items = [...this.items, ...resp.items];
            }
            catch (error) {
                console.error('Error fetching field schemas:', error);
            }
        },
        async fetchAllFieldSchemas() {
            this.resetStore();
            let moreItems = true;
            while (moreItems) {
                await this.getFieldSchemas();
                moreItems = this.items.length < this.total;
                if (moreItems) {
                    this.paginationPage += 1;
                }
            }
        },
        async addFieldSchema(payload) {
            await ContactsApiV2.fieldSchemaApi.createSchema(payload);
            this.resetStore();
            await this.fetchAllFieldSchemas();
        },
        async deleteFieldSchema(id) {
            await ContactsApiV2.fieldSchemaApi.deleteSchema(id);
            this.resetStore();
            await this.fetchAllFieldSchemas();
        },
        _updateFieldSchema(data) {
            const index = _.findIndex(this.items, { id: data.id });
            if (index > -1) {
                this.items[index] = data;
            }
            else {
                this.items.push(data);
            }
        },
        async getFieldSchema(id) {
            const data = await ContactsApiV2.fieldSchemaApi.getSchema(id);
            this._updateFieldSchema(data);
        },
        async updateFieldSchema(id, dto) {
            const data = await ContactsApiV2.fieldSchemaApi.updateSchema(id, dto);
            this._updateFieldSchema(data);
        },
        resetStore() {
            this.items = [];
            this.paginationPage = 1;
        },
    },
});
export default useFieldSchemasStore;

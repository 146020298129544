import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "typography-body-2-bold" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_i18n_t = _resolveComponent("i18n-t");
    const _component_OrConfirmV3 = _resolveComponent("OrConfirmV3");
    return (_openBlock(), _createBlock(_component_OrConfirmV3, {
        ref: "confirm",
        "title-text": _ctx.$t('contactBooks.shareModal.title'),
        "confirm-button-text": _ctx.$t('common.approve'),
        "deny-button-text": _ctx.$t('common.cancel'),
        "confirm-button-color": "primary",
        "auto-close-on-confirm": false,
        loading: _ctx.isLoading
    }, {
        default: _withCtx(() => [
            _createVNode(_component_i18n_t, {
                keypath: "contactBooks.shareModal.text",
                scope: "global",
                tag: "span"
            }, {
                account: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.accountName), 1)
                ]),
                book: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.bookName), 1)
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["title-text", "confirm-button-text", "deny-button-text", "loading"]));
}

import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsTableDataCellDate',
    props: {
        contact: {
            type: Object,
            required: true,
        },
        columnKey: {
            type: String,
            required: true,
        },
    },
    computed: {
        value() {
            const value = this.contact[this.columnKey];
            return value ? new Date(value) : null;
        },
        text() {
            return this.value
                ? this.value.toLocaleString('en-US', { timeZone: 'UTC' })
                : '';
        },
    },
});

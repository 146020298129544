import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "typography-body-2-regular" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrTextV3 = _resolveComponent("OrTextV3");
    const _component_OrTooltipV3 = _resolveComponent("OrTooltipV3");
    const _component_i18n_t = _resolveComponent("i18n-t");
    const _component_OrConfirmV3 = _resolveComponent("OrConfirmV3");
    return (_openBlock(), _createBlock(_component_OrConfirmV3, {
        ref: "confirm",
        type: "c",
        "title-text": _ctx.$t('contactBooks.shareModal.title'),
        "confirm-button-text": _ctx.$t('common.approve'),
        "cancel-button-text": _ctx.$t('common.cancel'),
        loading: _ctx.isLoading,
        "auto-close-on-confirm": false
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_i18n_t, {
                    keypath: "contactBooks.shareModal.text",
                    scope: "global",
                    tag: "span"
                }, {
                    account: _withCtx(() => [
                        _createVNode(_component_OrTooltipV3, { content: _ctx.accountName }, {
                            default: _withCtx(() => [
                                _createVNode(_component_OrTextV3, {
                                    class: "typography-body-2-bold",
                                    text: _ctx.accountName
                                }, null, 8, ["text"])
                            ]),
                            _: 1
                        }, 8, ["content"])
                    ]),
                    book: _withCtx(() => [
                        _createVNode(_component_OrTooltipV3, { content: _ctx.bookName }, {
                            default: _withCtx(() => [
                                _createVNode(_component_OrTextV3, {
                                    class: "typography-body-2-bold",
                                    text: _ctx.bookName
                                }, null, 8, ["text"])
                            ]),
                            _: 1
                        }, 8, ["content"])
                    ]),
                    _: 1
                })
            ])
        ]),
        _: 1
    }, 8, ["title-text", "confirm-button-text", "cancel-button-text", "loading"]));
}

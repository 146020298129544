import { OrInputV3 as OrInput } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import _ from 'lodash';
import { defineComponent, ref } from 'vue';
import ContactBooksSchemaEditor from '../../ContactBooksSchemaEditor/V2';
export default defineComponent({
    name: 'ContactBooksSettingsForm',
    components: {
        ContactBooksSchemaEditor,
        OrInput,
    },
    props: {
        bookName: {
            type: String,
            required: false,
            default: '',
        },
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        allFieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        schemaPresets: {
            type: Array,
            required: false,
            default: () => [],
        },
        bookSchemaPresets: {
            type: Array,
            required: false,
            default: () => [],
        },
        requiredFields: {
            type: Array,
            required: false,
            default: () => [],
        },
        requiredFieldsProperties: {
            type: Object,
            required: false,
            default: () => { },
        },
    },
    emits: ['submit'],
    setup() {
        const bookNameTextbox = ref();
        const schemaEditor = ref();
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            bookNameTextbox,
            schemaEditor,
            v$,
        };
    },
    data(props) {
        return {
            localBookName: props.bookName,
        };
    },
    validations() {
        return {
            localBookName: {
                required,
                minLengthValue: minLength(3),
            },
            notChanged: () => { var _a; return this.localBookName !== this.bookName || ((_a = this.schemaEditor) === null || _a === void 0 ? void 0 : _a.isChanged); },
        };
    },
    watch: {
        bookName(newVal, oldVal) {
            if (this.localBookName === oldVal)
                this.localBookName = newVal;
        },
    },
    methods: {
        onBookNameBlur() {
            this.localBookName = _.trim(this.localBookName);
            this.v$.localBookName.$touch();
        },
        submit() {
            var _a, _b;
            this.$emit('submit', (_a = this.schemaEditor) === null || _a === void 0 ? void 0 : _a.newFieldSchemas, (_b = this.schemaEditor) === null || _b === void 0 ? void 0 : _b.newSchemaPresets, this.localBookName);
        },
        scrollToFirstInvalidItem() {
            var _a, _b, _c;
            if (this.v$.localBookName.$invalid) {
                (_b = (_a = this.bookNameTextbox) === null || _a === void 0 ? void 0 : _a.$el) === null || _b === void 0 ? void 0 : _b.scrollIntoView({ behavior: 'smooth' });
            }
            else {
                return (_c = this.schemaEditor) === null || _c === void 0 ? void 0 : _c.scrollToFirstInvalidItem();
            }
        },
    },
});

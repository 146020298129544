import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex justify-end gap-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_form = _resolveComponent("contact-form");
    const _component_or_button = _resolveComponent("or-button");
    const _component_OrModalV3 = _resolveComponent("OrModalV3");
    return (_openBlock(), _createBlock(_component_OrModalV3, {
        ref: "modalComponent",
        "data-test": "edit-contact-modal",
        size: "s"
    }, {
        header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contacts.editModal.title')), 1)
        ]),
        footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_button, {
                    "data-test": "cancel-edit-contact-button",
                    type: "outline",
                    content: _ctx.$t('common.cancel'),
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('close')))
                }, null, 8, ["content"]),
                _createVNode(_component_or_button, {
                    "data-test": "update-contact-button",
                    "is-loading": _ctx.isSubmitting,
                    "is-disabled": !_ctx.isChanged,
                    content: _ctx.$t('common.update'),
                    onClick: _ctx.triggerSubmit
                }, null, 8, ["is-loading", "is-disabled", "content", "onClick"])
            ])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_contact_form, {
                ref: "contactForm",
                schema: _ctx.schema,
                "default-value": _ctx.contact,
                onSubmit: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update', $event)))
            }, null, 8, ["schema", "default-value"])
        ]),
        _: 1
    }, 512));
}

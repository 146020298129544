import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-6ecf8cd5"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "contact-book-settings-form",
    "data-test": "contact-book-settings-form"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ContactBooksSchemaEditor = _resolveComponent("ContactBooksSchemaEditor");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ContactBooksSchemaEditor, {
            ref: "schemaEditor",
            schema: _ctx.book.schema,
            "group-mapping": _ctx.groupMapping,
            "init-triggers": _ctx.initTriggers,
            "required-fields": _ctx.requiredFields,
            "required-fields-properties": _ctx.requiredFieldsProperties
        }, null, 8, ["schema", "group-mapping", "init-triggers", "required-fields", "required-fields-properties"])
    ]));
}

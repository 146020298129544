import { CompositeColumnKeys } from '@/constants';
function getCompositeColumnsMapping() {
    return {
        [CompositeColumnKeys.NAME]: {
            key: CompositeColumnKeys.NAME,
            label: 'Name',
            parts: ['profileImage', 'firstName', 'lastName'],
            columnToOrderBy: 'firstName',
        },
    };
}
export default getCompositeColumnsMapping;

import { OrIconButtonV3, OrMenuV3, OrMenuItemV3 } from '@onereach/ui-components';
import { defineComponent, ref, computed } from 'vue';
import { BookSharingState } from '@/constants';
export default defineComponent({
    name: 'ContactBookOverflowMenu',
    components: {
        OrIconButtonV3,
        OrMenuV3,
        OrMenuItemV3,
    },
    props: {
        isReadonly: {
            type: Boolean,
            default: false,
        },
        shareStatus: {
            type: String,
            default: '',
        },
    },
    emits: ['delete', 'deleteWithContacts', 'open'],
    setup(props) {
        const menuTrigger = ref();
        const menu = ref();
        const canDeleteWithContacts = computed(() => {
            return props.shareStatus == BookSharingState.NOT_SHARED;
        });
        return {
            menuTrigger,
            canDeleteWithContacts,
            menu,
        };
    },
});

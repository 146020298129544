import { OrInputV3 as OrInput } from '@onereach/ui-components';
import { debounce } from 'lodash';
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
    name: 'DebounceInput',
    components: {
        OrInput,
    },
    inheritAttrs: false,
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        delay: {
            type: Number,
            default: 300,
        },
    },
    setup(props, { emit }) {
        const inputValue = ref(props.modelValue);
        const debouncedInput = debounce((value) => {
            emit('update:debouncedModelValue', value);
        }, props.delay);
        const onInput = (event) => {
            event.preventDefault();
            debouncedInput(event.target.value);
        };
        watch(() => props.modelValue, (newVal) => {
            inputValue.value = newVal;
        });
        return {
            inputValue,
            onInput,
        };
    },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex gap-sm items-center min-w-[20px] ml-sm" };
const _hoisted_2 = { class: "actions flex gap-sm text-outline dark:text-outline-dark" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_tags_container = _resolveComponent("or-tags-container");
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    const _component_contact_books_schema_editor_item_content = _resolveComponent("contact-books-schema-editor-item-content");
    const _component_or_expansion_panel = _resolveComponent("or-expansion-panel");
    return (_openBlock(), _createBlock(_component_or_expansion_panel, {
        ref: "expansionPanelRef",
        class: "schema-item-container",
        "data-test": "schema-editor-item",
        label: _ctx.fieldSchema.label ? _ctx.fieldSchema.label : _ctx.$t('contactBooks.schemaEditor.newColumn'),
        color: _ctx.v$.$error ? 'danger' : 'default',
        variant: "card"
    }, {
        addon: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_tags_container, {
                    class: "presets",
                    tags: _ctx.schemaPresets
                }, null, 8, ["tags"]),
                _createElementVNode("div", _hoisted_2, [
                    (!_ctx.isAllowToEdit && _ctx.isChanged)
                        ? (_openBlock(), _createBlock(_component_or_icon_button, {
                            key: 0,
                            "data-test": "schema-editor-item-restore-button",
                            icon: "restore",
                            color: "inherit",
                            disabled: !_ctx.isChanged,
                            onClick: _ctx.reset
                        }, null, 8, ["disabled", "onClick"]))
                        : _createCommentVNode("", true),
                    (_ctx.isDeletable || !_ctx.isAllowToEdit)
                        ? (_openBlock(), _createBlock(_component_or_icon_button, {
                            key: 1,
                            "data-test": "schema-editor-delete-item-icon",
                            tooltip: _ctx.$tc('contactBooks.schemaEditor.delete'),
                            icon: "delete",
                            color: "inherit",
                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('delete')))
                        }, null, 8, ["tooltip"]))
                        : _createCommentVNode("", true)
                ])
            ])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_contact_books_schema_editor_item_content, _mergeProps({ ref: "itemContentRef" }, _ctx.$props, {
                class: "body py-md",
                onUpdate: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('update', $event)))
            }), null, 16)
        ]),
        _: 1
    }, 8, ["label", "color"]));
}

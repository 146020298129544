import { OrCardV3 as OrCard } from '@onereach/ui-components';
import { computed, defineComponent } from 'vue';
import { BookSharingState } from '@/constants';
import { getBookSharingStateV2 } from '@/utils';
import ContactBooksStatus from '../ContactBooksStatus.vue';
import ContactBookOverflowMenu from './ContactBookOverflowMenuV2.vue';
export default defineComponent({
    name: 'ContactBooksListItemV2',
    components: {
        OrCard,
        ContactBooksStatus,
        ContactBookOverflowMenu,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        isReadonly: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['delete', 'deleteWithContacts', 'needConfirmation', 'open'],
    setup(props) {
        const bookSharingState = computed(() => getBookSharingStateV2(props.item));
        const needConfirmation = computed(() => bookSharingState.value === BookSharingState.NEED_CONFIRMATION);
        return {
            bookSharingState,
            needConfirmation,
        };
    },
    computed: {
        resolvedRoute() {
            return this.$router.resolve({
                name: 'contacts v2',
                params: {
                    bookId: this.item.id,
                },
            }).href;
        },
        today() {
            const now = new Date();
            return new Date(now.getFullYear(), now.getMonth(), now.getDate());
        },
        yesterday() {
            const now = new Date();
            return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
        },
        updatedAtTimeString() {
            return +this.item.created_at >= +this.today ? this.$t('time.today') :
                +this.item.created_at >= +this.yesterday ? this.$t('time.yesterday') :
                    this.$t('contactBooks.createdAt', {
                        date: new Date(this.item.created_at).toLocaleDateString('en-us', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        }),
                    });
        },
    },
    methods: {
        beforeOpen(e) {
            if (this.bookSharingState === BookSharingState.SHARED || this.bookSharingState === BookSharingState.NOT_SHARED) {
                // don't use vue router redirect with control keys
                if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey)
                    return;
                this.$router.push(this.resolvedRoute);
            }
            e.preventDefault();
            if (this.bookSharingState === BookSharingState.NEED_CONFIRMATION) {
                this.$emit('needConfirmation', this.item);
            }
        },
    },
});

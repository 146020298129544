import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-1 flex-row gap-sm items-center justify-between" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_or_checkbox = _resolveComponent("or-checkbox");
    const _component_or_select = _resolveComponent("or-select");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_component_or_checkbox, {
            key: _ctx.columnKey,
            "model-value": !_ctx.isUnselected,
            "onUpdate:modelValue": _ctx.handleSelect
        }, {
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
        }, 8, ["model-value", "onUpdate:modelValue"])),
        _createVNode(_component_or_select, {
            modelValue: _ctx.localValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localValue) = $event)),
            class: "w-1/2",
            options: _ctx.fieldsOptions,
            placeholder: _ctx.$t('contactBooks.migrationModal.selectFieldToMap'),
            "is-loading": _ctx.isLoading,
            "is-disabled": _ctx.isUnselected,
            "is-invalid": _ctx.v$.localValue.$error,
            error: (_a = _ctx.v$.localValue.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message,
            "use-search": "",
            "update-search-text": _ctx.$t('contactBooks.migrationModal.wantToCreateNewColumn'),
            "search-function": _ctx.searchFieldSchemasOptions,
            onClose: _ctx.v$.localValue.$touch
        }, null, 8, ["modelValue", "options", "placeholder", "is-loading", "is-disabled", "is-invalid", "error", "update-search-text", "search-function", "onClose"])
    ]));
}

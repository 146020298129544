import { find, findIndex } from 'lodash';
import { defineStore } from 'pinia';
import { ContactsApiV2 } from '@/api';
import { ContactBooksOrderModes, MigrationsStatus } from '@/constants';
export const useContactsBooksStore = defineStore('contactBooksV2', {
    state: () => ({
        migrationsStatus: MigrationsStatus.UNDEFINED,
        total: undefined,
        items: [],
        triggers: [],
        sharedBookMetaInfo: [],
    }),
    getters: {
        getBookById: (state) => {
            return (id) => {
                return find(state.items, { id });
            };
        },
        getBookByName: (state) => {
            return (name) => {
                return find(state.items, { label: name });
            };
        },
        /**
         * TODO: implement
         */
        getAccessPermissionsByBookName: () => {
            return () => {
                throw new Error('Not implemented');
            };
        },
        sharedBookMeta({ sharedBookMetaInfo }) {
            return sharedBookMetaInfo;
        },
    },
    actions: {
        async getContactBooks({ orderMode = ContactBooksOrderModes.ASC, orderField = 'label', take = undefined, skip = undefined, q = undefined, } = {}) {
            const resp = await ContactsApiV2.contactBookApi.listContactBook({
                orderProperty: orderField,
                orderDirection: orderMode,
                size: take,
                from: skip,
                q,
            });
            this.total = resp.total;
            this.items = resp.items;
        },
        async addContactBook(payload) {
            const data = await ContactsApiV2.contactBookApi.createContactBook(payload);
            this._updateContactBook(data);
            return data.id;
        },
        async deleteContactBook(id) {
            await ContactsApiV2.contactBookApi.deleteContactBook(id);
        },
        async deleteBookContacts(bookId) {
            // eslint-disable-next-line max-len
            const { totalBatches: initialButchesCount } = await ContactsApiV2.contactApi.initDeleteBookContactsBulk(bookId, false, 400, true);
            let batchesLeft = initialButchesCount;
            // Repeat the process until all batches are processed
            while (batchesLeft > 0) {
                // eslint-disable-next-line max-len
                const { totalBatches: currentLeftButches } = await ContactsApiV2.contactApi.initDeleteBookContactsBulk(bookId, false, 400, true);
                batchesLeft = currentLeftButches;
            }
        },
        _updateContactBook(data) {
            const index = findIndex(this.items, { id: data.id });
            if (index > -1) {
                this.items[index] = data;
            }
            else {
                this.items.push(data);
            }
        },
        async getContactBook(id) {
            try {
                const data = await ContactsApiV2.contactBookApi.getContactBook(id);
                this._updateContactBook(data);
            }
            catch (e) {
                console.warn(e);
            }
        },
        async updateContactBook(id, dto) {
            const data = await ContactsApiV2.contactBookApi.updateContactBook(id, dto);
            this._updateContactBook(data);
        },
        async shareCrossAccountContactBook(id, dto) {
            await ContactsApiV2.contactBookApi.shareContactBook(id, dto);
            await this.getSharedBookMeta(id);
        },
        async approveSharing(id) {
            await ContactsApiV2.contactBookApi.approveSharedBook(id);
            await this.getContactBook(id);
        },
        async stopSharingContactBook(id, data) {
            await ContactsApiV2.contactBookApi.rejectShareBook(id, data);
            await this.getSharedBookMeta(id);
        },
        async restoreShareBook(id, shareMetaId) {
            await ContactsApiV2.contactBookApi.restoreShareContactBook(id, shareMetaId);
            await this.getSharedBookMeta(id);
        },
        // eslint-disable-next-line max-len
        async updateShareBookContactPermission(id, data) {
            await ContactsApiV2.sharedBookContactPermissionApi.updateSharedBookContactPermission(id, data);
        },
        async getSharedBookMeta(id) {
            this.sharedBookMetaInfo = await ContactsApiV2.contactBookApi.getSharedBookMeta(id);
        },
    },
});
export default useContactsBooksStore;

import { OrIconButtonV3 as OrIconButton, OrExpansionPanelV3 as OrExpansionPanel, } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import { defineComponent, ref } from 'vue';
import OrTagsContainer from '@/components/OrTagsContainer.vue';
import ContactBooksSchemaEditorItemContent from './ContactBooksSchemaEditorItemContent.vue';
export default defineComponent({
    name: 'ContactBooksSchemaEditorItem',
    components: {
        OrIconButton,
        OrExpansionPanel,
        OrTagsContainer,
        ContactBooksSchemaEditorItemContent,
    },
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        isDeletable: {
            type: Boolean,
            required: false,
            default: true,
        },
        undeletableProperties: {
            type: Array,
            required: false,
            default: () => [],
        },
        allItems: {
            type: Array,
            required: false,
            default: () => [],
        },
        schemaPresets: {
            type: Array,
            required: false,
            default: () => [],
        },
        editorItems: {
            type: Set,
            required: false,
            default: () => new Set(),
        },
    },
    emits: ['delete', 'update'],
    setup() {
        const expansionPanelRef = ref();
        const itemContentRef = ref();
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            v$,
            expansionPanelRef,
            itemContentRef,
        };
    },
    computed: {
        isAllowToEdit() {
            return !this.fieldSchema.id;
        },
        isChanged() {
            var _a;
            return !!((_a = this.itemContentRef) === null || _a === void 0 ? void 0 : _a.isChanged);
        },
    },
    mounted() {
        var _a;
        this.editorItems.add(this);
        if (!this.fieldSchema.label)
            (_a = this.expansionPanelRef) === null || _a === void 0 ? void 0 : _a.open();
    },
    beforeUnmount() {
        this.editorItems.delete(this);
    },
    methods: {
        toggleOpen(open) {
            var _a, _b;
            (_b = (_a = this.expansionPanelRef) === null || _a === void 0 ? void 0 : _a[open ? 'open' : 'close']) === null || _b === void 0 ? void 0 : _b.call(_a);
        },
        reset() {
            var _a;
            (_a = this.itemContentRef) === null || _a === void 0 ? void 0 : _a.reset();
        },
    },
});

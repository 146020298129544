import { OrButtonV3, OrInputV3, OrModalV3, OrSelectV3, OrSwitchV3, OrTabsV3, useResponsive, } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { defineComponent, ref, toRef } from 'vue';
import { defaultPermission, permissionItems } from '@/components/Contacts/ContactsShare/const';
import { alreadyExists } from '@/utils/validators';
import ActiveShareItemActions from './ActiveShareItemActions.vue';
import ArchivedShareItemActions from './ArchivedShareItemActions.vue';
import ContactShareItem from './ContactsShareItem.vue';
export default defineComponent({
    name: 'ContactsShareModalV2',
    components: {
        OrSwitchV3,
        OrSelectV3,
        OrModalV3,
        OrButtonV3,
        OrInputV3,
        ContactShareItem,
        OrTabsV3,
        ActiveShareItemActions,
        ArchivedShareItemActions,
    },
    props: {
        sharedBookMeta: {
            type: Array,
            required: false,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['invite', 'delete', 'restore', 'permissionUpdate'],
    expose: ['reset', 'modalComponent', 'openModal'],
    setup() {
        const menu = ref();
        const trigger = ref();
        const { isDesktop } = useResponsive();
        let customBookName = ref('');
        let accountToInvite = ref('');
        let currentTab = ref('active');
        let selectedPermission = ref('view');
        let shareWithCustomName = ref(false);
        let v$ = useVuelidate();
        const modalComponent = ref();
        const openModal = () => {
            var _a;
            reset();
            (_a = modalComponent.value) === null || _a === void 0 ? void 0 : _a.open();
        };
        const reset = () => {
            shareWithCustomName.value = false;
            customBookName.value = '';
            accountToInvite.value = '';
            selectedPermission.value = 'view';
            v$.value.$reset();
        };
        return {
            isDesktop,
            menu,
            trigger,
            customBookName,
            accountToInvite,
            currentTab,
            selectedPermission,
            shareWithCustomName,
            v$,
            modalComponent,
            openModal,
            reset,
        };
    },
    computed: {
        tabItems() {
            return [{
                    value: 'active',
                    label: this.$t('contacts.shareModal.active'),
                }, {
                    value: 'archived',
                    label: this.$t('contacts.shareModal.archived'),
                }];
        },
        getActive() {
            var _a;
            return (_a = this.sharedBookMeta) === null || _a === void 0 ? void 0 : _a.filter(i => i.status === 'shared' || i.status === 'pending');
        },
        getArchived() {
            var _a;
            return (_a = this.sharedBookMeta) === null || _a === void 0 ? void 0 : _a.filter(i => i.status === 'deletedFromTarget' || i.status === 'canceled');
        },
        permissionItems() {
            return permissionItems(this.$t);
        },
        invitedAccountIds() {
            var _a, _b;
            return (_b = (_a = this.sharedBookMeta) === null || _a === void 0 ? void 0 : _a.map(i => i.target_account)) !== null && _b !== void 0 ? _b : [];
        },
    },
    validations() {
        const invitedAccountIdsRef = toRef(this, 'invitedAccountIds');
        return {
            accountToInvite: {
                required,
                alreadyExists: alreadyExists(invitedAccountIdsRef, this.$t('contacts.shareModal.alreadyShared')),
                $lazy: true,
            },
            customBookName: {
                ...(this.shareWithCustomName && {
                    required,
                    minLengthValue: minLength(3),
                    $lazy: true,
                }),
            },
        };
    },
    methods: {
        trimAccountId() {
            this.accountToInvite = this.accountToInvite.trim();
        },
        buildPermission() {
            return this.selectedPermission === 'edit'
                ? Object.keys(defaultPermission).reduce((acc, k) => {
                    acc[k] = true;
                    return acc;
                }, {})
                : defaultPermission;
        },
        async sendInvitation() {
            const params = {
                targetAccountId: this.accountToInvite,
                contactPermission: this.buildPermission(),
                ...(this.customBookName && { custom_book_name: this.customBookName }),
            };
            const valid = await this.v$.$validate();
            if (valid) {
                this.$emit('invite', params);
            }
        },
        deleteInvitation(accountId) {
            this.$emit('delete', accountId);
        },
        restoreSharingMeta(metaId) {
            this.$emit('restore', metaId);
        },
        permissionUpdate($event) {
            this.$emit('permissionUpdate', $event);
        },
    },
});

import { AuthPopover } from '@onereach/auth-ui-module';
import { ServiceNavigationHeader } from '@onereach/service-navigation';
import { OrNotificationV3, OrToastContainerV3 } from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import i18n from '@/i18n';
import { getMenuItems } from '@/utils';
export default defineComponent({
    name: 'AppPage',
    components: {
        ServiceNavigationHeader,
        AuthPopover,
        OrNotificationV3,
        OrToastContainerV3,
    },
    props: {
        token: {
            type: String,
            required: false,
            default: null,
        },
        config: {
            type: Object,
            required: true,
        },
        serviceTranslations: {
            type: Object,
            required: false,
            default: null,
        },
        showError: {
            type: Boolean,
            required: false,
            default: false,
        },
        errorText: {
            type: String,
            required: false,
            default: null,
        },
        accountId: {
            type: String,
            required: false,
            default: null,
        },
        userRole: {
            type: String,
            default: undefined,
        },
    },
    emits: ['update:showError'],
    setup(props, { emit }) {
        const isErrorShown = useVModel(props, 'showError', emit);
        const route = useRoute();
        const menuItems = computed(() => getMenuItems(i18n.global.t, props.userRole));
        const breadcrumbs = computed(() => {
            return route.matched
                .map(({ meta }) => meta.breadcrumb)
                .filter(Boolean)
                .map((breadcrumb) => {
                return typeof breadcrumb === 'function'
                    ? breadcrumb(route)
                    : breadcrumb;
            });
        });
        const layout = computed(() => {
            return route.matched
                .map(({ meta }) => meta.layout)
                .filter(Boolean)
                .slice(-1)[0];
        });
        return {
            menuItems,
            isErrorShown,
            breadcrumbs,
            layout,
        };
    },
});

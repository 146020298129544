import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsTableDataCellString',
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
    },
});

import { OrIconV3 as OrIcon, OrButtonV3 as OrButton, OrMenuV3 as OrMenu, OrMenuItemV3 as OrMenuItem, } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
import { defaultPermission, permissionItems, } from '@/components/Contacts/ContactsShare/const';
export default defineComponent({
    name: 'ActiveShareItemActions',
    components: {
        OrButton,
        OrMenu,
        OrMenuItem,
        OrIcon,
    },
    props: {
        meta: {
            type: Object,
            required: false,
            default: () => { },
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['delete', 'permissionUpdate'],
    setup() {
        const menu = ref();
        const trigger = ref();
        return {
            menu,
            trigger,
            permissionMenuExpanded: ref(false),
        };
    },
    computed: {
        permissionItems() {
            return [...permissionItems(this.$t), {
                    label: this.$t('contacts.shareModal.removeInvite'),
                    value: 'removeInvite',
                }];
        },
    },
    methods: {
        onClick(value) {
            value === 'removeInvite' ? this.removeInvite() : this.permissionUpdate(value);
        },
        removeInvite() {
            var _a;
            this.$emit('delete', (_a = this.meta) === null || _a === void 0 ? void 0 : _a.target_account);
        },
        permissionUpdate(value) {
            var _a;
            this.$emit('permissionUpdate', {
                id: (_a = this.meta) === null || _a === void 0 ? void 0 : _a.contactPermission.id,
                ...(value === 'edit'
                    ? Object.keys(defaultPermission)
                        .reduce((acc, k) => {
                        acc[k] = true;
                        return acc;
                    }, {})
                    : defaultPermission),
            });
        },
        getPermission(permission) {
            const copy = { ...permission };
            delete copy.id;
            return Object.values(copy).every(i => i) ? this.$t('contacts.editOnly') : this.$t('contacts.viewOnly');
        },
    },
});

import { defineStore } from 'pinia';
import { ContactsApiV2 } from '@/api';
const useFiltersStore = defineStore('filtersV2', {
    state: () => ({
        globalFilters: [],
        collections: {},
        filters: {},
    }),
    getters: {
        getFiltersByBookId: (state) => {
            return (bookId) => {
                return state.collections[bookId]
                    ? state.collections[bookId]
                    : [];
            };
        },
        getGlobalFilters: (state) => {
            return () => {
                return state.globalFilters;
            };
        },
        getFilterById: (state) => (filterId) => state.filters[filterId],
    },
    actions: {
        async getFilters(params) {
            const data = await ContactsApiV2.filterApi.listFilters(params);
            if (params.contact_book) {
                this.collections[params.contact_book] = data.items;
            }
            else {
                this.globalFilters = data.items;
            }
            data.items.forEach(filter => this.filters[filter.id] = filter);
        },
        async getFilter(filterId) {
            this.filters[filterId] = await ContactsApiV2.filterApi.getFilter(filterId);
        },
        async addFilter(data) {
            const filter = await ContactsApiV2.filterApi.createFilter(data);
            this.filters[filter.id] = filter;
            return filter;
        },
        async updateFilter(filterId, data) {
            const filter = await ContactsApiV2.filterApi.updateFilter(filterId, data);
            this.filters[filter.id] = filter;
        },
        async deleteFilter(filterId) {
            await ContactsApiV2.filterApi.deleteFilter(filterId);
            delete this.filters[filterId];
        },
    },
});
export default useFiltersStore;

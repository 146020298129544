import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "contact-form-field-array-of-strings" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_OrTagInputV3 = _resolveComponent("OrTagInputV3");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_OrTagInputV3, {
            modelValue: _ctx.localData,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localData) = $event)),
            overflow: "wrap",
            label: _ctx.fieldSchema.label,
            placeholder: _ctx.$t('contacts.contactsForm.enterFieldValue', { name: (_a = _ctx.fieldSchema.singularLabel) !== null && _a !== void 0 ? _a : _ctx.fieldSchema.label })
        }, null, 8, ["modelValue", "label", "placeholder"])
    ]));
}

import { defineComponent } from 'vue';
import { OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';
import { ContactBooksOrderModes } from '@/constants';
export default defineComponent({
    components: {
        OrButton,
    },
    props: {
        dir: {
            type: String,
            required: true,
        },
    },
    emits: ['change'],
    data() {
        return {
            ContactBooksOrderModes,
        };
    },
});

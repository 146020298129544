import { OrTextareaV3 as OrTextarea } from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactFormFieldMD',
    components: {
        OrTextarea,
    },
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: String,
            required: false,
            default: '',
        },
    },
    emits: ['update:data'],
    setup(props, { emit }) {
        const localData = useVModel(props, 'data', emit);
        return {
            localData,
        };
    },
});

import { computed } from 'vue';
import { useRoute } from 'vue-router';
export function useIsV2Route() {
    const route = useRoute();
    const isV2 = computed(() => route.matched.some(r => r.meta.isV2));
    return {
        isV2,
    };
}
export default useIsV2Route;

import { defineComponent, watch } from 'vue';
import { OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';
import { get } from '@vueuse/core';
import _ from 'lodash';
import ContactBooksSchemaEditorItemTriggersItem from './ContactBooksSchemaEditorItemTriggersItem.vue';
import { useLocalState } from '@/composables';
export default defineComponent({
    name: 'ContactBooksSchemaEditorItemTriggers',
    components: {
        OrButton,
        ContactBooksSchemaEditorItemTriggersItem,
    },
    props: {
        triggers: {
            type: Array,
            required: false,
            default: () => [],
        },
        initTriggers: {
            type: Array,
            required: true,
        },
    },
    emits: ['update'],
    setup(props) {
        const { localState, reset, replace, isChanged, } = useLocalState(props.triggers);
        watch(() => props.triggers, (triggers) => {
            replace(get(triggers));
        }, { deep: true });
        return {
            localState,
            isChanged,
            reset,
        };
    },
    computed: {
        hasUnsaved() {
            return _.some(this.localState, ({ isEdit = false }) => isEdit);
        },
        schemaToOutput() {
            return this.localState;
        },
    },
    methods: {
        handleAddTrigger() {
            this.localState.push({
                initiateWithCanMsg: false,
                icon: '',
                trigger: '',
                label: '',
                initialMessageRequired: false,
                isEdit: true,
                isNew: true,
            });
        },
        handleDelete(index) {
            this.localState.splice(index, 1);
            this.handleApply();
        },
        handleEdit(index) {
            this.localState[index].isEdit = true;
        },
        handleSave(index, value) {
            // eslint-disable-next-line no-unused-vars
            const { isNew, isEdit, ...trigger } = value;
            this.localState[index] = trigger;
            this.handleApply();
        },
        handleApply() {
            this.$emit('update', this.localState);
        },
    },
});

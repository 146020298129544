import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "absolute right-[20px] bottom-[20px] z-[2]" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_or_fab = _resolveComponent("or-fab");
    const _component_or_menu_item = _resolveComponent("or-menu-item");
    const _component_or_menu = _resolveComponent("or-menu");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_or_fab, {
            ref: "triggerRef",
            icon: "add",
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.menuRef && _ctx.menuRef.open()))
        }, null, 512),
        _createVNode(_component_or_menu, {
            ref: "menuRef",
            trigger: (_a = _ctx.triggerRef) === null || _a === void 0 ? void 0 : _a.root
        }, {
            default: _withCtx(() => [
                _createVNode(_component_or_menu_item, {
                    disabled: "",
                    icon: "publish",
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('import')))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('contactBooks.addMenu.import')), 1)
                    ]),
                    _: 1
                }),
                _createVNode(_component_or_menu_item, {
                    icon: "add",
                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('create')))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('contactBooks.addMenu.create')), 1)
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["trigger"])
    ]));
}

import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_OrIcon = _resolveComponent("OrIcon");
    const _component_OrButton = _resolveComponent("OrButton");
    const _component_OrMenuItem = _resolveComponent("OrMenuItem");
    const _component_OrMenu = _resolveComponent("OrMenu");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_OrButton, {
            ref: "trigger",
            class: "whitespace-nowrap",
            variant: "link",
            color: "inherit",
            loading: _ctx.isLoading,
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.menu.toggle()))
        }, {
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getPermission(_ctx.meta.contactPermission)) + " ", 1),
                (_ctx.menu)
                    ? (_openBlock(), _createBlock(_component_OrIcon, {
                        key: 0,
                        icon: _ctx.menu.isOpen ? 'arrow_drop_up' : 'arrow_drop_down'
                    }, null, 8, ["icon"]))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }, 8, ["loading"]),
        _createVNode(_component_OrMenu, {
            ref: "menu",
            trigger: (_a = _ctx.trigger) === null || _a === void 0 ? void 0 : _a.root
        }, {
            default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permissionItems, (item) => {
                    return (_openBlock(), _createBlock(_component_OrMenuItem, {
                        key: item.value,
                        onClick: ($event) => (_ctx.onClick(item.value))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                        ]),
                        _: 2
                    }, 1032, ["onClick"]));
                }), 128))
            ]),
            _: 1
        }, 8, ["trigger"])
    ], 64));
}

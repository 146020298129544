import { SharedBookStatus, } from '@onereach/types-contacts-api';
export const permissionItems = ($t) => {
    return [{
            label: $t('contacts.viewOnly'),
            value: 'view',
        },
        {
            label: $t('contacts.editOnly'),
            value: 'edit',
        }];
};
export const defaultPermission = {
    edit: false,
    create: false,
    delete: false,
};
export const getDeletedFromText = ($t, meta) => {
    let where = '';
    switch (meta === null || meta === void 0 ? void 0 : meta.status) {
        case SharedBookStatus.deletedFromSource:
            where = 'source';
            break;
        case SharedBookStatus.deletedFromTarget:
            where = 'target';
            break;
    }
    return where ? $t('contacts.shareModal.deletedFrom', { where }) : '';
};

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "layout-column gap-md"
};
const _hoisted_2 = { class: "flex justify-end gap-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrNotificationV3 = _resolveComponent("OrNotificationV3");
    const _component_OrSegmentedControlV3 = _resolveComponent("OrSegmentedControlV3");
    const _component_OrCheckboxGroupV3 = _resolveComponent("OrCheckboxGroupV3");
    const _component_OrButtonV3 = _resolveComponent("OrButtonV3");
    const _component_OrModalV3 = _resolveComponent("OrModalV3");
    return (_openBlock(), _createBlock(_component_OrModalV3, {
        ref: "modal",
        size: "s"
    }, _createSlots({
        header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contacts.exportModal.title')), 1)
        ]),
        default: _withCtx(() => [
            (_ctx.isError)
                ? (_openBlock(), _createBlock(_component_OrNotificationV3, {
                    key: 0,
                    variant: "error",
                    message: _ctx.$t('contacts.exportModal.exportError')
                }, null, 8, ["message"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_OrSegmentedControlV3, {
                        modelValue: _ctx.formatModel,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.formatModel) = $event)),
                        options: _ctx.exportFormatOptions
                    }, null, 8, ["modelValue", "options"]),
                    _createVNode(_component_OrCheckboxGroupV3, {
                        modelValue: _ctx.fieldsToExport,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.fieldsToExport) = $event)),
                        options: _ctx.options,
                        disabled: _ctx.isLoading,
                        label: _ctx.$t('contacts.exportModal.fieldsToExport')
                    }, null, 8, ["modelValue", "options", "disabled", "label"])
                ]))
        ]),
        _: 2
    }, [
        (!_ctx.isError)
            ? {
                name: "footer",
                fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_OrButtonV3, {
                            variant: "outlined",
                            disabled: _ctx.isLoading,
                            onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('close')))
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1)
                            ]),
                            _: 1
                        }, 8, ["disabled"]),
                        _createVNode(_component_OrButtonV3, {
                            disabled: _ctx.fieldsToExport.length === 0,
                            loading: _ctx.isLoading,
                            onClick: _ctx.handleExport
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('contacts.exportModal.exportButton')), 1)
                            ]),
                            _: 1
                        }, 8, ["disabled", "loading", "onClick"])
                    ])
                ]),
                key: "0"
            }
            : undefined
    ]), 1536));
}

import { OrNotificationV3 } from '@onereach/ui-components';
import { useTimeoutPoll } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { defineComponent, onBeforeMount } from 'vue';
import { useSettingsStore } from '@/stores';
export default defineComponent({
    name: 'SettingsContainer',
    components: {
        OrNotificationV3,
    },
    setup() {
        const settingsStore = useSettingsStore();
        const { systemBusy } = storeToRefs(settingsStore);
        const { resume } = useTimeoutPoll(settingsStore.getOperationInfo, 30000);
        onBeforeMount(() => {
            settingsStore.getContactsCount();
            resume();
        });
        return {
            systemBusy,
        };
    },
});

import _ from 'lodash';
function trimAll(val, removeEmpty = true) {
    if (_.isArray(val)) {
        if (removeEmpty) {
            return _.filter(_.map(val, _.trim), _.negate(_.isEmpty));
        }
        else {
            return _.map(val, _.trim);
        }
    }
    else {
        return _.trim(val);
    }
}
export default trimAll;

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-4b7de53c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "match-row",
    "data-test": "match-row"
};
const _hoisted_2 = { class: "label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_select = _resolveComponent("or-select");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('contacts.filterModal.match.label')), 1),
        _createVNode(_component_or_select, {
            modelValue: _ctx.selectModel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selectModel) = $event)),
            options: _ctx.options,
            "is-disabled": _ctx.isLoading
        }, null, 8, ["modelValue", "options", "is-disabled"])
    ]));
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_form_field = _resolveComponent("contact-form-field");
    const _component_OrEmptyStateV3 = _resolveComponent("OrEmptyStateV3");
    return (_ctx.fieldSchemas.length > 0)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            class: "contact-form flex flex-col gap-md",
            onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["prevent"]))
        }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldSchemas, (field, index) => {
                return (_openBlock(), _createBlock(_component_contact_form_field, {
                    key: field.id,
                    "field-schema": field,
                    data: _ctx.localValues[index].value,
                    onUpdate: ($event) => (_ctx.handleUpdate(field.id, $event))
                }, null, 8, ["field-schema", "data", "onUpdate"]));
            }), 128))
        ], 32))
        : (_openBlock(), _createBlock(_component_OrEmptyStateV3, {
            key: 1,
            class: "h-full",
            heading: _ctx.$t('contacts.contactsForm.emptyState.heading'),
            text: _ctx.$t('contacts.contactsForm.emptyState.text')
        }, null, 8, ["heading", "text"]));
}

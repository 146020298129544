import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createBlock(_component_or_button, {
        "is-text": "",
        "data-test": "order-control",
        content: _ctx.$t('contactBooks.controls.order.name'),
        type: "text",
        icon: _ctx.dir === _ctx.ContactBooksOrderModes.ASC ? 'arrow_downward' : 'arrow_upward',
        "icon-position": "right",
        color: "neutral-6",
        size: "small",
        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('change')))
    }, null, 8, ["content", "icon"]));
}

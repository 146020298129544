import { OrIconV3 as OrIcon, OrButtonV3 as OrButton, OrSelectV3 as OrSelect, OrExpansionPanelV3 as OrExpansionPanel, OrConfirmV3, } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import _ from 'lodash';
import { defineComponent, ref } from 'vue';
import { getDefaultNewFieldV2 } from '@/utils';
import ContactBooksSchemaEditorItem from './ContactBooksSchemaEditorItem.vue';
import ContactBooksSchemaEditorPresetItem from './ContactBooksSchemaEditorPresetItem.vue';
export default defineComponent({
    name: 'ContactBooksSchemaEditor',
    components: {
        ContactBooksSchemaEditorItem,
        ContactBooksSchemaEditorPresetItem,
        OrIcon,
        OrButton,
        OrSelect,
        OrExpansionPanel,
        OrConfirmV3,
    },
    props: {
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        allFieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        schemaPresets: {
            type: Array,
            required: false,
            default: () => [],
        },
        selectedSchemaPresets: {
            type: Array,
            required: false,
            default: () => [],
        },
        requiredFields: {
            type: Array,
            required: false,
            default: () => [],
        },
        requiredFieldsProperties: {
            type: Object,
            required: false,
            default: () => { },
        },
    },
    emits: ['update'],
    setup() {
        const fieldSchemaSelectRef = ref();
        const schemaPresetSelectRef = ref();
        const fieldGroupsExpansionPanelRef = ref();
        const fieldsExpansionPanelRef = ref();
        const deleteFieldGroupConfirmRef = ref();
        const deleteFieldConfirmRef = ref();
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            fieldSchemaSelectRef,
            schemaPresetSelectRef,
            fieldGroupsExpansionPanelRef,
            fieldsExpansionPanelRef,
            deleteFieldGroupConfirmRef,
            deleteFieldConfirmRef,
            v$,
        };
    },
    data(props) {
        return {
            lastKey: 0,
            keysMap: new WeakMap(),
            newFieldSchemas: _.cloneDeep(props.fieldSchemas),
            newSchemaPresets: _.cloneDeep(props.selectedSchemaPresets),
            editorItems: new Set(),
            fieldSchemaIdToAdd: undefined,
            schemaPresetIdToAdd: undefined,
            presetIndexToDelete: undefined,
            fieldIndexToDelete: undefined,
        };
    },
    computed: {
        unusedFieldSchemas() {
            return _.filter(this.allFieldSchemas, fieldSchema => !this.newFieldSchemas.some(newField => newField.id === fieldSchema.id));
        },
        defaultFieldSchemasToAddOptions() {
            return this.unusedFieldSchemas
                .map(fieldSchema => ({
                value: fieldSchema.id,
                label: fieldSchema.label,
            }));
        },
        unusedSchemaPresets() {
            return _.filter(this.schemaPresets, schemaPreset => !this.newSchemaPresets.some(newPreset => newPreset.id === schemaPreset.id));
        },
        defaultPresetsOptionsToAdd() {
            return this.unusedSchemaPresets
                .map(schemaPreset => ({
                value: schemaPreset.id,
                label: schemaPreset.label,
            }));
        },
        fieldSchemaToAdd() {
            var _a;
            return this.fieldSchemaIdToAdd ?
                ((_a = _.find(this.allFieldSchemas, fieldSchema => fieldSchema.id === this.fieldSchemaIdToAdd)) !== null && _a !== void 0 ? _a : null) :
                null;
        },
        schemaPresetToAdd() {
            return _.find(this.schemaPresets, schemaPreset => schemaPreset.id === this.schemaPresetIdToAdd);
        },
        fieldSchemasIdsToPresets() {
            return _.mapValues(_.groupBy(_.flatMap(this.newSchemaPresets, preset => _.map(preset.fieldSchemas, ({ id }) => [id, preset])), pair => pair[0]), pairs => _.map(pairs, pair => pair[1]));
        },
        fieldSchemasIdsToPresetsNames() {
            return _.mapValues(this.fieldSchemasIdsToPresets, presets => _.map(presets, preset => preset.label));
        },
        hasNewItem() {
            return _.some(this.newFieldSchemas, ({ id }) => !id);
        },
        isChanged() {
            return !_.isEqual(this.newFieldSchemas, this.fieldSchemas) ||
                !_.isEqual(this.newSchemaPresets, this.selectedSchemaPresets);
        },
        hasUnsaved() {
            return this.hasNewItem;
        },
        fieldGroupToDelete() {
            var _a;
            if (this.presetIndexToDelete === undefined)
                return undefined;
            return (_a = this.newSchemaPresets[this.presetIndexToDelete]) === null || _a === void 0 ? void 0 : _a.label;
        },
        fieldToDelete() {
            var _a;
            if (this.fieldIndexToDelete === undefined)
                return undefined;
            return (_a = this.newFieldSchemas[this.fieldIndexToDelete]) === null || _a === void 0 ? void 0 : _a.label;
        },
    },
    watch: {
        fieldSchemas: {
            handler(val) {
                this.newFieldSchemas = _.unionBy(this.newFieldSchemas, val, fieldSchema => { var _a; return (_a = fieldSchema.id) !== null && _a !== void 0 ? _a : this.getOrCreateTemporaryKey(fieldSchema); });
            },
            deep: true,
        },
        selectedSchemaPresets: {
            handler(val) {
                this.newSchemaPresets = _.unionBy(this.newSchemaPresets, val, preset => preset.id);
            },
            deep: true,
        },
        newFieldSchemas: {
            handler() {
                this.fieldSchemaIdToAdd = undefined;
            },
            deep: true,
        },
        newSchemaPresets: {
            handler() {
                this.schemaPresetIdToAdd = undefined;
            },
            deep: true,
        },
    },
    mounted() {
        var _a, _b;
        (_a = this.fieldGroupsExpansionPanelRef) === null || _a === void 0 ? void 0 : _a.open();
        (_b = this.fieldsExpansionPanelRef) === null || _b === void 0 ? void 0 : _b.open();
    },
    methods: {
        searchFieldSchemasOptions(searchText) {
            return this.unusedFieldSchemas
                .filter(fieldSchema => fieldSchema.label.includes(searchText) || fieldSchema.id === searchText)
                .map(fieldSchema => ({
                value: fieldSchema.id,
                label: fieldSchema.label,
            }));
        },
        searchSchemaPresetsOptions(searchText) {
            return this.unusedSchemaPresets
                .filter(schemaPreset => schemaPreset.label.includes(searchText) || schemaPreset.id === searchText)
                .map(schemaPreset => ({
                value: schemaPreset.id,
                label: schemaPreset.label,
            }));
        },
        checkIsFieldSchemaDeletable(fieldSchema) {
            var _a;
            return !this.requiredFields.includes(fieldSchema.id) &&
                !((_a = this.newSchemaPresets) === null || _a === void 0 ? void 0 : _a.some(preset => { var _a; return (_a = preset.fieldSchemas) === null || _a === void 0 ? void 0 : _a.some(field => field.id === fieldSchema.id); }));
        },
        handlePresetDelete(index) {
            var _a;
            this.presetIndexToDelete = index;
            (_a = this.deleteFieldGroupConfirmRef) === null || _a === void 0 ? void 0 : _a.open();
        },
        handlePresetUnlink(index) {
            this.newSchemaPresets.splice(index, 1);
        },
        handleFieldDelete(index) {
            var _a, _b;
            this.fieldIndexToDelete = index;
            const field = this.newFieldSchemas[index];
            const presetsToUnlink = (field === null || field === void 0 ? void 0 : field.id) ? ((_a = this.fieldSchemasIdsToPresets[field.id]) !== null && _a !== void 0 ? _a : []) : [];
            if (presetsToUnlink.length > 0)
                (_b = this.deleteFieldConfirmRef) === null || _b === void 0 ? void 0 : _b.open();
            else
                this.deleteSelectedField();
        },
        deleteField(index) {
            var _a;
            const old = this.newFieldSchemas.splice(index, 1)[0];
            const presetsToUnlink = (old === null || old === void 0 ? void 0 : old.id) ? ((_a = this.fieldSchemasIdsToPresets[old.id]) !== null && _a !== void 0 ? _a : []) : [];
            this.newSchemaPresets = _.difference(this.newSchemaPresets, presetsToUnlink);
        },
        deleteSelectedField() {
            var _a;
            if (this.fieldIndexToDelete === undefined)
                return;
            this.deleteField(this.fieldIndexToDelete);
            this.fieldIndexToDelete = undefined;
            (_a = this.deleteFieldConfirmRef) === null || _a === void 0 ? void 0 : _a.close();
        },
        updateField(index, value) {
            const oldVal = this.newFieldSchemas[index];
            const newVal = _.clone(value);
            this.newFieldSchemas[index] = newVal;
            this.keysMap.set(newVal, this.keysMap.get(oldVal));
        },
        deleteSelectedPreset() {
            var _a, _b, _c;
            if (this.presetIndexToDelete === undefined)
                return;
            const old = this.newSchemaPresets.splice(this.presetIndexToDelete, 1)[0];
            const fieldSchemasToUnlink = (_a = old === null || old === void 0 ? void 0 : old.fieldSchemas) !== null && _a !== void 0 ? _a : [];
            for (const fieldSchemaToDelete of fieldSchemasToUnlink) {
                const indexToDelete = this.newFieldSchemas.findIndex(fs => fs.id === fieldSchemaToDelete.id);
                if (((_b = this.fieldSchemasIdsToPresets[fieldSchemaToDelete.id]) !== null && _b !== void 0 ? _b : []).length < 2)
                    this.deleteField(indexToDelete);
            }
            this.presetIndexToDelete = undefined;
            (_c = this.deleteFieldGroupConfirmRef) === null || _c === void 0 ? void 0 : _c.close();
        },
        handleCreateNewColumn() {
            this.newFieldSchemas.push(getDefaultNewFieldV2());
        },
        handleAddColumn() {
            if (!this.fieldSchemaToAdd) {
                this.handleCreateNewColumn();
                return;
            }
            this.newFieldSchemas.push(this.fieldSchemaToAdd);
            this.fieldSchemaIdToAdd = undefined;
        },
        handleAddSchemaPreset() {
            var _a;
            this.newSchemaPresets.push(this.schemaPresetToAdd);
            this.newFieldSchemas = (_a = _.unionBy(this.newFieldSchemas, _.compact(_.flatMap(this.newSchemaPresets, presets => presets.fieldSchemas)), fieldSchema => { var _a; return (_a = fieldSchema.id) !== null && _a !== void 0 ? _a : this.getOrCreateTemporaryKey(fieldSchema); })) !== null && _a !== void 0 ? _a : [];
            this.schemaPresetIdToAdd = undefined;
        },
        getOrCreateTemporaryKey(item) {
            if (this.keysMap.has(item))
                return this.keysMap.get(item);
            const newTempKey = 'tempKey' + this.lastKey++;
            this.keysMap.set(item, newTempKey);
            return newTempKey;
        },
        scrollToFirstInvalidItem() {
            var _a;
            for (const item of this.editorItems)
                if (item.v$.$invalid) {
                    (_a = this.fieldsExpansionPanelRef) === null || _a === void 0 ? void 0 : _a.open();
                    item.$el.scrollIntoView({ behavior: 'smooth' });
                    item.toggleOpen(true);
                    return;
                }
        },
    },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_or_icon_button, {
            icon: "add",
            type: "floating",
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('click')))
        })
    ]));
}

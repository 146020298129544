import { OrModalV3 } from '@onereach/ui-components';
import { OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';
import useVuelidate from '@vuelidate/core';
import { defineComponent, ref } from 'vue';
import ContactBooksAddForm from './ContactBooksAddForm.vue';
export default defineComponent({
    name: 'ContactBooksAddModal',
    components: {
        OrModalV3,
        OrButton,
        ContactBooksAddForm,
    },
    props: {
        isSubmitting: {
            type: Boolean,
            required: true,
        },
        groupMapping: {
            type: Object,
            required: true,
        },
        initTriggers: {
            type: Array,
            required: true,
        },
        schemaPresets: {
            type: Object,
            required: true,
        },
        defaultSchema: {
            type: Object,
            required: true,
        },
        requiredFields: {
            type: Array,
            required: true,
        },
        requiredFieldsProperties: {
            type: Object,
            required: true,
        },
    },
    emits: ['add', 'close'],
    expose: ['modalComponent'],
    setup() {
        const addBookForm = ref();
        const modalComponent = ref();
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            addBookForm,
            modalComponent,
            v$,
        };
    },
    methods: {
        async triggerSubmit() {
            var _a, _b;
            this.v$.$touch();
            if (await this.v$.$validate()) {
                (_a = this.addBookForm) === null || _a === void 0 ? void 0 : _a.submit();
            }
            else {
                (_b = this.addBookForm) === null || _b === void 0 ? void 0 : _b.scrollToFirstInvalidItem();
            }
        },
        async handleSubmit(bookData) {
            this.$emit('add', bookData);
        },
    },
});

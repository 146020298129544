import { OrSkeletonTextV3 as OrSkeletonText } from '@onereach/ui-components';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'SkeletonLoader',
    components: { OrSkeletonText },
    props: {
        rows: {
            type: Number,
            required: false,
            default: 3,
        },
    },
});

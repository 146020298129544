import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-d75d15b8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "contacts-tabs h-full w-full" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_view = _resolveComponent("router-view");
    const _component_OrTabsV3 = _resolveComponent("OrTabsV3");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isAnyTabSelected)
            ? (_openBlock(), _createBlock(_component_OrTabsV3, {
                key: 0,
                modelValue: _ctx.selectedTab,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selectedTab) = $event)),
                items: _ctx.tabsItems,
                variant: _ctx.isMobile ? 'fitted' : 'default',
                class: _normalizeClass(_ctx.tabsStyles)
            }, {
                [_ctx.selectedTab]: _withCtx(() => [
                    _createElementVNode("div", {
                        class: _normalizeClass(["layout-column overflow-y-auto overflow-x-hidden h-full", !_ctx.isCustomFullPage ? 'max-w-[min(1376px,calc(100%-theme(spacing.3xl)))] w-full mx-auto' : ''])
                    }, [
                        _createVNode(_component_router_view)
                    ], 2)
                ]),
                _: 2
            }, 1032, ["modelValue", "items", "variant", "class"]))
            : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
    ]));
}

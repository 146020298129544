import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_form_field = _resolveComponent("contact-form-field");
    return (_openBlock(), _createElementBlock("form", {
        class: "contact-form",
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["prevent"]))
    }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedSchemaItems, (field) => {
            return (_openBlock(), _createBlock(_component_contact_form_field, {
                key: field.order,
                "field-schema": field,
                data: _ctx.localValues[field.key],
                onUpdate: ($event) => (_ctx.handleUpdate(field.key, $event))
            }, null, 8, ["field-schema", "data", "onUpdate"]));
        }), 128))
    ], 32));
}

import { OrIconV3 as OrIcon, OrButtonV3 as OrButton, OrMenuV3 as OrMenu, OrMenuItemV3 as OrMenuItem, } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'ContactsAddButton',
    components: {
        OrIcon,
        OrButton,
        OrMenu,
        OrMenuItem,
    },
    props: {
        isImportDisabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['add', 'import'],
    setup() {
        const button = ref();
        const menu = ref();
        return {
            button,
            menu,
        };
    },
});

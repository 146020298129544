import { defineComponent, ref } from 'vue';
import { OrLabel, OrOverflowMenu, OrIcon } from '@onereach/ui-components-legacy';
export default defineComponent({
    name: 'OrPaginationLengthSelector',
    components: {
        OrLabel,
        OrOverflowMenu,
        OrIcon,
    },
    props: {
        limit: {
            type: Number,
            required: false,
            default: () => 10,
        },
        total: {
            type: Number,
            required: false,
            default: () => null,
        },
        offset: {
            type: Number,
            required: true,
        },
        variants: {
            type: Array,
            required: false,
            default: () => [10, 20, 45],
        },
        label: {
            type: String,
            required: false,
            default: () => 'Rows per page:',
        },
    },
    emits: ['change'],
    setup() {
        const overflowMenu = ref();
        return {
            overflowMenu,
        };
    },
    methods: {
        handleSelect(variant) {
            this.overflowMenu.close();
            this.$emit('change', variant);
        },
    },
});

import { ColumnTypes } from '@onereach/types-contacts-api';
import getDefaultIdentifier from './getDefaultIdentifier';
function getEmptyContactFormValuesV2(fieldSchemas) {
    const arrayItemsTypes = [
        ColumnTypes['array of strings'],
        ColumnTypes['array of identifiers'],
        ColumnTypes['array of objects'],
    ];
    return fieldSchemas.map(fieldSchema => ({
        schemaId: fieldSchema.id,
        value: arrayItemsTypes.indexOf(fieldSchema.type) != -1 ? [] :
            fieldSchema.type === ColumnTypes['identifier object'] ? getDefaultIdentifier() :
                undefined,
    }));
}
export default getEmptyContactFormValuesV2;

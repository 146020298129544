import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "array-of-strings" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_tags_container = _resolveComponent("or-tags-container");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_or_tags_container, {
            tags: _ctx.value,
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["stop"]))
        }, null, 8, ["tags"])
    ]));
}

import { SharedBookStatus } from '@onereach/types-contacts-api';
import { BookSharingState, SharingState } from '@/constants';
export function getBookSharingState(book) {
    const source = book.source;
    if (!source.accountId)
        return BookSharingState.NOT_SHARED;
    return source.isApproved ?
        BookSharingState.SHARED :
        BookSharingState.NEED_CONFIRMATION;
}
// todo get rid of mapping
export function getBookSharingStateV2(book) {
    switch (book.status) {
        case SharedBookStatus.shared:
            return BookSharingState.SHARED;
        case SharedBookStatus.pending:
            return BookSharingState.NEED_CONFIRMATION;
        case SharedBookStatus.deletedFromSource:
            return BookSharingState.DELETED_FROM_SOURCE;
        case SharedBookStatus.canceled:
            return BookSharingState.CANCELED;
        default:
            return BookSharingState.NOT_SHARED;
    }
}
// todo get rid of mapping
export function mapSharedMetaStatus(status) {
    switch (status) {
        case SharedBookStatus.shared:
            return SharingState.SHARED;
        case SharedBookStatus.pending:
            return SharingState.WAITING;
        case SharedBookStatus.deletedFromTarget:
            return SharingState.DELETED_FROM_TARGET;
        case SharedBookStatus.canceled:
            return SharingState.CANCELED;
        default:
            return null;
    }
}

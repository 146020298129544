import { helpers } from '@vuelidate/validators';
import _ from 'lodash';
function conflictingRules(message) {
    return helpers.withMessage(message, (rules) => {
        if (!helpers.req(rules))
            return true;
        const uniqRules = _.uniq(rules);
        const uniqTags = _.uniq(_.map(rules, rule => rule.substring(1)));
        const valid = uniqRules.length === uniqTags.length;
        const invalidRules = valid ? [] :
            _.flatten(_.filter(_.values(_.groupBy(uniqRules, rule => rule.substring(1))), arr => arr.length > 1));
        return {
            $valid: valid,
            extraParams: { invalidRules },
        };
    });
}
export default conflictingRules;

import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsTableDataCellNumber',
    props: {
        value: {
            type: Number,
            required: false,
            default: null,
        },
    },
});

import _ from 'lodash';
import getDefaultContactsTableColumnStoredValue from './getDefaultContactsTableColumnStoredValue';
import { convertSchemaToOrderedArray } from './index';
import getAllCompositeColumns from './getAllCompositeColumns';
function getDefaultContactsTableStoredValue(schema, withCompositeColumns = true) {
    const compositeColumns = getAllCompositeColumns();
    const keysToHide = compositeColumns.flatMap(({ parts }) => parts);
    const virtualColumns = compositeColumns.map(({ key }) => ({ key }));
    const schemaItems = _.concat(withCompositeColumns ? virtualColumns : [], convertSchemaToOrderedArray(schema, withCompositeColumns)
        .filter(item => !withCompositeColumns || !keysToHide.includes(item.key)));
    const columns = _.mapValues(_.keyBy(schemaItems, ({ key }) => key), getDefaultContactsTableColumnStoredValue);
    return {
        columns,
    };
}
export default getDefaultContactsTableStoredValue;

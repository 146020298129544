import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "contact-form-field-identifier-item flex flex-col gap-md+ md:gap-md pb-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_input = _resolveComponent("or-input");
    const _component_OrTagInputV3 = _resolveComponent("OrTagInputV3");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_or_input, {
            modelValue: _ctx.localData.val,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localData.val) = $event)),
            placeholder: _ctx.$t('contacts.contactsForm.enterFieldValue', { name: _ctx.fieldSchema.label })
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_OrTagInputV3, {
            modelValue: _ctx.localData.type,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.localData.type) = $event)),
            label: _ctx.$t('contacts.contactsForm.type'),
            placeholder: _ctx.$t('contacts.contactsForm.enterFieldType', { name: _ctx.fieldSchema.label })
        }, null, 8, ["modelValue", "label", "placeholder"])
    ]));
}

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrNotificationV3 = _resolveComponent("OrNotificationV3");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.systemBusy)
            ? (_openBlock(), _createBlock(_component_OrNotificationV3, {
                key: 0,
                "disable-close": "",
                variant: "warning",
                class: "absolute z-[100] md:left-1/2 md:-translate-x-1/2"
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('settings.systemBusy')), 1)
                ]),
                _: 1
            }))
            : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
    ], 64));
}

import { OrModalV3 } from '@onereach/ui-components';
import { OrAvatar, OrButtonV2 as OrButton, OrIconButton, OrChip, OrInput } from '@onereach/ui-components-legacy';
import useVuelidate from '@vuelidate/core';
import { defineComponent, toRef, ref } from 'vue';
import { SharingState } from '@/constants';
import { alreadyExists } from '@/utils/validators';
export default defineComponent({
    name: 'ContactsShareModal',
    components: {
        OrModalV3,
        OrButton,
        OrIconButton,
        OrInput,
        OrAvatar,
        OrChip,
    },
    props: {
        invitations: {
            type: Array,
            required: false,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['invite', 'delete'],
    expose: ['modalComponent'],
    setup() {
        return {
            v$: useVuelidate(),
            modalComponent: ref(),
        };
    },
    data() {
        return {
            accountToInvite: '',
        };
    },
    computed: {
        invitedAccountIds() {
            var _a, _b;
            return (_b = (_a = this.invitations) === null || _a === void 0 ? void 0 : _a.map(i => i.accountId)) !== null && _b !== void 0 ? _b : [];
        },
        isInviteDisabled() {
            return !this.accountToInvite.trim();
        },
    },
    watch: {
        isOpen() {
            this.accountToInvite = '';
        },
        isLoading(val) {
            if (!val)
                this.accountToInvite = '';
        },
    },
    validations() {
        const invitedAccountIdsRef = toRef(this, 'invitedAccountIds');
        return {
            accountToInvite: {
                alreadyExists: alreadyExists(invitedAccountIdsRef, this.$t('contacts.shareModal.alreadyShared')),
            },
        };
    },
    methods: {
        trimAccountId() {
            this.accountToInvite = this.accountToInvite.trim();
        },
        getIsWaiting(state) {
            return state === SharingState.WAITING;
        },
        getStateText(state) {
            switch (state) {
                case SharingState.WAITING:
                    return this.$t('contacts.shareModal.waitingForResponse');
                case SharingState.SHARED:
                    return this.$t('contacts.shareModal.shared');
                default:
                    return state;
            }
        },
        getUsersText(usersCount, state) {
            return state === SharingState.WAITING ?
                this.$t('contacts.shareModal.unknown') :
                this.$t('contacts.shareModal.users', { count: usersCount });
        },
        sendInvitation() {
            this.$emit('invite', this.accountToInvite);
        },
        deleteInvitation(accountId) {
            this.$emit('delete', accountId);
        },
    },
});

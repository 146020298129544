import { defineComponent } from 'vue';
import OrTagsContainer from '@/components/OrTagsContainer.vue';
export default defineComponent({
    name: 'ContactsTableDataCellArrayOfStrings',
    components: {
        OrTagsContainer,
    },
    props: {
        value: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
});

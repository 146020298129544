import _ from 'lodash';
function groupSchemaItems(schemaItems, groupMapping, customGroupName = 'custom') {
    const indexedItems = schemaItems.map((item, index) => ({
        ...item,
        originalIndex: index,
    }));
    const itemsMap = {};
    for (const [groupName, itemList] of Object.entries(groupMapping)) {
        const items = _.filter(indexedItems, (item) => itemList.includes(item.key));
        if (items.length > 0) {
            itemsMap[groupName] = items;
        }
    }
    itemsMap[customGroupName] = _.filter(indexedItems, (item) => item.isNew || !Object.values(groupMapping).flat().includes(item.key));
    return itemsMap;
}
export default groupSchemaItems;

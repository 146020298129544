import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrModalV3 = _resolveComponent("OrModalV3");
    const _component_OrSidebarV3 = _resolveComponent("OrSidebarV3");
    const _component_OrTeleportV3 = _resolveComponent("OrTeleportV3");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.isMobile)
            ? (_openBlock(), _createBlock(_component_OrModalV3, _mergeProps({ key: 0 }, _ctx.$props, {
                ref: "rootRef",
                onClose: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close')))
            }), _createSlots({
                default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _: 2
            }, [
                (_ctx.$slots.header)
                    ? {
                        name: "header",
                        fn: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "header")
                        ]),
                        key: "0"
                    }
                    : undefined,
                (_ctx.$slots.footer)
                    ? {
                        name: "footer",
                        fn: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "footer")
                        ]),
                        key: "1"
                    }
                    : undefined
            ]), 1040))
            : _createCommentVNode("", true),
        (!_ctx.isMobile && _ctx.sidebarOpen)
            ? (_openBlock(), _createBlock(_component_OrTeleportV3, {
                key: 1,
                to: "#page-content-container",
                disabled: !_ctx.isAbsolutePositioned
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_OrSidebarV3, _mergeProps(_ctx.$props, {
                        ref: "rootRef",
                        "is-open": _ctx.sidebarOpen,
                        class: _ctx.sidebarStyles,
                        onOpen: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('open'))),
                        onClose: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('close')))
                    }), _createSlots({
                        default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "default")
                        ]),
                        _: 2
                    }, [
                        (_ctx.$slots.header)
                            ? {
                                name: "header",
                                fn: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "header")
                                ]),
                                key: "0"
                            }
                            : undefined,
                        (_ctx.$slots.footer)
                            ? {
                                name: "footer",
                                fn: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "footer")
                                ]),
                                key: "1"
                            }
                            : undefined
                    ]), 1040, ["is-open", "class"])
                ]),
                _: 3
            }, 8, ["disabled"]))
            : _createCommentVNode("", true)
    ], 64));
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedBookStatus = exports.MigrationStatus = exports.MigrationState = exports.ColumnTypes = exports.BatchProcessStatus = void 0;
var BatchProcessStatus;
(function (BatchProcessStatus) {
    BatchProcessStatus["pending"] = "pending";
    BatchProcessStatus["success"] = "success";
    BatchProcessStatus["failed"] = "failed";
})(BatchProcessStatus || (exports.BatchProcessStatus = BatchProcessStatus = {}));
var ColumnTypes;
(function (ColumnTypes) {
    ColumnTypes["string"] = "string";
    ColumnTypes["integer"] = "integer";
    ColumnTypes["double"] = "double";
    ColumnTypes["date"] = "date";
    ColumnTypes["md"] = "md";
    ColumnTypes["identifier object"] = "identifier object";
    ColumnTypes["array of identifiers"] = "array of identifiers";
    ColumnTypes["array of objects"] = "array of objects";
    ColumnTypes["array of strings"] = "array of strings";
})(ColumnTypes || (exports.ColumnTypes = ColumnTypes = {}));
var MigrationState;
(function (MigrationState) {
    MigrationState["InProgress"] = "InProgress";
    MigrationState["ToDo"] = "ToDo";
    MigrationState["Done"] = "Done";
    MigrationState["NotStarted"] = "NotStarted";
})(MigrationState || (exports.MigrationState = MigrationState = {}));
var MigrationStatus;
(function (MigrationStatus) {
    MigrationStatus["Executed"] = "executed";
    MigrationStatus["Pending"] = "pending";
    MigrationStatus["Unknown"] = "unknown";
})(MigrationStatus || (exports.MigrationStatus = MigrationStatus = {}));
var SharedBookStatus;
(function (SharedBookStatus) {
    SharedBookStatus["pending"] = "pending";
    SharedBookStatus["shared"] = "shared";
    SharedBookStatus["canceled"] = "canceled";
    SharedBookStatus["deletedFromTarget"] = "deletedFromTarget";
    SharedBookStatus["deletedFromSource"] = "deletedFromSource";
})(SharedBookStatus || (exports.SharedBookStatus = SharedBookStatus = {}));
exports.DB_TARGET_VERSION = '1';

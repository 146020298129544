function getDefaultNewFilter() {
    return {
        id: '',
        name: '',
        created_at: new Date(),
        updated_at: new Date(),
        match_all: true,
        conditions: [],
    };
}
export default getDefaultNewFilter;

import { OrCardV3 } from '@onereach/ui-components';
import { defineComponent } from 'vue';
import { RouterLink } from 'vue-router';
import { getBookSharingState } from '@/utils';
import ContactBookOverflowMenu from '../ContactBookOverflowMenu';
import ContactBooksStatus from '../ContactBooksStatus.vue';
export default defineComponent({
    name: 'ContactBooksGridItem',
    components: {
        ContactBookOverflowMenu,
        OrCard: OrCardV3,
        RouterLink,
        ContactBooksStatus,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    emits: ['delete', 'migrate', 'share'],
    computed: {
        itemsCount() {
            var _a;
            return (_a = this.item.count) !== null && _a !== void 0 ? _a : 0;
        },
        bookState() {
            return getBookSharingState(this.item);
        },
    },
    methods: {
        handleUpdate() { },
        handleOpenNewTab() {
            const routeData = this.$router.resolve({
                name: 'contacts',
                params: {
                    bookName: this.item.name,
                },
            });
            window.open(routeData.href, '_blank');
        },
    },
});

import { join, toPairs, isEmpty } from 'lodash';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsTableDataCellArrayOfObjects',
    props: {
        value: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        convertedValue() {
            var _a, _b;
            return (_b = (_a = this.value) === null || _a === void 0 ? void 0 : _a.map((objValue) => join(toPairs(objValue)
                .filter(([key]) => key !== 'type')
                .map(([, value]) => Array.isArray(value) ? join(value, ', ') : value)
                .filter(value => !isEmpty(value)), ', '))) !== null && _b !== void 0 ? _b : [];
        },
    },
});

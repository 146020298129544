import { defineComponent } from 'vue';
import { OrIconButton, OrRadio } from '@onereach/ui-components-legacy';
export default defineComponent({
    name: 'ContactsFilterMenuItem',
    components: {
        OrIconButton,
        OrRadio,
    },
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        filter: {
            type: Object,
            required: true,
        },
    },
    emits: ['edit', 'delete'],
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    class: "flex flex-col gap-md",
    "data-test": "schema-editor-item-content"
};
const _hoisted_2 = {
    key: 0,
    class: "row"
};
const _hoisted_3 = {
    key: 1,
    class: "row"
};
const _hoisted_4 = {
    key: 2,
    class: "row actions flex justify-end"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a, _b, _c;
    const _component_debounce_input = _resolveComponent("debounce-input");
    const _component_or_select = _resolveComponent("or-select");
    const _component_or_input = _resolveComponent("or-input");
    const _component_contact_books_schema_editor_item_properties = _resolveComponent("contact-books-schema-editor-item-properties");
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_debounce_input, {
            "data-test": "schema-editor-column-label-input",
            delay: 700,
            readonly: !_ctx.isAllowToEdit,
            label: _ctx.$t('contactBooks.schemaEditor.fieldName'),
            placeholder: _ctx.$t('contactBooks.schemaEditor.enterFieldName'),
            "model-value": _ctx.localState.label,
            error: _ctx.v$.localState.label.$error ? (_a = _ctx.v$.localState.label.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message : undefined,
            onBlur: _ctx.onLabelBlur,
            "onUpdate:debouncedModelValue": _ctx.onLabelUpdate
        }, null, 8, ["readonly", "label", "placeholder", "model-value", "error", "onBlur", "onUpdate:debouncedModelValue"]),
        _createVNode(_component_debounce_input, {
            "data-test": "schema-editor-machine-name-input",
            readonly: !_ctx.isNew,
            label: _ctx.$t('contactBooks.schemaEditor.machineName'),
            placeholder: _ctx.$t('contactBooks.schemaEditor.enterMachineName'),
            "model-value": _ctx.localState.machine_name,
            error: (_b = _ctx.v$.localState.machine_name.$errors[0]) === null || _b === void 0 ? void 0 : _b.$response,
            loading: _ctx.v$.localState.machine_name.$pending,
            onBlur: _cache[0] || (_cache[0] = ($event) => (_ctx.v$.localState.machine_name.$touch())),
            onFocus: _cache[1] || (_cache[1] = ($event) => (_ctx.v$.localState.machine_name.$reset())),
            "onUpdate:debouncedModelValue": _ctx.onMachineNameUpdate
        }, null, 8, ["readonly", "label", "placeholder", "model-value", "error", "loading", "onUpdate:debouncedModelValue"]),
        _createVNode(_component_or_select, {
            modelValue: _ctx.localState.type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.localState.type) = $event)),
            "data-test": "schema-editor-type-select",
            readonly: !_ctx.isNew,
            label: _ctx.$t('contactBooks.schemaEditor.columnType'),
            placeholder: _ctx.$t('contactBooks.schemaEditor.enterType'),
            options: _ctx.columnTypes,
            onChange: _cache[3] || (_cache[3] = ($event) => (_ctx.localState.type = $event))
        }, null, 8, ["modelValue", "readonly", "label", "placeholder", "options"]),
        (_ctx.showSingularLabel)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_or_input, {
                    "data-test": "schema-editor-singular-label-input",
                    readonly: !_ctx.isAllowToEdit,
                    label: _ctx.$t('contactBooks.schemaEditor.singularLabel'),
                    placeholder: _ctx.$t('contactBooks.schemaEditor.enterSingularLabel'),
                    "model-value": _ctx.localState.singular_label,
                    error: _ctx.v$.localState.singular_label.$error ? (_c = _ctx.v$.localState.singular_label.$errors[0]) === null || _c === void 0 ? void 0 : _c.$message : undefined,
                    onBlur: _ctx.v$.localState.singular_label.$touch,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => (_ctx.localState.singular_label = $event))
                }, null, 8, ["readonly", "label", "placeholder", "model-value", "error", "onBlur"])
            ]))
            : _createCommentVNode("", true),
        (_ctx.showProperties)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_contact_books_schema_editor_item_properties, {
                    properties: _ctx.localState.properties,
                    "is-deletable": _ctx.isAllowToEdit,
                    "undeletable-properties": _ctx.undeletableProperties,
                    onUpdate: _cache[5] || (_cache[5] = ($event) => (_ctx.localState.properties = $event))
                }, null, 8, ["properties", "is-deletable", "undeletable-properties"])
            ]))
            : _createCommentVNode("", true),
        (_ctx.isChanged && !_ctx.isSingleItem)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_or_button, {
                    "data-test": "schema-editor-apply-changes-button",
                    class: "apply-changes",
                    disabled: !_ctx.isChanged || _ctx.v$.$invalid,
                    onClick: _ctx.handleApply
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.apply')), 1)
                    ]),
                    _: 1
                }, 8, ["disabled", "onClick"])
            ]))
            : _createCommentVNode("", true)
    ]));
}

import { ColumnTypes } from '@onereach/types-contacts-api';
import { OrExpansionPanelV3 as OrExpansionPanel } from '@onereach/ui-components';
import { defineComponent } from 'vue';
import { ColumnTypesExtended } from '@/types/columnTypes';
import { hasOwnProperty } from '@/utils';
import ContactFormFieldArrayOfIdentifiers from './ContactFormFieldArrayOfIdentifiers.vue';
import ContactFormFieldArrayOfObjects from './ContactFormFieldArrayOfObjects.vue';
import ContactFormFieldArrayOfStrings from './ContactFormFieldArrayOfStrings.vue';
import ContactFormFieldDate from './ContactFormFieldDateV2.vue';
import ContactFormFieldDouble from './ContactFormFieldDouble.vue';
import ContactFormFieldIdentifierObject from './ContactFormFieldIdentifierObject.vue';
import ContactFormFieldInteger from './ContactFormFieldInteger.vue';
import ContactFormFieldMD from './ContactFormFieldMD.vue';
import ContactFormFieldString from './ContactFormFieldString.vue';
const FieldComponents = {
    [ColumnTypes.string]: ContactFormFieldString,
    [ColumnTypes.date]: ContactFormFieldDate,
    [ColumnTypesExtended.dateTz]: ContactFormFieldDate,
    [ColumnTypes.double]: ContactFormFieldDouble,
    [ColumnTypes.md]: ContactFormFieldMD,
    [ColumnTypes.integer]: ContactFormFieldInteger,
    [ColumnTypes['array of strings']]: ContactFormFieldArrayOfStrings,
    [ColumnTypes['array of objects']]: ContactFormFieldArrayOfObjects,
    [ColumnTypes['array of identifiers']]: ContactFormFieldArrayOfIdentifiers,
    [ColumnTypes['identifier object']]: ContactFormFieldIdentifierObject,
};
export default defineComponent({
    name: 'ContactFormField',
    components: {
        OrExpansionPanel,
    },
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: [Object, String, Number],
            required: false,
            default: () => undefined,
        },
    },
    emits: ['update'],
    setup() {
        return {
            FieldComponents,
        };
    },
    computed: {
        oldFieldSchema() {
            return {
                visibility: true,
                label: this.fieldSchema.label,
                singularLabel: this.fieldSchema.singular_label,
                order: 0,
                properties: this.fieldSchema.properties,
                triggers: [],
                typeOf: this.fieldSchema.type,
            };
        },
        isComponentImplemented() {
            return this.fieldSchema.type in FieldComponents;
        },
        component() {
            return hasOwnProperty(FieldComponents, this.fieldSchema.type)
                ? FieldComponents[this.fieldSchema.type]
                : null;
        },
        showExpansionPanel() {
            return ![
                ColumnTypes.string,
                ColumnTypes.double,
                ColumnTypes.integer,
                ColumnTypes.md,
                ColumnTypes.date,
                ColumnTypes['array of strings'],
                ColumnTypesExtended.dateTz,
            ].includes(this.fieldSchema.type);
        },
    },
});

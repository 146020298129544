import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "contact-form-field-array-of-objects flex flex-col gap-md+ md:gap-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_form_field_object_array_item = _resolveComponent("contact-form-field-object-array-item");
    const _component_or_icon = _resolveComponent("or-icon");
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localData, (object, index) => {
            return (_openBlock(), _createBlock(_component_contact_form_field_object_array_item, {
                key: index,
                properties: _ctx.fieldSchema.properties,
                "singular-label": _ctx.fieldSchema.singularLabel,
                index: index,
                item: object,
                "onDelete:item": _ctx.handleDelete
            }, null, 8, ["properties", "singular-label", "index", "item", "onDelete:item"]));
        }), 128)),
        _createVNode(_component_or_button, {
            class: "add-object self-start",
            "data-test": "add-object-button",
            variant: "link",
            onClick: _ctx.handleAddObject
        }, {
            default: _withCtx(() => [
                _createVNode(_component_or_icon, { icon: "add" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('contacts.contactsForm.addItem', { singularLabel: _ctx.fieldSchema.singularLabel })), 1)
            ]),
            _: 1
        }, 8, ["onClick"])
    ]));
}

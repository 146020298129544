import VPagination from '@hennge/vue3-pagination';
import { defineComponent } from 'vue';
import { OrPaginationLengthSelector } from '../../OrPagination';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
export default defineComponent({
    name: 'ContactBooksPagination',
    components: {
        OrPaginationLengthSelector,
        VPagination,
    },
    props: {
        limit: {
            type: Number,
            required: true,
        },
        offset: {
            type: Number,
            required: true,
        },
        total: {
            type: Number,
            required: false,
            default: () => null,
        },
    },
    emits: ['change', 'update:page', 'update:limit'],
    computed: {
        pages() {
            return this.total ?
                Math.ceil(this.total / this.limit)
                : 0;
        },
        currentPage: {
            get: function () {
                return this.offset >= this.total
                    ? Math.floor(this.total / this.limit)
                    : Math.floor(this.offset / this.limit) + 1;
            },
            set(val) {
                this.$emit('update:page', val);
            },
        },
    },
});

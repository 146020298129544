import { OrModalV3 } from '@onereach/ui-components';
import { OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';
import { defineComponent, ref } from 'vue';
import { CompositeColumnKeys } from '@/constants';
import { getCompositeColumnsMapping, convertSchemaToOrderedArray } from '@/utils';
import { ContactsViewModalProfileInfo, ContactsViewField } from './ContactsViewField';
const alreadyShownKeys = getCompositeColumnsMapping()[CompositeColumnKeys.NAME].parts;
export default defineComponent({
    name: 'ContactViewModal',
    components: {
        OrModalV3,
        OrButton,
        ContactsViewModalProfileInfo,
        ContactsViewField,
    },
    props: {
        isReadonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        schema: {
            type: Object,
            required: false,
            default: () => null,
        },
        contact: {
            type: Object,
            required: false,
            default: () => null,
        },
    },
    expose: ['modalComponent'],
    setup() {
        return {
            modalComponent: ref(),
        };
    },
    computed: {
        orderedSchemaItems() {
            return convertSchemaToOrderedArray(this.schema).filter(({ key }) => !alreadyShownKeys.includes(key));
        },
        firstName() {
            return this.contact.firstName;
        },
        lastName() {
            return this.contact.lastName;
        },
        profilePictureUrl() {
            return this.contact.profileImage;
        },
    },
});

import { OrIconV3 as OrIcon, OrButtonV3 as OrButton } from '@onereach/ui-components';
import _ from 'lodash';
import { defineComponent } from 'vue';
import { getDefaultIdentifier, isPropertyEmpty } from '@/utils';
import { ContactFormFieldObjectArrayItem } from './ContactFormFieldArrayItem';
export default defineComponent({
    name: 'ContactFormFieldArrayOfIdentifiers',
    components: {
        OrIcon,
        OrButton,
        ContactFormFieldObjectArrayItem,
    },
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['update:data'],
    data() {
        return {
            localData: _.cloneDeep(this.data),
        };
    },
    computed: {
        properties() {
            return _.isEmpty(this.fieldSchema.properties) ? {
                val: 'string',
                type: 'array',
            } : this.fieldSchema.properties;
        },
    },
    watch: {
        localData: {
            handler(val) {
                const notEmptyData = _.filter(val, _.negate(isPropertyEmpty));
                this.$emit('update:data', notEmptyData);
            },
            deep: true,
        },
    },
    methods: {
        handleAddIdentifier() {
            this.localData.push(getDefaultIdentifier());
        },
        handleDelete(index) {
            var _a;
            (_a = this.localData) === null || _a === void 0 ? void 0 : _a.splice(index, 1);
        },
    },
});

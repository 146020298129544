import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "break-words typography-body-2-bold" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_i18n_t = _resolveComponent("i18n-t");
    const _component_OrConfirmV3 = _resolveComponent("OrConfirmV3");
    return (_openBlock(), _createBlock(_component_OrConfirmV3, {
        ref: "confirm",
        "title-text": _ctx.$t('common.deletePermanently'),
        loading: _ctx.isLoading,
        "confirm-button-text": _ctx.$t('common.delete'),
        "deny-button-text": _ctx.$t('common.keepIt'),
        "confirmation-text": _ctx.contactBookName,
        "text-confirmation-label": _ctx.$t('contactBooks.deleteModal.contactBookName'),
        "text-confirmation-error": _ctx.$t('contactBooks.deleteModal.namesDoNotMatch'),
        "auto-close-on-confirm": false,
        "data-test": "contact-books-delete-modal",
        onConfirm: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('accept', _ctx.contactBookName)))
    }, {
        default: _withCtx(() => [
            _createVNode(_component_i18n_t, {
                keypath: _ctx.tKey,
                scope: "global",
                tag: "span"
            }, {
                contactBook: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.contactBookName), 1)
                ]),
                _: 1
            }, 8, ["keypath"]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('contactBooks.deleteModal.enterNameToConfirm')), 1)
        ]),
        _: 1
    }, 8, ["title-text", "loading", "confirm-button-text", "deny-button-text", "confirmation-text", "text-confirmation-label", "text-confirmation-error"]));
}

import { parse, transforms } from 'json2csv';
import _ from 'lodash';
import { ExportFormats } from '@/constants';
import createAndDownloadFile from './createAndDownloadFile';
export function exportAndDownload(dataToExport, fileName, format, fieldsToExport) {
    switch (format) {
        case ExportFormats.JSON: {
            const json = JSON.stringify(dataToExport);
            createAndDownloadFile(json, `${fileName}.json`, 'application/json');
            break;
        }
        case ExportFormats.CSV: {
            const fields = fieldsToExport;
            const fieldsToUnwind = _.uniq(fields.filter(field => field.includes('.'))
                .map(field => field.substring(0, field.indexOf('.'))));
            const transformsToUse = [transforms.unwind({ paths: fieldsToUnwind })];
            const opts = {
                fields,
                transforms: transformsToUse,
            };
            const csv = parse(dataToExport, opts);
            createAndDownloadFile(csv, `${fileName}.csv`, 'text/csv');
            break;
        }
        default:
            return;
    }
}
export function exportAndDownloadV2(format, dataToExport, fileName, schema) {
    const parser = format === ExportFormats.JSON
        ? dataToJsonString(dataToExport, fileName, schema)
        : dataToCsvString(dataToExport, fileName, schema);
    parser.createAndDownloadFile();
}
function dataToJsonString(contacts, fileName, schema) {
    const preparedData = contacts.map(contact => {
        const { contactField, fieldValueField } = splitSchema(schema);
        // contact own fields
        const cv = contactField.reduce((acc, i) => {
            acc[i.label] = contact[i.id];
            return acc;
        }, {});
        // contacts field values
        const fv = Object.entries(contact.fieldValues).reduce((acc, [key, val]) => {
            const fieldSchema = fieldValueField.find(s => s.id === key);
            if (fieldSchema) {
                const fieldProps = Object.keys(fieldSchema.properties || {});
                acc[fieldSchema.label] = fieldProps.length
                    ? (Array.isArray(val.value) ? val.value : [val.value]).map(v => {
                        return Object.fromEntries(fieldProps.map(p => [p, v ? v[p] : null]));
                    })
                    : val.value;
            }
            return acc;
        }, {});
        return {
            ...cv,
            ...fv,
        };
    });
    const json = JSON.stringify(preparedData);
    return {
        createAndDownloadFile: () => createAndDownloadFile(json, `${fileName}.json`, 'application/json'),
        rowData: json,
    };
}
function dataToCsvString(contacts, fileName, schema) {
    const json = JSON.parse(dataToJsonString(contacts, fileName, schema).rowData);
    const fieldsToUnwind = new Set();
    const fields = schema.map(i => {
        const keys = Object.keys(i.properties || {});
        if (keys.length) {
            return keys.map(k => {
                const field = `${i.label}.${k}`;
                fieldsToUnwind.add(i.label);
                return field;
            });
        }
        return i.label;
    }).flat();
    // order columns, ID should be first
    fields.splice(0, 0, fields.splice(fields.indexOf('Id'), 1)[0]);
    const transformsToUse = [transforms.unwind({ paths: Array.from(fieldsToUnwind) })];
    const csv = parse(json, {
        fields,
        transforms: transformsToUse,
    });
    return {
        createAndDownloadFile: () => createAndDownloadFile(csv, `${fileName}.csv`, 'text/csv'),
        rowData: csv,
    };
}
function splitSchema(schema) {
    return schema.reduce((acc, i) => {
        i.isContactField ? acc.contactField.push(i) : acc.fieldValueField.push(i);
        return acc;
    }, {
        contactField: [],
        fieldValueField: [],
    });
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    class: "contact-book-settings-form flex flex-col gap-md",
    "data-test": "contact-book-settings-form"
};
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "row" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_or_input = _resolveComponent("or-input");
    const _component_ContactBooksSchemaEditor = _resolveComponent("ContactBooksSchemaEditor");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_or_input, {
                ref: "bookNameTextbox",
                modelValue: _ctx.localBookName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localBookName) = $event)),
                label: _ctx.$t('contactBooks.addForm.contactBookName'),
                placeholder: _ctx.$t('contactBooks.addForm.enterContactBookName'),
                error: _ctx.v$.localBookName.$error ? (_a = _ctx.v$.localBookName.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message : undefined,
                onBlur: _ctx.onBookNameBlur
            }, null, 8, ["modelValue", "label", "placeholder", "error", "onBlur"])
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ContactBooksSchemaEditor, {
                ref: "schemaEditor",
                "field-schemas": _ctx.fieldSchemas,
                "all-field-schemas": _ctx.allFieldSchemas,
                "schema-presets": _ctx.schemaPresets,
                "selected-schema-presets": _ctx.bookSchemaPresets,
                "required-fields": _ctx.requiredFields,
                "required-fields-properties": _ctx.requiredFieldsProperties
            }, null, 8, ["field-schemas", "all-field-schemas", "schema-presets", "selected-schema-presets", "required-fields", "required-fields-properties"])
        ])
    ]));
}

import { OrDateTimePickerV3 as OrDateTimePicker } from '@onereach/ui-components'; //TODO: replace with V3
import { defineComponent } from 'vue';
import { dateToLocalISO } from '@/utils';
export default defineComponent({
    name: 'ContactFormFieldDate',
    components: {
        OrDateTimePicker,
    },
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: [String, Date],
            required: false,
            default: null,
        },
    },
    emits: ['update:data'],
    data() {
        return {
            localData: this.data ? new Date(this.data) : null,
        };
    },
    watch: {
        data(val) {
            var _a;
            const newDate = new Date(val);
            if (+((_a = this.localData) !== null && _a !== void 0 ? _a : 0) != +newDate)
                this.localData = newDate;
        },
        localData(val) {
            if (+val != +(new Date(this.data)))
                this.$emit('update:data', val ? dateToLocalISO(val) : null);
        },
    },
});

import { OrMenuItemV3 } from '@onereach/ui-components';
import { defineComponent } from 'vue';
import { RouterLink } from 'vue-router';
export default defineComponent({
    name: 'SettingsMenu',
    components: {
        RouterLink,
        OrMenuItemV3,
    },
    setup() {
        const menuItems = [
            {
                label: 'Indices',
                to: '/settings/indices',
                icon: 'tag',
            },
            {
                label: 'Deprecated indices',
                to: '/settings/deprecated-indices',
                icon: 'do_not_disturb_on',
            },
            {
                label: 'Migrations',
                to: '/settings/migrations',
                icon: 'sync',
            },
            {
                label: 'Changelog',
                to: '/settings/changelog',
                icon: 'assignment',
            },
        ];
        return {
            menuItems,
        };
    },
});

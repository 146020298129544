import { useLocalStorage } from '@vueuse/core';
import _ from 'lodash';
import { getDefaultContactsTableStoredValueV2 } from '../utils';
function useContactsTableStoredValue(bookId = 'all', accountId, fieldSchemas = []) {
    var _a;
    const contactsTableLocalStorageKey = bookId + ' Contacts Table ' + accountId;
    const defaultContactsTableStoredValue = getDefaultContactsTableStoredValueV2(fieldSchemas);
    const contactsTableStoredValue = useLocalStorage(contactsTableLocalStorageKey, defaultContactsTableStoredValue);
    const defaultOrder = defaultContactsTableStoredValue.order;
    contactsTableStoredValue.value.order = _.defaults(contactsTableStoredValue.value.order, defaultOrder);
    if (fieldSchemas.length > 0) {
        const defaultColumns = defaultContactsTableStoredValue.columns;
        contactsTableStoredValue.value.columns = _.pick(_.defaults(contactsTableStoredValue.value.columns, defaultColumns), _.keys(defaultColumns));
        const columnsIds = _.keys(defaultContactsTableStoredValue.columns);
        if (!columnsIds.includes(contactsTableStoredValue.value.order.column)) {
            contactsTableStoredValue.value.order.column = 'created_at';
        }
    }
    const defaultPaginationLimit = defaultContactsTableStoredValue.paginationLimit;
    contactsTableStoredValue.value.paginationLimit = (_a = contactsTableStoredValue.value.paginationLimit) !== null && _a !== void 0 ? _a : defaultPaginationLimit;
    return contactsTableStoredValue;
}
export default useContactsTableStoredValue;

import { OrIconButtonV3 as OrIconButton } from '@onereach/ui-components';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsUnlinkButton',
    components: {
        OrIconButton,
    },
    props: {
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['click'],
});

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex justify-end gap-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contacts_columns_form = _resolveComponent("contacts-columns-form");
    const _component_or_button = _resolveComponent("or-button");
    const _component_OrModalV3 = _resolveComponent("OrModalV3");
    return (_openBlock(), _createBlock(_component_OrModalV3, {
        ref: "modalComponent",
        "data-test": "table-settings-modal",
        size: "s"
    }, {
        header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contacts.tableSettingsModal.title')), 1)
        ]),
        footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_button, {
                    "data-test": "cancel-table-settings-button",
                    type: "outline",
                    content: _ctx.$t('common.cancel'),
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('close')))
                }, null, 8, ["content"]),
                _createVNode(_component_or_button, {
                    "data-test": "apply-table-settings-button",
                    content: _ctx.$t('common.apply'),
                    "is-disabled": !_ctx.isChanged,
                    onClick: _ctx.applyChanges
                }, null, 8, ["content", "is-disabled", "onClick"])
            ])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_contacts_columns_form, {
                modelValue: _ctx.arrayOfColumns,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.arrayOfColumns) = $event)),
                title: _ctx.$t('contacts.tableSettingsModal.showColumns')
            }, null, 8, ["modelValue", "title"])
        ]),
        _: 1
    }, 512));
}

import { OrConfirmV3, OrTextV3, OrTooltipV3 } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'ContactBooksShareModalV2',
    components: {
        OrConfirmV3,
        OrTextV3,
        OrTooltipV3,
    },
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        accountName: {
            type: String,
            required: false,
        },
        bookName: {
            type: String,
            required: false,
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    expose: ['confirm'],
    setup() {
        const confirm = ref(null);
        return {
            confirm,
        };
    },
});

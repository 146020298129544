import { defineStore } from 'pinia';
import { ContactsApi } from '@/api';
import _ from 'lodash';
export const useContactsRuleGroupsStore = defineStore('ruleGroups', {
    state: () => ({
        items: [],
    }),
    getters: {
        getRuleGroupByName: (state) => {
            return (name) => {
                return _.find(state.items, { name });
            };
        },
        getRuleGroupByGroupId: (state) => {
            return (groupId) => {
                return _.find(state.items, { groupId });
            };
        },
    },
    actions: {
        async getRuleGroups(payload = {}) {
            const { from = 0, searchStr: searchPhrase, } = payload;
            const { data: items } = await ContactsApi.getContactRuleGroupsList({
                from,
                searchPhrase,
            });
            this.items = items;
        },
        async addRuleGroup(payload) {
            await ContactsApi.addContactRuleGroup(payload);
        },
        async deleteRuleGroup({ groupId }) {
            await ContactsApi.deleteContactRuleGroup({ groupId });
        },
        async getRuleGroup(groupId) {
            const data = await ContactsApi.getContactRuleGroup({ groupId });
            const index = _.findIndex(this.items, { groupId: data.groupId });
            if (index > -1) {
                this.items[index] = data;
            }
            else {
                this.items.push(data);
            }
        },
        async updateRuleGroup(ruleGroup) {
            await ContactsApi.updateContactRuleGroup(ruleGroup);
            await this.getRuleGroup(ruleGroup.groupId);
        },
    },
});
export default useContactsRuleGroupsStore;

import { OrExpansionPanelV3 as OrExpansionPanel, OrIconButtonV3 as OrIconButton, OrButtonV3 as OrButton, OrInputV3 as OrInput, OrSelectV3 as OrSelect, OrErrorV3 as OrError, } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import _ from 'lodash';
import { defineComponent, ref, toRef } from 'vue';
import { ArrayOfObjectPropertyTypes, SpecialCharactersRegexp } from '@/constants';
import { alreadyExists, forbiddenChar, mustBeEqual } from '@/utils/validators';
export default defineComponent({
    name: 'ContactBooksSchemaEditorItemPropertiesItem',
    components: {
        OrExpansionPanel,
        OrIconButton,
        OrButton,
        OrInput,
        OrSelect,
        OrError,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
        isDeletable: {
            type: Boolean,
            required: false,
            default: true,
        },
        otherProperties: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['delete', 'save'],
    setup(props) {
        const expansionPanelRef = ref();
        const key = ref(props.property.key);
        const type = ref(props.property.type);
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            v$,
            expansionPanelRef,
            key,
            type,
        };
    },
    computed: {
        typeOptions() {
            return _.map(ArrayOfObjectPropertyTypes, (item) => ({
                value: item,
                label: item,
            }));
        },
    },
    validations() {
        const other = toRef(this.$props, 'otherProperties');
        return {
            key: {
                required,
                minLengthValue: minLength(3),
                notSpecialChar: forbiddenChar(SpecialCharactersRegexp, this.$t('contactBooks.schemaEditor.propertyCharNotAllowed')),
                alreadyExists: alreadyExists(other, this.$t('contactBooks.schemaEditor.propertyAlreadyExists')),
            },
            property: {
                isEditable: {
                    mustBeNotEditable: mustBeEqual(false, this.$t('contactBooks.schemaEditor.propertyNotSaved')),
                },
            },
        };
    },
    mounted() {
        var _a;
        if (this.property.isEditable)
            (_a = this.expansionPanelRef) === null || _a === void 0 ? void 0 : _a.open();
    },
    methods: {
        handleAddProperty() {
            if (this.v$.$invalid && this.v$.$silentErrors.length > 1) {
                this.v$.$touch();
                this.v$.property.isEditable.$reset();
            }
            else
                this.$emit('save', {
                    type: this.type,
                    key: this.key,
                });
        },
    },
});

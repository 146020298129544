import { defineComponent } from 'vue';
import { OrIconButton, OrButtonV2 as OrButton, OrInput, OrSwitch, OrSelect, OrIcon } from '@onereach/ui-components-legacy';
import { minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { useLocalState } from '@/composables';
import IconSelect from './ContactBooksSchemaEditorItemTriggersItemIconSelect.vue';
export default defineComponent({
    name: 'ContactBooksSchemaEditorItemTriggersItem',
    components: {
        OrButton,
        OrIconButton,
        OrInput,
        OrSwitch,
        OrSelect,
        IconSelect,
        OrIcon,
    },
    props: {
        trigger: {
            type: Object,
            required: true,
        },
        initTriggers: {
            type: Array,
            required: true,
        },
        isNew: {
            type: Boolean,
            required: false,
            default: false,
        },
        isEdit: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['delete', 'edit', 'save'],
    setup(props) {
        const { localState, isChanged, } = useLocalState(props.trigger);
        const rules = {
            trigger: {
                required,
                minLengthValue: minLength(3),
            },
            label: {
                required,
                minLengthValue: minLength(2),
            },
            icon: {
                required,
            },
        };
        const v$ = useVuelidate(rules, localState, { $scope: 'ContactBooksSchemaEditor' });
        return {
            localState,
            v$,
            isChanged,
        };
    },
    computed: {
        showForm() {
            return this.isEdit || this.isNew;
        },
        triggers() {
            return this.initTriggers.map(trigger => {
                return {
                    label: trigger.value,
                    value: trigger.value,
                };
            });
        },
    },
    methods: {
        handleSave() {
            this.$emit('save', { ...this.localState });
        },
    },
});

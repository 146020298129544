import _ from 'lodash';
import { isPropertyEmpty } from './index';
function getContactPropertiesToShow(contacts) {
    return _.reduce(contacts, (result, contact) => {
        _.each(contact, (value, key) => { if (!isPropertyEmpty(value))
            result[key] = true; });
        return result;
    }, { contactId: true });
}
export default getContactPropertiesToShow;

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    const _component_or_menu_item = _resolveComponent("or-menu-item");
    const _component_or_menu = _resolveComponent("or-menu");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_or_icon_button, {
            ref: "button",
            icon: { 'icon': 'more_vert', 'variant': 'filled-bold' },
            "data-test": "additional-overflow-menu",
            color: "default",
            onClick: _cache[0] || (_cache[0] = ($event) => { var _a; return ((_a = _ctx.menu) === null || _a === void 0 ? void 0 : _a.toggle()); })
        }, null, 512),
        _createVNode(_component_or_menu, {
            ref: "menu",
            trigger: (_a = _ctx.button) === null || _a === void 0 ? void 0 : _a.root
        }, {
            default: _withCtx(() => [
                _createVNode(_component_or_menu_item, {
                    icon: "create",
                    disabled: Boolean(_ctx.isReadonly),
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('open:editSchema')))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('contactBooks.controls.editSchema')), 1)
                    ]),
                    _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_or_menu_item, {
                    icon: "upload",
                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('export')))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('contacts.exportBook')), 1)
                    ]),
                    _: 1
                }),
                _createVNode(_component_or_menu_item, {
                    icon: "delete",
                    class: "theme-border-outline-variant dark:theme-border-outline-variant-dark !border-0 !border-t-1",
                    disabled: Boolean(_ctx.isReadonly),
                    onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('delete')))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.delete')), 1)
                    ]),
                    _: 1
                }, 8, ["disabled"])
            ]),
            _: 1
        }, 8, ["trigger"])
    ], 64));
}

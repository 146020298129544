import { OrTabsV3, useResponsive } from '@onereach/ui-components';
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from '@/composables';
export default defineComponent({
    name: 'RoutingTabsLayout',
    components: {
        OrTabsV3,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const { t } = useI18n();
        const { isMobile } = useResponsive();
        const selectedTab = ref('');
        const currentRouteName = computed(() => route.name);
        const isCustomFullPage = computed(() => ['all contacts v2', 'contacts v2'].includes(route.name));
        const tabsStyles = computed(() => ([
            isCustomFullPage.value ? 'full-screen-tabs' : '',
        ]));
        const tabsItems = computed(() => [
            {
                value: 'books v2 index',
                label: t('tabsItems.books'),
            },
            {
                value: 'all contacts v2',
                label: t('tabsItems.contacts'),
            },
            {
                value: 'field schemas',
                label: t('tabsItems.fields'),
            },
        ]);
        const isAnyTabSelected = computed(() => tabsItems.value.map(item => item.value).includes(currentRouteName.value));
        watch(() => currentRouteName.value, (val) => {
            selectedTab.value = val;
        }, { immediate: true });
        watch(() => selectedTab.value, (val) => {
            if (route.name === val) {
                return;
            }
            router.replace({ name: val });
        });
        return {
            currentRouteName,
            isCustomFullPage,
            tabsStyles,
            isMobile,
            tabsItems,
            selectedTab,
            isAnyTabSelected,
        };
    },
});

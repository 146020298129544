import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "data flex flex-col gap-sm" };
const _hoisted_2 = { class: "w-full flex flex-col gap-xs" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    const _component_or_input = _resolveComponent("or-input");
    const _component_or_select = _resolveComponent("or-select");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_error = _resolveComponent("or-error");
    const _component_or_expansion_panel = _resolveComponent("or-expansion-panel");
    return (_openBlock(), _createBlock(_component_or_expansion_panel, {
        ref: "expansionPanelRef",
        class: "container !bg-background dark:!bg-background-dark",
        "data-test": "schema-editor-item-property-item",
        label: _ctx.key ? _ctx.key : _ctx.$t('contactBooks.schemaEditor.newProperty'),
        color: _ctx.v$.$error ? 'danger' : 'default',
        variant: "card"
    }, {
        addon: _withCtx(() => [
            (_ctx.isDeletable)
                ? (_openBlock(), _createBlock(_component_or_icon_button, {
                    key: 0,
                    class: "self-center",
                    "data-test": "schema-editor-delete-property-icon",
                    icon: "delete",
                    color: "inherit",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('delete')))
                }))
                : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => {
            var _a;
            return [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_or_input, {
                        modelValue: _ctx.key,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.key) = $event)),
                        class: "min-w-0 flex-1",
                        "data-test": "schema-editor-new-property-name-input",
                        label: _ctx.$t('contactBooks.schemaEditor.name'),
                        placeholder: _ctx.$t('contactBooks.schemaEditor.enterName'),
                        readonly: !_ctx.property.isEditable,
                        error: _ctx.v$.key.$error ? (_a = _ctx.v$.key.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message : undefined,
                        onBlur: _ctx.v$.key.$touch
                    }, null, 8, ["modelValue", "label", "placeholder", "readonly", "error", "onBlur"]),
                    _createVNode(_component_or_select, {
                        modelValue: _ctx.type,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.type) = $event)),
                        "data-test": "schema-editor-property-type-select",
                        label: _ctx.$t('contactBooks.schemaEditor.type'),
                        placeholder: _ctx.$t('contactBooks.schemaEditor.enterType'),
                        options: _ctx.typeOptions,
                        readonly: !_ctx.property.isEditable
                    }, null, 8, ["modelValue", "label", "placeholder", "options", "readonly"]),
                    _createElementVNode("div", _hoisted_2, [
                        (_ctx.property.isEditable)
                            ? (_openBlock(), _createBlock(_component_or_button, {
                                key: 0,
                                class: "w-full",
                                "data-test": "schema-editor-save-property-button",
                                variant: "outlined",
                                onClick: _ctx.handleAddProperty
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('common.add')), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                        (_ctx.v$.property.isEditable.$error)
                            ? (_openBlock(), _createBlock(_component_or_error, { key: 1 }, {
                                default: _withCtx(() => {
                                    var _a;
                                    return [
                                        _createTextVNode(_toDisplayString((_a = _ctx.v$.property.isEditable.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message), 1)
                                    ];
                                }),
                                _: 1
                            }))
                            : _createCommentVNode("", true)
                    ])
                ])
            ];
        }),
        _: 1
    }, 8, ["label", "color"]));
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    class: "contact-view-field-array-of-strings flex flex-row flex-wrap gap-xs pt-xs md:pt-sm pb-md",
    "data-test": "contact-view-field-array-of-strings"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_tag = _resolveComponent("or-tag");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (name, index) => {
            return (_openBlock(), _createBlock(_component_or_tag, {
                key: index,
                "can-delete": false
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(name), 1)
                ]),
                _: 2
            }, 1024));
        }), 128))
    ]));
}

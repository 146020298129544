import { OrTagV3 as OrTag, OrButtonV3 as OrButton, OrPopoverV3 as OrPopover, useOverflow, } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'OrTagsContainer',
    components: {
        OrTag,
        OrButton,
        OrPopover,
    },
    props: {
        tags: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const container = ref();
        const button = ref();
        const popover = ref();
        const { innerItems, outerItems } = useOverflow(container);
        return {
            container,
            button,
            popover,
            innerItems,
            outerItems,
        };
    },
    computed: {
        moreText() {
            return `+${this.outerItems.length}`;
        },
        hiddenTags() {
            var _a;
            return this.tags.slice((_a = this.innerItems) === null || _a === void 0 ? void 0 : _a.length);
        },
    },
});

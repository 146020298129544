import { defineComponent } from 'vue';
import { OrButtonV2 as OrButton, OrIconButton, OrOverflowMenu, OrRadioGroup } from '@onereach/ui-components-legacy';
import ContactsFilterMenuItem from './ContactsFilterMenuItem.vue';
import { useVModel } from '@vueuse/core';
export default defineComponent({
    name: 'ContactsFilterMenu',
    components: {
        OrIconButton,
        OrButton,
        OrOverflowMenu,
        OrRadioGroup,
        ContactsFilterMenuItem,
    },
    props: {
        modelValue: {
            type: String,
            required: false,
            default: null,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        filters: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['edit', 'delete', 'create'],
    setup(props, { emit }) {
        const filterIdModel = useVModel(props, 'modelValue', emit);
        return {
            filterIdModel,
        };
    },
    computed: {
        selectedFilterName() {
            var _a;
            return (_a = this.filters.find(f => f.filterId === this.filterIdModel)) === null || _a === void 0 ? void 0 : _a.name;
        },
        radioGroupModel: {
            get() {
                return this.filterIdModel ? {
                    label: this.selectedFilterName,
                    value: this.filterIdModel,
                } : null;
            },
            set(val) {
                var _a;
                this.filterIdModel = (_a = val === null || val === void 0 ? void 0 : val.value) !== null && _a !== void 0 ? _a : null;
            },
        },
        buttonLabel() {
            return this.filterIdModel ?
                this.$t('contacts.filteredBy', { filter: this.selectedFilterName }) :
                this.$t('contacts.filter');
        },
    },
});

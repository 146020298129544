import { defineComponent } from 'vue';
import { OrAvatar } from '@onereach/ui-components-legacy';
export default defineComponent({
    name: 'ContactViewModalProfileInfo',
    components: {
        OrAvatar,
    },
    props: {
        firstName: {
            type: String,
            required: false,
            default: () => null,
        },
        lastName: {
            type: String,
            required: false,
            default: () => null,
        },
        profileImage: {
            type: String,
            required: false,
            default: () => null,
        },
    },
    computed: {
        hasName() {
            return !!this.firstName || !!this.lastName;
        },
        name() {
            var _a, _b;
            return this.hasName ?
                `${(_a = this.firstName) !== null && _a !== void 0 ? _a : ''} ${(_b = this.lastName) !== null && _b !== void 0 ? _b : ''}` :
                this.$t('contacts.noName');
        },
    },
});

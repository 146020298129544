import { filter, reject, fromPairs, map, includes } from 'lodash';
function convertFieldValuesToContact(fieldValues) {
    const contactKeys = ['id', 'created_at', 'updated_at'];
    const contactValues = filter(fieldValues, field => includes(contactKeys, field.schemaId));
    const otherValues = reject(fieldValues, field => includes(contactKeys, field.schemaId));
    return {
        ...fromPairs(map(contactValues, value => [value.schemaId, value.value])),
        data: otherValues,
    };
}
export default convertFieldValuesToContact;

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "settings-editor flex flex-col min-h-0" };
const _hoisted_2 = { class: "footer pt-md" };
const _hoisted_3 = { class: "actions flex gap-md justify-between md:justify-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_book_settings_form = _resolveComponent("contact-book-settings-form");
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_contact_book_settings_form, {
            ref: "bookSettingsForm",
            class: "grow overflow-y-auto overflow-x-hidden",
            "error-reference": "bookSettingsForm",
            "book-name": _ctx.bookName,
            "field-schemas": _ctx.fieldSchemas,
            "all-field-schemas": _ctx.allFieldSchemas,
            "schema-presets": _ctx.schemaPresets,
            "book-schema-presets": _ctx.bookSchemaPresets,
            onSubmit: _ctx.handleSubmit
        }, null, 8, ["book-name", "field-schemas", "all-field-schemas", "schema-presets", "book-schema-presets", "onSubmit"]),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_or_button, {
                    class: "grow md:flex-grow-0",
                    variant: "outlined",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('cancel')))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.back')), 1)
                    ]),
                    _: 1
                }),
                _createVNode(_component_or_button, {
                    class: "grow md:flex-grow-0",
                    loading: _ctx.isLoading || _ctx.v$.$pending,
                    onClick: _ctx.triggerSubmit
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.create')), 1)
                    ]),
                    _: 1
                }, 8, ["loading", "onClick"])
            ])
        ])
    ]));
}

import { defineComponent } from 'vue';
import { groupSchemaItems } from '@/utils';
import ContactBooksSchemaEditorItemListGroup from './ContactBooksSchemaEditorItemListGroup.vue';
import _ from 'lodash';
export default defineComponent({
    name: 'ContactBooksSchemaEditorItemListGrouped',
    components: {
        ContactBooksSchemaEditorItemListGroup,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        groupMapping: {
            type: Object,
            required: false,
            default: null,
        },
        customGroupName: {
            type: String,
            required: false,
            default: 'custom',
        },
    },
    emits: ['addColumn'],
    data() {
        return {
            openedGroups: {},
        };
    },
    computed: {
        groupNames() {
            return Object.keys(this.groupedItems);
        },
        groupedItems() {
            return groupSchemaItems(this.items, this.groupMapping, this.customGroupName);
        },
    },
    methods: {
        toggle(name, isOpened) {
            this.openedGroups[name] = isOpened;
        },
        toggleGroupByItemKey(key, isOpened) {
            const groupName = _.findKey(this.groupedItems, items => _.some(items, { key }));
            if (groupName)
                this.toggle(groupName, isOpened);
        },
    },
});

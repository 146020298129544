import { TranslatableError } from '@/errors';
import { getStoreIdWithoutVersion } from '@/utils';
function TranslatableErrorHandlerPiniaPlugin({ store }) {
    store.$onAction(({ name, onError }) => {
        onError(e => {
            const storeId = getStoreIdWithoutVersion(store.$id);
            throw e instanceof TranslatableError
                ? new TranslatableError(`errors.${storeId}.${name}`, e)
                : e;
        });
    });
}
export default TranslatableErrorHandlerPiniaPlugin;

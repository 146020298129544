import { defineComponent } from 'vue';
import { OrSelect } from '@onereach/ui-components-legacy';
export default defineComponent({
    name: 'ContactsFilterFormMatch',
    components: {
        OrSelect,
    },
    props: {
        modelValue: {
            type: Boolean,
            required: false,
            default: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        options() {
            return [
                {
                    value: 'all',
                    label: this.$t('contacts.filterModal.match.all'),
                },
                {
                    value: 'any',
                    label: this.$t('contacts.filterModal.match.any'),
                },
            ];
        },
        selectModel: {
            get: function () {
                return this.modelValue ? 'all' : 'any';
            },
            set: function (val) {
                this.$emit('update:modelValue', val === 'all');
            },
        },
    },
});

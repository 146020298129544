import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    return (_openBlock(), _createBlock(_component_or_icon_button, {
        icon: "merge_type",
        "data-test": "merge-contacts-link",
        tooltip: _ctx.$t('contacts.mergeV2'),
        disabled: _ctx.isDisabled,
        size: "l",
        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('click')))
    }, null, 8, ["tooltip", "disabled"]));
}

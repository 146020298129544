import '@onereach/styles';
import { createApp } from 'vue';
import i18n from '@/i18n';
import router from '@/router';
import SentryApi from '@/sentry';
import createPinia from '@/stores';
import App from './ContactsApp.vue';
const app = createApp(App);
app.use(i18n);
app.use(createPinia());
app.use(router);
// handle vue warnings to exclude some of them
app.config.warnHandler = (msg, _, trace) => ![
    'Extraneous non-props attributes (data-test)', // we use this attribute for testing
].some((warning) => msg.includes(warning)) &&
    console.warn('[Vue warn]: '.concat(msg).concat(trace));
//should be after createPinia, because we need some info form our store
SentryApi.init(app, router);
export default app;

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "content flex flex-col gap-md+ md:gap-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    const _component_or_input = _resolveComponent("or-input");
    const _component_OrTagInputV3 = _resolveComponent("OrTagInputV3");
    const _component_or_expansion_panel = _resolveComponent("or-expansion-panel");
    return (_openBlock(), _createBlock(_component_or_expansion_panel, {
        class: "contact-form-field-object-item",
        label: _ctx.itemName,
        variant: "card",
        "is-open": ""
    }, {
        addon: _withCtx(() => [
            _createVNode(_component_or_icon_button, {
                class: "delete-button",
                color: "default",
                icon: "delete",
                onClick: _ctx.handleDelete
            }, null, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (type, name) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: name,
                        class: "contact-form-field-object-item-prop"
                    }, [
                        (type === _ctx.ArrayOfObjectPropertyTypes.STRING)
                            ? (_openBlock(), _createBlock(_component_or_input, {
                                key: 0,
                                modelValue: _ctx.itemModel[name],
                                "onUpdate:modelValue": ($event) => ((_ctx.itemModel[name]) = $event),
                                label: _ctx.getLabel(name),
                                placeholder: _ctx.$t('contacts.contactsForm.enterFieldValue', { name: _ctx.getLabel(name) })
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "placeholder"]))
                            : _createCommentVNode("", true),
                        (type === _ctx.ArrayOfObjectPropertyTypes.ARRAY)
                            ? (_openBlock(), _createBlock(_component_OrTagInputV3, {
                                key: 1,
                                modelValue: _ctx.itemModel[name],
                                "onUpdate:modelValue": ($event) => ((_ctx.itemModel[name]) = $event),
                                overflow: "hidden",
                                label: _ctx.getLabel(name),
                                placeholder: _ctx.$t('contacts.contactsForm.enterFieldValue', { name: _ctx.getLabel(name) })
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "placeholder"]))
                            : _createCommentVNode("", true)
                    ]));
                }), 128))
            ])
        ]),
        _: 1
    }, 8, ["label"]));
}

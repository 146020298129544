import { OrTagInputV3 } from '@onereach/ui-components'; //TODO: replace with OrTagsV3
import { useVModel } from '@vueuse/core';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactFormFieldArrayOfStrings',
    components: {
        OrTagInputV3,
    },
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['update:data'],
    setup(props, { emit }) {
        const localData = useVModel(props, 'data', emit);
        return {
            localData,
        };
    },
});

import { UserRoles } from '@onereach/auth-ui-module';
import isContactsV1Enabled from './isContactsV1Enabled';
import isContactsV2Enabled from './isContactsV2Enabled';
function getMenuItems($t, role = UserRoles.user) {
    let menuItems = [];
    if (isContactsV1Enabled()) {
        menuItems = [
            ...(isContactsV2Enabled()
                ? [{
                        label: $t('menuItems.contactBooks'),
                        url: '/books',
                    },
                    {
                        label: $t('menuItems.oldContactBooks'),
                        url: '/contact-books',
                    }]
                : [{
                        label: $t('menuItems.contactBooks'),
                        url: '/contact-books',
                    },
                    {
                        label: $t('menuItems.visibilityRules'),
                        url: '/visibility-rules',
                    }]),
        ];
    }
    if (role === UserRoles.admin || role === UserRoles.superAdmin) {
        menuItems.push({
            label: $t('menuItems.settings'),
            url: '/settings',
        });
    }
    return menuItems;
}
export default getMenuItems;

import { useToastV3 as useToast } from '@onereach/ui-components';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
function useImportedContactsNotification() {
    const toast = useToast();
    onMounted(() => {
        const { params: { failedContactsCount, createdContactsCount } } = useRoute();
        if (createdContactsCount) {
            const showNotification = parseInt(createdContactsCount, 10) > 0
                ? toast.success
                : toast.error;
            showNotification({ messageText: `Created contacts count: ${createdContactsCount}` });
        }
        if (failedContactsCount && parseInt(failedContactsCount, 10) > 0) {
            toast.error({ messageText: `Failed contacts count: ${failedContactsCount}` });
        }
    });
}
export default useImportedContactsNotification;

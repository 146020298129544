import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "p-md gap-md flex flex-col md:w-[352px] max-h-[480px]" };
const _hoisted_2 = { class: "typography-headline-4" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a, _b, _c;
    const _component_or_icon = _resolveComponent("or-icon");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_checkbox_group = _resolveComponent("or-checkbox-group");
    const _component_or_popover = _resolveComponent("or-popover");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_or_button, {
            ref: "menuButtonRef",
            "data-test": "contact-table-settings-button",
            class: _normalizeClass(["!rounded-full !py-[6px] !border-none !gap-xs", {
                    '!bg-transparent': ((_a = _ctx.popoverRef) === null || _a === void 0 ? void 0 : _a.state) != 'open',
                }]),
            variant: 'outlined',
            "force-state": ((_b = _ctx.popoverRef) === null || _b === void 0 ? void 0 : _b.state) == 'open' ? 'active' : undefined,
            onClick: _cache[0] || (_cache[0] = ($event) => { var _a; return ((_a = _ctx.popoverRef) === null || _a === void 0 ? void 0 : _a.toggle()); })
        }, {
            default: _withCtx(() => {
                var _a;
                return [
                    _createVNode(_component_or_icon, { icon: "visibility" }),
                    _createElementVNode("span", {
                        class: _normalizeClass({ '!text-on-background dark:!text-on-background-dark': ((_a = _ctx.popoverRef) === null || _a === void 0 ? void 0 : _a.state) != 'open' })
                    }, _toDisplayString(_ctx.$t('contacts.hideFields')), 3)
                ];
            }),
            _: 1
        }, 8, ["class", "force-state"]),
        _createVNode(_component_or_popover, {
            ref: "popoverRef",
            trigger: (_c = _ctx.menuButtonRef) === null || _c === void 0 ? void 0 : _c.root,
            placement: "bottom-start"
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('contacts.hideFields')), 1),
                    _createVNode(_component_or_checkbox_group, {
                        "model-value": _ctx.columnsModel,
                        class: "overflow-auto",
                        label: _ctx.$t('contacts.tableSettingsMenu.itemsLabel'),
                        options: _ctx.columnsOptions,
                        "onUpdate:modelValue": _ctx.onColumnsModelUpdate
                    }, null, 8, ["model-value", "label", "options", "onUpdate:modelValue"])
                ])
            ]),
            _: 1
        }, 8, ["trigger"])
    ], 64));
}

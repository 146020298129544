import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-361f1004"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "container" };
const _hoisted_2 = { class: "data" };
const _hoisted_3 = { class: "title" };
const _hoisted_4 = { class: "data-row" };
const _hoisted_5 = {
    key: 1,
    class: "data-row",
    "data-test": "schema-editor-property-row"
};
const _hoisted_6 = { class: "key" };
const _hoisted_7 = { class: "type" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_or_input = _resolveComponent("or-input");
    const _component_or_select = _resolveComponent("or-select");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.property.isEditable)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('contactBooks.schemaEditor.newProperty')), 1),
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_or_input, {
                            modelValue: _ctx.key,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.key) = $event)),
                            "data-test": "schema-editor-new-property-name-input",
                            label: _ctx.$t('contactBooks.schemaEditor.name'),
                            "is-invalid": _ctx.v$.key.$error,
                            error: (_a = _ctx.v$.key.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message,
                            onBlur: _ctx.v$.key.$touch
                        }, null, 8, ["modelValue", "label", "is-invalid", "error", "onBlur"]),
                        _createVNode(_component_or_select, {
                            modelValue: _ctx.type,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.type) = $event)),
                            "data-test": "schema-editor-property-type-select",
                            label: _ctx.$t('contactBooks.schemaEditor.type'),
                            options: _ctx.typeOptions
                        }, null, 8, ["modelValue", "label", "options"]),
                        _createVNode(_component_or_button, {
                            "data-test": "schema-editor-save-property-button",
                            "is-disabled": _ctx.v$.$invalid,
                            content: _ctx.$t('contactBooks.schemaEditor.add'),
                            onClick: _ctx.handleAddProperty
                        }, null, 8, ["is-disabled", "content", "onClick"])
                    ])
                ], 64))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.property.key), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.property.type), 1)
                ]))
        ]),
        (_ctx.isDeletable)
            ? (_openBlock(), _createBlock(_component_or_icon_button, {
                key: 0,
                "data-test": "schema-editor-delete-property-icon",
                icon: "delete",
                "icon-color": "neutral-4",
                onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('delete')))
            }))
            : _createCommentVNode("", true)
    ]));
}

import { defineComponent } from 'vue';
import { SchemaItemTypes } from '@/constants';
import { hasOwnProperty, isPropertyEmpty } from '@/utils';
import ContactsViewFieldString from './ContactsViewFieldString.vue';
import ContactsViewFieldDate from './ContactsViewFieldDate.vue';
import ContactsViewFieldArrayOfStrings from './ContactsViewFieldArrayOfStrings.vue';
import ContactsViewFieldArrayOfObjects from './ContactsViewFieldArrayOfObjects.vue';
import ContactsViewFieldArrayOfIdentifiers from './ContactsViewFieldArrayOfIdentifiers.vue';
import ContactsViewFieldIdentifierObject from './ContactsViewFieldIdentifierObject.vue';
import ContactsViewFieldMD from './ContactsViewFieldMD.vue';
const ViewComponents = {
    [SchemaItemTypes.STRING]: ContactsViewFieldString,
    [SchemaItemTypes.DATE]: ContactsViewFieldDate,
    [SchemaItemTypes.DOUBLE]: ContactsViewFieldString,
    [SchemaItemTypes.MD]: ContactsViewFieldMD,
    [SchemaItemTypes.INTEGER]: ContactsViewFieldString,
    [SchemaItemTypes.ARRAY_OF_STRINGS]: ContactsViewFieldArrayOfStrings,
    [SchemaItemTypes.ARRAY_OF_OBJECTS]: ContactsViewFieldArrayOfObjects,
    [SchemaItemTypes.ARRAY_OF_IDENTIFIERS]: ContactsViewFieldArrayOfIdentifiers,
    [SchemaItemTypes.IDENTIFIER_OBJECT]: ContactsViewFieldIdentifierObject,
};
export default defineComponent({
    name: 'ContactsViewField',
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: [Object, String, Number],
            required: false,
            default: () => undefined,
        },
        alwaysShow: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        return {
            ViewComponents,
        };
    },
    computed: {
        isEmpty() {
            return isPropertyEmpty(this.data);
        },
        isComponentImplemented() {
            return this.fieldSchema.typeOf in ViewComponents;
        },
        component() {
            return hasOwnProperty(ViewComponents, this.fieldSchema.typeOf)
                ? ViewComponents[this.fieldSchema.typeOf]
                : null;
        },
    },
});

import { OrConfirmV3 } from '@onereach/ui-components';
import { defineComponent, ref, computed } from 'vue';
export default defineComponent({
    name: 'ContactsDeleteModal',
    components: {
        OrConfirmV3,
    },
    props: {
        contactIds: {
            type: Array,
            required: false,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        isDeletingAll: {
            type: Boolean,
            required: false,
            default: false,
        },
        contactBookName: {
            type: String,
            required: false,
            default: () => null,
        },
    },
    emits: ['accept'],
    expose: ['confirm'],
    setup(props) {
        const confirm = ref(null);
        const contactsCount = computed(() => props.contactIds.length);
        return {
            confirm,
            contactsCount,
        };
    },
});

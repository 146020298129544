import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (!_ctx.isEmpty)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
            key: 0,
            contact: _ctx.contact,
            "column-key": _ctx.columnKey
        }, null, 8, ["contact", "column-key"]))
        : _createCommentVNode("", true);
}

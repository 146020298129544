import { OrModalV3 } from '@onereach/ui-components';
import { OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';
import useVuelidate from '@vuelidate/core';
import { defineComponent, ref } from 'vue';
import ContactBookSettingsForm from './ContactBookSettingsForm.vue';
export default defineComponent({
    name: 'ContactBooksSettingsModal',
    components: {
        ContactBookSettingsForm,
        OrModalV3,
        OrButton,
    },
    props: {
        book: {
            type: Object,
            required: false,
            default: () => null,
        },
        initTriggers: {
            type: Array,
            required: false,
            default: () => [],
        },
        groupMapping: {
            type: Object,
            required: false,
            default: () => null,
        },
        schemaPresets: {
            type: Object,
            required: false,
            default: () => null,
        },
        isSubmitting: {
            type: Boolean,
            required: false,
            default: false,
        },
        requiredFields: {
            type: Array,
            required: false,
            default: () => [],
        },
        requiredFieldsProperties: {
            type: Object,
            required: false,
            default: () => null,
        },
    },
    emits: ['update', 'close'],
    expose: ['modalComponent'],
    setup() {
        const bookSettingsForm = ref();
        const modalComponent = ref();
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            bookSettingsForm,
            modalComponent,
            v$,
        };
    },
    computed: {
        notChanged() {
            return this.v$.$invalid && this.v$.$silentErrors[0].$uid === 'schemaEditor-notChanged';
        },
    },
    methods: {
        async triggerSubmit() {
            var _a, _b;
            this.v$.$touch();
            if (await this.v$.$validate()) {
                (_a = this.bookSettingsForm) === null || _a === void 0 ? void 0 : _a.submit();
            }
            else {
                (_b = this.bookSettingsForm) === null || _b === void 0 ? void 0 : _b.scrollToFirstInvalidItem();
            }
        },
        handleSubmit(schema) {
            this.$emit('update', schema);
        },
    },
});

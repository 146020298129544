import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "flex flex-col" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_skeleton_text = _resolveComponent("or-skeleton-text");
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (idx) => {
                return (_openBlock(), _createBlock(_component_or_skeleton_text, {
                    key: idx,
                    "font-size": 24,
                    "line-height": 28.64,
                    animated: true,
                    class: "flex-1"
                }));
            }), 128))
        ])
    ]));
}

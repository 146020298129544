import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_search = _resolveComponent("or-search");
    return (_openBlock(), _createBlock(_component_or_search, {
        "data-test": "contact-search-input",
        class: "search-box min-w-[250px] md:w-[352px]",
        placeholder: _ctx.$t('contacts.searchPlaceholder'),
        onSearch: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('search', $event)))
    }, null, 8, ["placeholder"]));
}

import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactViewFieldString',
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: [String, Number],
            required: false,
            default: () => '',
        },
    },
});

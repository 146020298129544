import { OrTagV3 as OrTag } from '@onereach/ui-components';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactViewFieldArrayOfStrings',
    components: {
        OrTag,
    },
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
});

import { ColumnTypes } from '@onereach/types-contacts-api';
import { OrButtonV3 as OrButton, OrSelectV3 as OrSelect, OrInputV3 as OrInput, } from '@onereach/ui-components';
import useVuelidate from '@vuelidate/core';
import { helpers, minLength, required } from '@vuelidate/validators';
import { get } from '@vueuse/core';
import _ from 'lodash';
import { defineComponent, watch, computed } from 'vue';
import { ContactsApiV2 } from '@/api';
import { TranslatableError } from '@/errors';
import { useLocalState } from '@/composables';
import { forbiddenChar } from '@/utils/validators';
import ContactBooksSchemaEditorItemProperties from './ContactBooksSchemaEditorItemProperties.vue';
import DebounceInput from './DebounceInput.vue';
export default defineComponent({
    name: 'ContactBooksSchemaEditorItemContent',
    components: {
        ContactBooksSchemaEditorItemProperties,
        DebounceInput,
        OrButton,
        OrInput,
        OrSelect,
    },
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        undeletableProperties: {
            type: Array,
            required: false,
            default: () => [],
        },
        allItems: {
            type: Array,
            required: false,
            default: () => [],
        },
        isSingleItem: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['update'],
    setup(props) {
        const { localState, isChanged, reset, replace } = useLocalState(props.fieldSchema);
        const showSingularLabel = computed(() => {
            return [
                ColumnTypes['array of identifiers'],
                ColumnTypes['array of objects'],
            ].includes(get(localState.type));
        });
        const machine_name = computed(() => {
            return localState.machine_name;
        });
        watch(() => props.fieldSchema, (fieldSchema) => replace(get(fieldSchema)), { deep: true });
        watch(machine_name, async () => {
            const validation = v$.value.localState.machine_name;
            if (await validation.$validate()) {
                validation.$reset();
            }
        });
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            v$,
            localState,
            isChanged,
            showSingularLabel,
            reset,
        };
    },
    data() {
        return {
            columnTypes: _.map(ColumnTypes, (item) => ({
                value: item,
                label: item,
            })),
        };
    },
    computed: {
        showProperties() {
            return this.localState.type === ColumnTypes['array of objects'];
        },
        isNew() {
            return !this.localState.id;
        },
        isAllowToEdit() {
            return (!this.localState.id || this.isSingleItem);
        },
    },
    watch: {
        localState: {
            handler() {
                this.update();
            },
            deep: true,
        },
    },
    validations() {
        return {
            localState: {
                label: {
                    required,
                    notDot: forbiddenChar(/\./, this.$t('contactBooks.schemaEditor.labelDotNotAllowed')),
                    minLengthValue: minLength(3),
                },
                machine_name: {
                    machineName: helpers.withAsync(async (val) => {
                        try {
                            //we should not validate already existing machine name
                            if (this.localState.id) {
                                return true;
                            }
                            if (val === '') {
                                return Promise.reject(this.$t('contactBooks.schemaEditor.required'));
                            }
                            const existedInCurrent = (this.allItems || []).filter(fs => fs.machine_name === val);
                            // this needed if we try to create the same fields(more than one) which are not saved yet
                            // >1 because our value also exist in newFieldSchemas array
                            if (existedInCurrent.length > 1) {
                                return Promise.reject(this.$t('contactBooks.schemaEditor.machineNameAlreadyPersisted'));
                            }
                            //then we should check if this machine name exist in any other field schemas
                            // which are not related to this book
                            const res = await ContactsApiV2.fieldSchemaApi.isMachineNameExist(val);
                            // we should throw reject if api response true(to trigger field error)
                            return Promise[res ? 'reject' : 'resolve'](this.$t('contactBooks.schemaEditor.machineNameAlreadyExists'));
                        }
                        catch (e) {
                            // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
                            if (e instanceof TranslatableError)
                                return Promise.reject(this.$t(e.translationKey));
                            if (e instanceof Error)
                                return Promise.reject(e.message);
                            return Promise.reject(e);
                        }
                    }),
                    $lazy: true,
                },
                ...(this.showSingularLabel && {
                    singular_label: {
                        required,
                        notDot: forbiddenChar(/\./, this.$t('contactBooks.schemaEditor.labelDotNotAllowed')),
                        minLengthValue: minLength(3),
                    },
                }),
            },
        };
    },
    methods: {
        onLabelBlur() {
            this.v$.localState.label.$touch();
        },
        onLabelUpdate(label) {
            this.localState.label = label;
            // we don't allow update machine name after it was created
            if (this.isNew) {
                this.localState.machine_name = _.snakeCase(label)
                    .replace(/№/g, '')
                    .replace(/_$/, '');
            }
        },
        onMachineNameUpdate(machineName) {
            this.localState.machine_name = machineName;
        },
        handleApply() {
            this.update();
        },
        update() {
            this.$emit('update', this.localState);
        },
    },
});

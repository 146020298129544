import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrConfirmV3 = _resolveComponent("OrConfirmV3");
    return (_openBlock(), _createBlock(_component_OrConfirmV3, {
        ref: "confirmComponent",
        "title-text": _ctx.$t('contacts.discardChangesConfirm.title'),
        "confirm-button-text": _ctx.$t('contacts.discardChangesConfirm.discard'),
        "cancel-button-text": _ctx.$t('contacts.discardChangesConfirm.cancel'),
        "confirm-type": "d",
        onClose: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close'))),
        onConfirm: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('confirm')))
    }, {
        default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contacts.discardChangesConfirm.body')), 1)
        ]),
        _: 1
    }, 8, ["title-text", "confirm-button-text", "cancel-button-text"]));
}

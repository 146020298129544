import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "header-cell-content relative w-full h-full !overflow-visible typography-caption-semibold flex items-center" };
const _hoisted_2 = { class: "text-content w-full h-full flex flex-row overflow-hidden" };
const _hoisted_3 = { class: "text self-center overflow-hidden text-ellipsis" };
const _hoisted_4 = {
    key: 1,
    class: "text self-center p-sm max-w-full overflow-hidden text-ellipsis"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon = _resolveComponent("or-icon");
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.canBeOrdered)
                ? (_openBlock(), _createBlock(_component_or_button, {
                    key: 0,
                    variant: "text",
                    color: "inherit",
                    class: _normalizeClass(["w-full h-full !p-sm !min-w-0 max-w-full overflow-hidden text-ellipsis !justify-start", _ctx.buttonStyles]),
                    onClick: _ctx.changeOrder
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.value), 1),
                        (_ctx.orderMode)
                            ? (_openBlock(), _createBlock(_component_or_icon, {
                                key: 0,
                                icon: _ctx.icon,
                                color: "inherit",
                                class: "text-outline dark:text-outline-dark"
                            }, null, 8, ["icon"]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["class", "onClick"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.value), 1))
        ]),
        _createElementVNode("div", {
            ref: "resizeBarRef",
            class: _normalizeClass(["resize-bar absolute top-none right-[-2.5px] w-[5px] h-full cursor-col-resize flex flex-row justify-center", { '[&>*]:hover:bg-primary-hover [&>*]:dark:hover:bg-primary-hover-dark': _ctx.canBeResizedNow }])
        }, [
            _createElementVNode("div", {
                class: _normalizeClass(["w-[2px] h-full", { 'bg-primary-hover dark:bg-primary-hover-dark': _ctx.isResizing }])
            }, null, 2)
        ], 2)
    ]));
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_empty_state = _resolveComponent("or-empty-state");
    return (_openBlock(), _createBlock(_component_or_empty_state, {
        "image-src": _ctx.rocketPng,
        heading: _ctx.$t('contactBooks.empty.mainText'),
        text: _ctx.$t('contactBooks.empty.secondaryText'),
        size: "l"
    }, null, 8, ["image-src", "heading", "text"]));
}

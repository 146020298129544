import { OrInput } from '@onereach/ui-components-legacy';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import _ from 'lodash';
import { defineComponent, ref } from 'vue';
import ContactBooksSchemaEditor from '../../ContactBooksSchemaEditor/V2';
export default defineComponent({
    name: 'ContactBooksAddForm',
    components: {
        OrInput,
        ContactBooksSchemaEditor,
    },
    props: {
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        allFieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        initTriggers: {
            type: Array,
            required: true,
        },
        schemaPresets: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['submit'],
    setup() {
        const bookNameTextbox = ref();
        const schemaEditor = ref();
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            v$,
            schemaEditor,
            bookNameTextbox,
        };
    },
    data() {
        return {
            book: {
                name: '',
            },
        };
    },
    computed: {
        name() {
            return this.book.name;
        },
    },
    validations() {
        return {
            name: {
                required,
                minLengthValue: minLength(3),
            },
        };
    },
    methods: {
        onBookNameBlur() {
            this.book.name = _.trim(this.book.name);
            this.v$.name.$touch();
        },
        submit() {
            var _a, _b;
            this.$emit('submit', {
                label: this.book.name,
                fieldSchemas: (_a = this.schemaEditor) === null || _a === void 0 ? void 0 : _a.newFieldSchemas,
                schemaPresets: (_b = this.schemaEditor) === null || _b === void 0 ? void 0 : _b.newSchemaPresets,
            });
        },
        scrollToFirstInvalidItem() {
            var _a, _b;
            if (this.v$.name.$error) {
                (_a = this.bookNameTextbox) === null || _a === void 0 ? void 0 : _a.$el.scrollIntoView({ behavior: 'smooth' });
            }
            else {
                return (_b = this.schemaEditor) === null || _b === void 0 ? void 0 : _b.scrollToFirstInvalidItem();
            }
        },
    },
});

import { OrButtonV3 as OrButton, useResponsive } from '@onereach/ui-components';
import { computed, defineComponent, ref } from 'vue';
import OrConditionalSidebarModal from '../../OrConditionalSidebarModal.vue';
import { ContactFormV2 } from '../ContactForm';
export default defineComponent({
    name: 'ContactEditModal',
    components: {
        OrConditionalSidebarModal,
        OrButton,
        ContactFormV2,
    },
    props: {
        isSubmitting: {
            type: Boolean,
            required: false,
            default: false,
        },
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        contact: {
            type: Object,
            required: false,
            default: () => { },
        },
    },
    emits: ['update', 'close'],
    expose: ['isChanged', 'openComponent', 'closeComponent'],
    setup() {
        const contactForm = ref();
        const conditionalSidebar = ref();
        const openComponent = () => { var _a; (_a = conditionalSidebar.value) === null || _a === void 0 ? void 0 : _a.openCom(); };
        const closeComponent = () => { var _a; (_a = conditionalSidebar.value) === null || _a === void 0 ? void 0 : _a.closeCom(); };
        const { isMobile } = useResponsive();
        const isChanged = computed(() => { var _a, _b; return (_b = (_a = contactForm === null || contactForm === void 0 ? void 0 : contactForm.value) === null || _a === void 0 ? void 0 : _a.isChanged) !== null && _b !== void 0 ? _b : false; });
        return {
            contactForm,
            isMobile,
            isChanged,
            conditionalSidebar,
            openComponent,
            closeComponent,
        };
    },
    methods: {
        triggerSubmit() {
            var _a;
            (_a = this.contactForm) === null || _a === void 0 ? void 0 : _a.submit();
        },
    },
});

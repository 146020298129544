import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { MigrationsStatus } from '@/constants';
import { useContactBooksV1, useAuth } from '@/stores';
import isContactsV1Enabled from '@/utils/isContactsV1Enabled';
function useContactsInitialDataLegacy() {
    const store = useContactBooksV1();
    const authStore = useAuth();
    const { migrationsStatus } = storeToRefs(store);
    const { authorized } = storeToRefs(authStore);
    watch([migrationsStatus, authorized], async ([status, authorized]) => {
        if (!isContactsV1Enabled() || !authorized)
            return;
        switch (status) {
            case MigrationsStatus.UNDEFINED: {
                await store.checkIfMigrationsNeeded();
                break;
            }
            case MigrationsStatus.IN_PENDING: {
                await store.migrateContactsTables();
                break;
            }
            case MigrationsStatus.RESOLVED: {
                await store.getInitialData();
                break;
            }
        }
    });
}
export default useContactsInitialDataLegacy;

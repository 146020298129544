import { ColumnTypes } from '@onereach/types-contacts-api';
import { ColumnTypesExtended } from '@/types/columnTypes';
function getFilterOperatorOptions(type, $t) {
    switch (type) {
        case ColumnTypes.integer:
        case ColumnTypes.double:
        case ColumnTypes.date:
        case ColumnTypesExtended.dateTz:
            return [
                {
                    value: '=',
                    label: $t('contacts.filterModal.conditions.operators.equal'),
                },
                {
                    value: '<',
                    label: $t('contacts.filterModal.conditions.operators.less'),
                },
                {
                    value: '<=',
                    label: $t('contacts.filterModal.conditions.operators.lessOrEqual'),
                },
                {
                    value: '>',
                    label: $t('contacts.filterModal.conditions.operators.greater'),
                },
                {
                    value: '>=',
                    label: $t('contacts.filterModal.conditions.operators.greaterOrEqual'),
                },
                {
                    value: '!=',
                    label: $t('contacts.filterModal.conditions.operators.notEqual'),
                },
            ];
        case ColumnTypes.string:
        case ColumnTypes.md:
        case ColumnTypes['array of strings']:
            return [
                {
                    value: '=',
                    label: $t('contacts.filterModal.conditions.operators.equal'),
                },
                {
                    value: 'contains',
                    label: $t('contacts.filterModal.conditions.operators.contain'),
                },
            ];
        default:
            return [];
    }
}
export default getFilterOperatorOptions;

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "actions flex flex-row gap-md justify-between md:justify-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_form = _resolveComponent("contact-form");
    const _component_or_button = _resolveComponent("or-button");
    const _component_OrConditionalSidebarModal = _resolveComponent("OrConditionalSidebarModal");
    return (_openBlock(), _createBlock(_component_OrConditionalSidebarModal, {
        ref: "conditionalSidebar",
        "data-test": "add-contact-modal",
        onClose: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('close')))
    }, _createSlots({
        footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_button, {
                    "data-test": "close-add-contact-modal-button",
                    class: "!grow md:!grow-0",
                    variant: "outlined",
                    onClick: _ctx.handleClose
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.cancel')), 1)
                    ]),
                    _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_or_button, {
                    "data-test": "add-contact-button",
                    class: "!grow md:!grow-0",
                    loading: _ctx.isSubmitting,
                    disabled: !_ctx.isChanged,
                    onClick: _ctx.triggerSubmit
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.create')), 1)
                    ]),
                    _: 1
                }, 8, ["loading", "disabled", "onClick"])
            ])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_contact_form, {
                ref: "contactForm",
                "data-test": "add-contact-form",
                "field-schemas": _ctx.fieldSchemas,
                onSubmit: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('add', $event)))
            }, null, 8, ["field-schemas"])
        ]),
        _: 2
    }, [
        (_ctx.isMobile)
            ? {
                name: "header",
                fn: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('contacts.addModal.title')), 1)
                ]),
                key: "0"
            }
            : undefined
    ]), 1536));
}

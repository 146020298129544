import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-86f6c0c6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "filter-item" };
const _hoisted_2 = { class: "label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_radio = _resolveComponent("or-radio");
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_or_radio, {
            value: _ctx.filter.filterId,
            label: _ctx.filter.name,
            "is-disabled": _ctx.isLoading
        }, {
            default: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.filter.name), 1)
            ]),
            _: 1
        }, 8, ["value", "label", "is-disabled"]),
        _createVNode(_component_or_icon_button, {
            "icon-color": "neutral-3",
            icon: "edit",
            size: "small",
            "is-disabled": _ctx.isLoading,
            "data-test": "edit-filter-item",
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('edit')))
        }, null, 8, ["is-disabled"]),
        _createVNode(_component_or_icon_button, {
            "icon-color": "neutral-3",
            icon: "delete",
            size: "small",
            "is-disabled": _ctx.isLoading,
            "data-test": "delete-filter-item",
            onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('delete')))
        }, null, 8, ["is-disabled"])
    ]));
}

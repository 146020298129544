import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "text-center typography-body-2-regular text-on-background" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_books_list_header = _resolveComponent("contact-books-list-header");
    const _component_contact_books_grid_item = _resolveComponent("contact-books-grid-item");
    const _component_contact_books_list_item = _resolveComponent("contact-books-list-item");
    const _component_OrCardCollectionV3 = _resolveComponent("OrCardCollectionV3");
    return (_openBlock(), _createBlock(_component_OrCardCollectionV3, {
        class: "books-collection overflow-hidden p-xs",
        items: _ctx.items,
        view: _ctx.view,
        sort: _ctx.sort,
        "sort-options": _ctx.sortOptions,
        "search-placeholder": _ctx.$t('common.search'),
        onViewChange: _cache[8] || (_cache[8] = ($event) => (_ctx.$emit('changeView', $event))),
        onSortChange: _ctx.onSortChange,
        onSearchChange: _cache[9] || (_cache[9] = ($event) => (_ctx.$emit('search', $event)))
    }, {
        header: _withCtx(({ mode }) => [
            (mode === 'list')
                ? (_openBlock(), _createBlock(_component_contact_books_list_header, {
                    key: 0,
                    class: "sticky top-none z-[1]"
                }))
                : _createCommentVNode("", true)
        ]),
        item: _withCtx(({ item, mode }) => [
            (mode === 'grid')
                ? (_openBlock(), _createBlock(_component_contact_books_grid_item, {
                    key: item.id,
                    item: item,
                    "is-readonly": !!_ctx.isReadonly,
                    onDelete: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('delete', $event))),
                    onDeleteWithContacts: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('delete', $event, true))),
                    onOpen: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('open', $event))),
                    onNeedConfirmation: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('needConfirmation', $event)))
                }, null, 8, ["item", "is-readonly"]))
                : (mode === 'list')
                    ? (_openBlock(), _createBlock(_component_contact_books_list_item, {
                        key: `${item.id}-in-list`,
                        item: item,
                        "is-readonly": !!_ctx.isReadonly,
                        onDelete: _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('delete', $event))),
                        onDeleteWithContacts: _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('delete', $event, true))),
                        onOpen: _cache[6] || (_cache[6] = ($event) => (_ctx.$emit('open', $event))),
                        onNeedConfirmation: _cache[7] || (_cache[7] = ($event) => (_ctx.$emit('needConfirmation', $event)))
                    }, null, 8, ["item", "is-readonly"]))
                    : _createCommentVNode("", true)
        ]),
        noSearchResults: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('contactBooks.noMatchingRecords')), 1)
        ]),
        _: 1
    }, 8, ["items", "view", "sort", "sort-options", "search-placeholder", "onSortChange"]));
}

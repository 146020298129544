import { ColumnTypes } from '@onereach/types-contacts-api';
import _ from 'lodash';
import getDefaultContactsTableColumnStoredValue from './getDefaultContactsTableColumnStoredValueV2';
function getDefaultContactsTableStoredValue(fieldSchemas, includeContactFields = true) {
    var _a, _b;
    const extendedFieldSchemas = [
        ...(includeContactFields ? [{
                id: 'id',
                label: 'Id',
                type: ColumnTypes.string,
            }] : []),
        ...fieldSchemas,
        ...(includeContactFields ? [
            {
                id: 'created_at',
                label: 'Created at',
                type: ColumnTypes.date,
            },
            {
                id: 'updated_at',
                label: 'Updated at',
                type: ColumnTypes.date,
            },
        ] : []),
    ];
    const columns = _.mapValues(_.keyBy(extendedFieldSchemas, ({ id }) => id), getDefaultContactsTableColumnStoredValue);
    const order = {
        column: includeContactFields ? 'created_at' : ((_b = (_a = fieldSchemas[0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : ''),
        mode: 'desc',
    };
    const paginationLimit = 10;
    return {
        columns,
        order,
        paginationLimit,
    };
}
export default getDefaultContactsTableStoredValue;

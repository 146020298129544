import { SharedBookStatus, } from '@onereach/types-contacts-api';
import { OrAvatarV3 as OrAvatar, OrIconV3 as OrIcon, OrIconButtonV3 as OrIconButton, OrTagV3 as OrTag, OrTextV3 as OrText, getAbbreviation, } from '@onereach/ui-components';
import { useDateFormat } from '@vueuse/core';
import { defineComponent, ref } from 'vue';
import { getDeletedFromText } from '@/components/Contacts/ContactsShare/const';
export default defineComponent({
    name: 'ContactsShareModalV2',
    components: {
        OrIconButton,
        OrAvatar,
        OrIcon,
        OrTag,
        OrText,
    },
    props: {
        meta: {
            type: Object,
            required: false,
            default: () => { },
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        showDeletedTag: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const time = useDateFormat(props.meta.updated_at, 'DD MMM YYYY');
        return {
            time,
            expanded: ref(false),
            getAbbreviation,
        };
    },
    computed: {
        isShared() {
            var _a;
            return ((_a = this.meta) === null || _a === void 0 ? void 0 : _a.status) === SharedBookStatus.shared;
        },
        getDeletedFromText() {
            return getDeletedFromText(this.$t, this.meta);
        },
    },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = { class: "identifiers flex flex-col" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (identifier, index) => {
            return (_openBlock(), _createElementBlock("span", {
                key: index,
                class: "identifier-value overflow-hidden text-ellipsis"
            }, _toDisplayString(identifier.val), 1));
        }), 128))
    ]));
}

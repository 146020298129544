import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "contact-form-field overflow-hidden text-ellipsis" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = {
    key: 2,
    class: "contact-form-field-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_expansion_panel = _resolveComponent("or-expansion-panel");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.isComponentImplemented)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.fieldSchema.type), 1))
            : (_ctx.showExpansionPanel)
                ? (_openBlock(), _createBlock(_component_or_expansion_panel, {
                    key: 1,
                    class: "pt-md",
                    label: _ctx.fieldSchema.label,
                    "is-open": ""
                }, {
                    default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
                            class: "pt-md",
                            "field-schema": _ctx.oldFieldSchema,
                            data: _ctx.data,
                            "onUpdate:data": _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('update', $event)))
                        }, null, 40, ["field-schema", "data"]))
                    ]),
                    _: 1
                }, 8, ["label"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
                        "field-schema": _ctx.oldFieldSchema,
                        data: _ctx.data,
                        "onUpdate:data": _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('update', $event)))
                    }, null, 40, ["field-schema", "data"]))
                ]))
    ]));
}

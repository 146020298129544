import { Contacts } from '@or-sdk/contacts';
import _ from 'lodash';
import { useAuth } from '@/stores';
import config from '../config';
const instance = new Contacts({
    contactsApiUrl: `${config.CONTACTS_API}`,
    token: () => {
        return `USER ${_.get(useAuth(), 'reactiveToken')}`;
    },
    withKeepAliveAgents: true,
});
export default instance;

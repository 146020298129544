import { OrModalV3 } from '@onereach/ui-components';
import { OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';
import { defineComponent, ref } from 'vue';
import ContactForm from '../ContactForm';
export default defineComponent({
    name: 'ContactAddModal',
    components: {
        OrModalV3,
        OrButton,
        ContactForm,
    },
    props: {
        isSubmitting: {
            type: Boolean,
            required: false,
            default: false,
        },
        schema: {
            type: Object,
            required: false,
            default: () => null,
        },
    },
    emits: ['add', 'close'],
    expose: ['modalComponent'],
    setup() {
        const contactForm = ref();
        const modalComponent = ref();
        return {
            contactForm,
            modalComponent,
        };
    },
    computed: {
        isChanged() {
            var _a, _b;
            return (_b = (_a = this.contactForm) === null || _a === void 0 ? void 0 : _a.isChanged) !== null && _b !== void 0 ? _b : false;
        },
    },
    methods: {
        triggerSubmit() {
            var _a;
            (_a = this.contactForm) === null || _a === void 0 ? void 0 : _a.submit();
        },
    },
});

import { helpers } from '@vuelidate/validators';
import { unref, computed } from 'vue';
function alreadyExists(other, message) {
    const otherArray = computed(() => unref(other));
    return helpers.withParams({
        type: 'alreadyExists',
        other,
    }, helpers.withMessage(message, (val) => !helpers.req(val) || !otherArray.value.includes(val)));
}
export default alreadyExists;

import _ from 'lodash';
import getAllCompositeColumns from './getAllCompositeColumns';
function convertSchemaToOrderedArray(schema, withCompositeColumns = false) {
    const schemaItemsWithKey = _.map(schema, (item, key) => ({
        ...item,
        key: key,
    }));
    const itemsWithKey = withCompositeColumns ?
        createArrayWithCompositeColumns() :
        schemaItemsWithKey;
    return _.map(_.orderBy(itemsWithKey, 'order'), (item, index) => ({
        ...item,
        isChanged: false,
        order: index,
    }));
    function createArrayWithCompositeColumns() {
        const compositeColumns = getAllCompositeColumns();
        const columnsToHide = compositeColumns.flatMap(({ parts }) => parts);
        const realColumns = schemaItemsWithKey.filter(item => !columnsToHide.includes(item.key));
        const virtualColumns = compositeColumns.map(column => ({
            visibility: false,
            label: column.label,
            order: _.min(column.parts.map(key => {
                var _a, _b;
                return (_b = (_a = schemaItemsWithKey
                    .find(item => item.key === key)) === null || _a === void 0 ? void 0 : _a.order) !== null && _b !== void 0 ? _b : schemaItemsWithKey.length;
            })),
            key: column.key,
        }));
        return _.concat(realColumns, virtualColumns);
    }
}
export default convertSchemaToOrderedArray;

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    const _component_or_overflow_menu = _resolveComponent("or-overflow-menu");
    return (_openBlock(), _createBlock(_component_or_overflow_menu, {
        class: "contact-books-overflow-menu",
        items: _ctx.menuItems
    }, {
        trigger: _withCtx(() => [
            _createVNode(_component_or_icon_button, {
                icon: "more_vert",
                "data-test": "additional-overflow-menu",
                "icon-color": "neutral-6"
            })
        ]),
        _: 1
    }, 8, ["items"]));
}

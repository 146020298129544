import { OrIconButtonV3 as OrIconButton, OrMenuV3 as OrMenu, OrMenuItemV3 as OrMenuItem, } from '@onereach/ui-components';
import { storeToRefs } from 'pinia';
import { defineComponent, ref } from 'vue';
import { useAuth } from '@/stores';
export default defineComponent({
    name: 'ContactBookOverflowMenuAdditional',
    components: {
        OrIconButton,
        OrMenu,
        OrMenuItem,
    },
    emits: ['delete', 'open:editSchema', 'export'],
    setup() {
        const button = ref();
        const menu = ref();
        const authStore = useAuth();
        const { isReadonly } = storeToRefs(authStore);
        return {
            button,
            menu,
            isReadonly,
        };
    },
});

import { SchemaItemTypes } from '@/constants';
function getDefaultNewField(index = 10) {
    return {
        visibility: true,
        label: '',
        key: '',
        order: index,
        isNew: true,
        typeOf: SchemaItemTypes.STRING,
        properties: {},
        triggers: [],
    };
}
export default getDefaultNewField;

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex flex-row items-center overflow-hidden gap-xs" };
const _hoisted_2 = {
    ref: "container",
    class: "relative flex flex-row grow items-center overflow-hidden gap-xs whitespace-nowrap"
};
const _hoisted_3 = { class: "max-w-[192px] max-h-[min(346px,50vh-26px)] p-md flex flex-row flex-wrap items-center overflow-auto gap-xs whitespace-nowrap" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a, _b;
    const _component_or_tag = _resolveComponent("or-tag");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_popover = _resolveComponent("or-popover");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
                return (_openBlock(), _createBlock(_component_or_tag, {
                    key: tag,
                    class: "max-w-full"
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(tag), 1)
                    ]),
                    _: 2
                }, 1024));
            }), 128))
        ], 512),
        ((_a = _ctx.outerItems) === null || _a === void 0 ? void 0 : _a.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_or_button, {
                    ref: "button",
                    class: "!typography-caption-regular text-on-background dark:text-on-background-dark",
                    color: "inherit",
                    variant: "link",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.popover.open()))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.moreText), 1)
                    ]),
                    _: 1
                }, 512),
                _createVNode(_component_or_popover, {
                    ref: "popover",
                    trigger: (_b = _ctx.button) === null || _b === void 0 ? void 0 : _b.root,
                    variant: "popover"
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hiddenTags, (tag) => {
                                return (_openBlock(), _createBlock(_component_or_tag, {
                                    key: tag,
                                    class: "max-w-full"
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(tag), 1)
                                    ]),
                                    _: 2
                                }, 1024));
                            }), 128))
                        ])
                    ]),
                    _: 1
                }, 8, ["trigger"])
            ], 64))
            : _createCommentVNode("", true)
    ]));
}

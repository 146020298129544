import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "break-words typography-body-2-bold" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_i18n_t = _resolveComponent("i18n-t");
    const _component_OrConfirmV3 = _resolveComponent("OrConfirmV3");
    return (_openBlock(), _createBlock(_component_OrConfirmV3, {
        ref: "confirm",
        "data-test": "unlink-contacts-modal",
        "title-text": _ctx.$t('contacts.unlinkModal.title'),
        loading: _ctx.isLoading,
        "auto-close-on-confirm": false,
        "confirm-button-text": _ctx.$t('contacts.unlinkModal.unlink'),
        "deny-button-text": _ctx.$t('common.cancel'),
        onClose: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('decline'))),
        onConfirm: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('accept')))
    }, {
        default: _withCtx(() => [
            _createVNode(_component_i18n_t, {
                keypath: "contacts.unlinkModal.text",
                scope: "global",
                tag: "span",
                plural: _ctx.contactsCount
            }, {
                count: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.contactsCount), 1)
                ]),
                contactBook: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.contactBookName), 1)
                ]),
                _: 1
            }, 8, ["plural"])
        ]),
        _: 1
    }, 8, ["title-text", "loading", "confirm-button-text", "deny-button-text"]));
}

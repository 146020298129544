import { defineComponent } from 'vue';
import _ from 'lodash';
export default defineComponent({
    name: 'ContactsTableDataCellArrayOfObjects',
    props: {
        contact: {
            type: Object,
            required: true,
        },
        columnKey: {
            type: String,
            required: true,
        },
    },
    computed: {
        value() {
            return this.contact[this.columnKey];
        },
        convertedValue() {
            var _a, _b;
            return (_b = (_a = this.value) === null || _a === void 0 ? void 0 : _a.map(objValue => _.join(_.toPairs(objValue).filter(([key]) => key !== 'type')
                .map(([, value]) => value).filter(value => !_.isEmpty(value)), ', '))) !== null && _b !== void 0 ? _b : [];
        },
    },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_textarea = _resolveComponent("or-textarea");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_or_textarea, {
            modelValue: _ctx.localData,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localData) = $event)),
            label: _ctx.fieldSchema.label,
            placeholder: _ctx.$t('contacts.contactsForm.enterFieldValue', { name: _ctx.fieldSchema.label })
        }, null, 8, ["modelValue", "label", "placeholder"])
    ]));
}

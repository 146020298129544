import { OrIconButtonV3 as OrIconButton, OrExpansionPanelV3 as OrExpansionPanel, OrInputV3 as OrInput, } from '@onereach/ui-components';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactBooksSchemaEditorPresetItem',
    components: {
        OrIconButton,
        OrExpansionPanel,
        OrInput,
    },
    props: {
        schemaPreset: {
            type: Object,
            required: true,
        },
    },
    emits: ['delete', 'unlink'],
    computed: {
        fieldSchemasNames() {
            var _a, _b, _c;
            return (_c = (_b = (_a = this.schemaPreset) === null || _a === void 0 ? void 0 : _a.fieldSchemas) === null || _b === void 0 ? void 0 : _b.map(fs => fs.label)) !== null && _c !== void 0 ? _c : [];
        },
    },
});

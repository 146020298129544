export const adjustPaginationOffset = (total, deleteCount, currentOffset, perPage) => {
    const changedTotal = total - deleteCount;
    if (changedTotal <= 0) {
        return 0;
    }
    if (changedTotal === currentOffset) {
        return currentOffset - perPage;
    }
    if (changedTotal < currentOffset) {
        return Math.ceil(changedTotal / perPage) * perPage - perPage;
    }
    return currentOffset;
};

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrIconButtonV3 = _resolveComponent("OrIconButtonV3");
    return (_openBlock(), _createBlock(_component_OrIconButtonV3, {
        icon: "upload",
        tooltip: _ctx.$t('contacts.exportV2'),
        disabled: _ctx.isDisabled,
        size: "l",
        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('click')))
    }, null, 8, ["tooltip", "disabled"]));
}

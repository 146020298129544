import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-f8a9696e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "flex flex-row gap-md md:gap-xs mb-lg" };
const _hoisted_2 = { class: "flex flex-col gap-md" };
const _hoisted_3 = { class: "flex flex-row gap-md mr-auto" };
const _hoisted_4 = { class: "text-container" };
const _hoisted_5 = { class: "account-id" };
const _hoisted_6 = { class: "users-count" };
const _hoisted_7 = { class: "chip-container" };
const _hoisted_8 = { class: "button-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_input = _resolveComponent("or-input");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_avatar = _resolveComponent("or-avatar");
    const _component_or_chip = _resolveComponent("or-chip");
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    const _component_OrModalV3 = _resolveComponent("OrModalV3");
    return (_openBlock(), _createBlock(_component_OrModalV3, {
        ref: "modalComponent",
        class: "min-h-[400px]",
        size: "m"
    }, {
        header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contacts.shareModal.title')), 1)
        ]),
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_input, {
                    modelValue: _ctx.accountToInvite,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.accountToInvite) = $event)),
                    "is-disabled": _ctx.isLoading,
                    label: _ctx.$t('contacts.shareModal.accountId'),
                    placeholder: _ctx.$t('contacts.shareModal.enterId'),
                    error: _ctx.v$.accountToInvite.alreadyExists.$message,
                    "is-invalid": _ctx.v$.accountToInvite.alreadyExists.$invalid && !_ctx.isLoading,
                    onBlur: _ctx.trimAccountId
                }, null, 8, ["modelValue", "is-disabled", "label", "placeholder", "error", "is-invalid", "onBlur"]),
                _createVNode(_component_or_button, {
                    class: "whitespace-nowrap w-full self-baseline md:w-auto md:mt-[22px]",
                    "is-disabled": _ctx.isInviteDisabled || _ctx.v$.$invalid,
                    "is-loading": _ctx.isLoading,
                    content: _ctx.$t('contacts.shareModal.sendInvite'),
                    onClick: _ctx.sendInvitation
                }, null, 8, ["is-disabled", "is-loading", "content", "onClick"])
            ]),
            _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invitations, (invitation) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: invitation.accountId,
                        class: _normalizeClass(["flex flex-row gap-md w-full", { 'is-waiting': _ctx.getIsWaiting(invitation.state) }])
                    }, [
                        _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_or_avatar, {
                                src: invitation.imageUrl,
                                size: "medium",
                                icon: _ctx.getIsWaiting(invitation.state) ? '' : 'account_circle'
                            }, null, 8, ["src", "icon"]),
                            _createElementVNode("div", _hoisted_4, [
                                _createElementVNode("div", _hoisted_5, _toDisplayString(invitation.accountId), 1),
                                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getUsersText(invitation.usersCount, invitation.state)), 1)
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_or_chip, {
                                content: _ctx.getStateText(invitation.state),
                                "can-delete": false,
                                "is-disabled": _ctx.getIsWaiting(invitation.state)
                            }, null, 8, ["content", "is-disabled"])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_or_icon_button, {
                                icon: "delete",
                                "icon-color": "neutral-4",
                                "is-disabled": _ctx.isLoading,
                                onClick: ($event) => (_ctx.deleteInvitation(invitation.accountId))
                            }, null, 8, ["is-disabled", "onClick"])
                        ])
                    ], 2));
                }), 128))
            ])
        ]),
        _: 1
    }, 512));
}

import { OrConfirmV3 } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'ContactsDiscardChangesConfirm',
    components: {
        OrConfirmV3,
    },
    emits: ['confirm', 'close'],
    expose: ['confirmComponent'],
    setup() {
        const confirmComponent = ref();
        return {
            confirmComponent,
        };
    },
});

import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactViewFieldDate',
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: [Date, String],
            required: false,
            default: null,
        },
    },
    computed: {
        date() {
            if (!this.data)
                return null;
            return new Date(this.data);
        },
        text() {
            var _a, _b;
            return (_b = (_a = this.date) === null || _a === void 0 ? void 0 : _a.toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hourCycle: 'h12',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'UTC',
            })) !== null && _b !== void 0 ? _b : '';
        },
    },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "contact-view-field-identifier-object flex flex-col gap-sm pb-md pt-sm md:pt-sm+" };
const _hoisted_2 = { class: "text text-ellipsis overflow-hidden" };
const _hoisted_3 = { class: "chips-container max-w-full flex flex-row flex-wrap gap-xs" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_tag = _resolveComponent("or-tag");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.val), 1),
        _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.type, (type) => {
                return (_openBlock(), _createBlock(_component_or_tag, { key: type }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(type), 1)
                    ]),
                    _: 2
                }, 1024));
            }), 128))
        ])
    ]));
}

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex gap-md self-baseline" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrTag = _resolveComponent("OrTag");
    const _component_OrButton = _resolveComponent("OrButton");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.getDeletedFromText && _ctx.showDeletedTag)
            ? (_openBlock(), _createBlock(_component_OrTag, { key: 0 }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getDeletedFromText), 1)
                ]),
                _: 1
            }))
            : _createCommentVNode("", true),
        _createVNode(_component_OrButton, {
            loading: _ctx.isLoading,
            variant: "link",
            onClick: _ctx.restoreSharingMeta
        }, {
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('contacts.shareModal.recover')), 1)
            ]),
            _: 1
        }, 8, ["loading", "onClick"])
    ]));
}

import _ from 'lodash';
function convertOrderedArrayToSchema(schemaItems) {
    return _.reduce(schemaItems, (acc, item, index) => {
        acc = {
            ...acc,
            [item.key || `new_${index}`]: {
                ..._.omit(item, ['originalIndex', 'key', 'isNew', 'isChanged']),
                order: index,
            },
        };
        return acc;
    }, {});
}
export default convertOrderedArrayToSchema;

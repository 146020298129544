import { defineComponent } from 'vue';
import { OrIconButton, OrOverflowMenu } from '@onereach/ui-components-legacy';
export default defineComponent({
    name: 'ContactBookOverflowMenuAdditional',
    components: {
        OrIconButton,
        OrOverflowMenu,
    },
    emits: ['update', 'delete', 'open:editSchema', 'open:accessSettings', 'open:share'],
    data() {
        return {
            menuItems: [
                {
                    icon: 'create',
                    label: this.$t('contactBooks.controls.editSchema'),
                    handler: () => this.$emit('open:editSchema'),
                },
                {
                    icon: 'account_box',
                    label: this.$t('contactBooks.controls.accessSettings'),
                    handler: () => this.$emit('open:accessSettings'),
                },
                {
                    icon: 'share',
                    label: this.$t('contactBooks.controls.share'),
                    handler: () => this.$emit('open:share'),
                },
                {
                    icon: 'delete',
                    label: this.$t('common.delete'),
                    handler: () => this.$emit('delete'),
                },
            ],
        };
    },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-row-reverse py-md+ px-xl mt-auto bg-background dark:bg-background-dark border-t-1 border-outline-variant dark:border-outline-variant-dark" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrPagination = _resolveComponent("OrPagination");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_OrPagination, {
            modelValue: _ctx.localPagination,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localPagination) = $event)),
            length: _ctx.localLength
        }, null, 8, ["modelValue", "length"])
    ]));
}

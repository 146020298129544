import { OrModalV3, SidebarPlacement, OrSidebarV3, OrTeleportV3, useResponsive, } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'OrConditionalSidebarModal',
    components: {
        OrModalV3,
        OrSidebarV3,
        OrTeleportV3,
    },
    props: {
        width: {
            type: Number,
            default: 400,
        },
        draggable: {
            type: Boolean,
            default: false,
        },
        collapsible: {
            type: Boolean,
            default: false,
        },
        side: {
            type: String,
            default: SidebarPlacement.Right,
        },
        isAbsolutePositioned: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['open', 'close'],
    expose: ['rootRef', 'openCom', 'closeCom'],
    setup() {
        let sidebarOpen = ref(false);
        const { isMobile } = useResponsive();
        const rootRef = ref();
        function openCom() {
            var _a;
            if (!isMobile.value) {
                sidebarOpen.value = true;
            }
            else {
                (_a = rootRef.value) === null || _a === void 0 ? void 0 : _a.open();
            }
        }
        function closeCom() {
            var _a;
            if (!isMobile.value) {
                sidebarOpen.value = false;
            }
            else {
                (_a = rootRef.value) === null || _a === void 0 ? void 0 : _a.close();
            }
        }
        return {
            isMobile,
            rootRef,
            sidebarOpen,
            openCom,
            closeCom,
        };
    },
    computed: {
        sidebarStyles() {
            const xPlacement = this.side === SidebarPlacement.Right ? 'right-none' : 'left-none';
            return this.isAbsolutePositioned
                ? `!absolute inset-y-none ${xPlacement} z-[5] shadow-modal dark:shadow-modal-dark`
                : '!relative';
        },
    },
});

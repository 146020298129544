import { defineStore } from 'pinia';
import ContactsApi from '@/api/ContactsApiV2';
const useContactsStore = defineStore('contactsV2', {
    state: () => ({
        all: {
            items: [],
            total: 0,
        },
        collections: {},
        contacts: {},
    }),
    getters: {
        getContactsByBookId: (state) => {
            return (id) => {
                return state.collections[id]
                    ? state.collections[id]
                    : {};
            };
        },
        getAllContacts: (state) => {
            return () => {
                return state.all;
            };
        },
        getContactById: (state) => (contactId) => state.contacts[contactId],
    },
    actions: {
        async getContacts(payload) {
            const collection = await ContactsApi.contactApi.listContact(payload);
            collection.items.forEach(contact => this.contacts[contact.id] = contact);
            if (payload.contact_book) {
                this.collections[payload.contact_book] = collection;
            }
            else {
                this.all = collection;
            }
        },
        _updateContact(contact, contactBook) {
            this.contacts[contact.id] = contact;
            if (contactBook) {
                const collection = this.collections[contactBook];
                if (collection) {
                    const index = collection.items.findIndex(ct => ct.id === contact.id);
                    if (index >= 0) {
                        collection.items[index] = contact;
                    }
                    else {
                        collection.items.push(contact);
                    }
                }
            }
        },
        async updateContact(id, data) {
            const contact = await ContactsApi.contactApi.updateContact(id, data);
            this._updateContact(contact, data.contact_book);
        },
        async createContact(data) {
            const contact = await ContactsApi.contactApi.createContact(data);
            this._updateContact(contact, data.contact_book);
        },
        async bulkCreateContacts(data) {
            return ContactsApi.bulkCreateApi.bulkCreateContacts(`ui_bulk_${Date.now()}`, data);
        },
        async deleteContacts(contactIds, bookId) {
            await ContactsApi.contactApi.deleteMulti({
                contact_book: bookId,
                ids: contactIds,
            });
            contactIds.forEach(id => delete this.contacts[id]);
            if (bookId && this.collections[bookId]) {
                this.collections[bookId].items = this.collections[bookId].items
                    .filter(ct => !contactIds.includes(ct.id));
                this.collections[bookId].total -= contactIds.length;
            }
        },
        async deleteAllContacts(contactBookId) {
            await ContactsApi.contactApi.deleteMulti({
                contact_book: contactBookId,
                all: true,
            });
            this.collections[contactBookId].items = [];
            this.collections[contactBookId].total = 0;
        },
        async mergeContacts(toId, fromId, bookId) {
            await ContactsApi.contactApi.mergeContacts(toId, {
                contact_book: bookId,
                mergeContactId: fromId,
            });
        },
        async linkContact(id, data) {
            await ContactsApi.contactApi.linkContact(id, data);
        },
        async linkContactsMulti(data) {
            await ContactsApi.contactApi.linkContactsMulti(data);
        },
        async unlinkContact(id, data) {
            await ContactsApi.contactApi.unlinkContact(id, data);
        },
        async unlinkContactsMulti(data) {
            await ContactsApi.contactApi.unlinkContactsMulti(data);
        },
    },
});
export default useContactsStore;

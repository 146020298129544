import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-dc657888"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "filter-button-content",
    "data-test": "filter-button-content"
};
const _hoisted_2 = {
    class: "filter-menu",
    "data-test": "filter-menu"
};
const _hoisted_3 = { class: "title" };
const _hoisted_4 = { class: "menu-content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    const _component_or_button = _resolveComponent("or-button");
    const _component_contacts_filter_menu_item = _resolveComponent("contacts-filter-menu-item");
    const _component_or_radio_group = _resolveComponent("or-radio-group");
    const _component_or_overflow_menu = _resolveComponent("or-overflow-menu");
    return (_openBlock(), _createBlock(_component_or_overflow_menu, { placement: "bottom-start" }, {
        trigger: _withCtx(() => [
            _createVNode(_component_or_button, {
                "data-test": "filter-contacts-button",
                type: "text",
                color: "neutral-4",
                icon: "filter_list",
                "icon-position": "left",
                class: _normalizeClass({ 'with-filter': !!_ctx.modelValue })
            }, _createSlots({
                default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.buttonLabel), 1)
                ]),
                _: 2
            }, [
                (!!_ctx.modelValue)
                    ? {
                        name: "append",
                        fn: _withCtx(() => [
                            _createVNode(_component_or_icon_button, {
                                icon: "close",
                                size: "small",
                                "icon-color": "neutral-6",
                                onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.radioGroupModel = null), ["stop"]))
                            })
                        ]),
                        key: "0"
                    }
                    : undefined
            ]), 1032, ["class"])
        ]),
        content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('contacts.filters')), 1),
                _createElementVNode("div", _hoisted_4, [
                    (_ctx.filters && _ctx.filters.length > 0)
                        ? (_openBlock(), _createBlock(_component_or_radio_group, {
                            key: 0,
                            modelValue: _ctx.radioGroupModel,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.radioGroupModel) = $event)),
                            "is-uncheckable": ""
                        }, {
                            default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter) => {
                                    return (_openBlock(), _createBlock(_component_contacts_filter_menu_item, {
                                        key: filter.filterId,
                                        filter: filter,
                                        "is-loading": _ctx.isLoading,
                                        onEdit: ($event) => (_ctx.$emit('edit', filter.filterId)),
                                        onDelete: ($event) => (_ctx.$emit('delete', filter.filterId))
                                    }, null, 8, ["filter", "is-loading", "onEdit", "onDelete"]));
                                }), 128))
                            ]),
                            _: 1
                        }, 8, ["modelValue"]))
                        : _createCommentVNode("", true),
                    _createVNode(_component_or_button, {
                        "data-test": "create-filter-button",
                        type: "text",
                        color: "primary",
                        class: "create-button",
                        "is-disabled": _ctx.isLoading,
                        icon: "add",
                        "icon-position": "left",
                        content: _ctx.$t('common.createNew'),
                        onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('create')))
                    }, null, 8, ["is-disabled", "content"])
                ])
            ])
        ]),
        _: 1
    }));
}

function getTriggerIcons($t) {
    return [
        {
            icon: 'sms',
            text: $t('contactBooks.schemaEditor.triggerTypes.sms'),
        },
        {
            icon: 'phone',
            text: $t('contactBooks.schemaEditor.triggerTypes.call'),
        },
    ];
}
export default getTriggerIcons;

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-d8c5a0a6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "grouped-list-item",
    "data-test": "schema-editor-grouped-list-item"
};
const _hoisted_2 = {
    key: 0,
    class: "button-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_collapse = _resolveComponent("or-collapse");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_or_button, {
            type: "text",
            class: "toggle",
            "data-test": "schema-editor-expand-group-button",
            color: _ctx.v$.$error ? 'error' : 'neutral-6',
            content: _ctx.groupName,
            icon: _ctx.isOpened ? 'expand_more' : 'chevron_right',
            "icon-position": "left",
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.isOpenedModel = !_ctx.isOpenedModel))
        }, null, 8, ["color", "content", "icon"]),
        _createVNode(_component_or_collapse, {
            "keep-state": true,
            "is-visible": _ctx.isOpened
        }, {
            default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                    return _renderSlot(_ctx.$slots, "item", {
                        element: item,
                        index: item.originalIndex
                    }, undefined, true);
                }), 256)),
                (_ctx.withAddButton)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_or_button, {
                            "data-test": "schema-editor-add-button",
                            type: "text",
                            content: _ctx.$t('contactBooks.schemaEditor.addColumn'),
                            icon: "add",
                            "icon-position": "left",
                            onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('addColumn')))
                        }, null, 8, ["content"])
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _: 3
        }, 8, ["is-visible"])
    ]));
}

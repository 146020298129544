import { OrMenuItemV3 as OrMenuItem, OrMenuV3 as OrMenu, OrFabV3 as OrFab, } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: 'ContactBooksAddButton',
    components: {
        OrMenuItem,
        OrMenu,
        OrFab,
    },
    emits: ['create', 'import'],
    setup() {
        const triggerRef = ref();
        const menuRef = ref();
        return {
            triggerRef,
            menuRef,
        };
    },
});

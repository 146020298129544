import { OrEmptyStateV3 as OrEmptyState, } from '@onereach/ui-components';
import { defineComponent } from 'vue';
import rocketPng from '@/assets/rocket.png';
export default defineComponent({
    name: 'ContactBooksEmpty',
    components: {
        OrEmptyState,
    },
    setup() {
        return {
            rocketPng,
        };
    },
});

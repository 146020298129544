import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsTableDataCellArrayOfIdentifiers',
    props: {
        value: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
});

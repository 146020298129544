import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "layout-row h-full w-full overflow-hidden" };
const _hoisted_2 = { class: "layout-column h-full grow p-xl overflow-hidden" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SettingsMenu = _resolveComponent("SettingsMenu");
    const _component_OrSidebarV3 = _resolveComponent("OrSidebarV3");
    const _component_router_view = _resolveComponent("router-view");
    const _component_SettingsContainer = _resolveComponent("SettingsContainer");
    return (_openBlock(), _createBlock(_component_SettingsContainer, null, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_OrSidebarV3, {
                    collapsible: "",
                    resizeable: "",
                    width: 255,
                    placement: "left"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_SettingsMenu)
                    ]),
                    _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_router_view)
                ])
            ])
        ]),
        _: 1
    }));
}

import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_OrIconButtonV3 = _resolveComponent("OrIconButtonV3");
    const _component_OrMenuItemV3 = _resolveComponent("OrMenuItemV3");
    const _component_OrMenuV3 = _resolveComponent("OrMenuV3");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_OrIconButtonV3, {
            ref: "menuTrigger",
            icon: "more_vert",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => { var _a; return ((_a = _ctx.menu) === null || _a === void 0 ? void 0 : _a.toggle()); }, ["stop", "prevent"]))
        }, null, 512),
        _createVNode(_component_OrMenuV3, {
            ref: "menu",
            trigger: (_a = _ctx.menuTrigger) === null || _a === void 0 ? void 0 : _a.root
        }, {
            default: _withCtx(() => [
                (_ctx.shareStatus !== 'need confirmation')
                    ? (_openBlock(), _createBlock(_component_OrMenuItemV3, {
                        key: 0,
                        icon: "open_in_new",
                        onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('open')))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('contactBooks.controls.openInNewTab')), 1)
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true),
                (_ctx.canDeleteWithContacts)
                    ? (_openBlock(), _createBlock(_component_OrMenuItemV3, {
                        key: 1,
                        icon: "delete",
                        disabled: Boolean(_ctx.isReadonly),
                        onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('deleteWithContacts')))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('contactBooks.controls.deleteWithContacts')), 1)
                        ]),
                        _: 1
                    }, 8, ["disabled"]))
                    : _createCommentVNode("", true),
                _createVNode(_component_OrMenuItemV3, {
                    icon: "delete",
                    disabled: Boolean(_ctx.isReadonly),
                    onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('delete')))
                }, {
                    default: _withCtx(() => [
                        (_ctx.shareStatus === 'not shared')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('common.delete')), 1)
                            ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('contactBooks.controls.deleteSharing')), 1)
                            ], 64))
                    ]),
                    _: 1
                }, 8, ["disabled"])
            ]),
            _: 1
        }, 8, ["trigger"])
    ]));
}

import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { MigrationsStatus } from '@/constants';
import { useMigrations, useSchemaPresetsStore, useFieldSchemas, useAuth } from '@/stores';
function useContactsInitialData() {
    const migrationsStore = useMigrations();
    const schemaPresetsStore = useSchemaPresetsStore();
    const fieldSchemasStore = useFieldSchemas();
    const authStore = useAuth();
    const { migrationsStatus } = storeToRefs(migrationsStore);
    const { authorized } = storeToRefs(authStore);
    watch([migrationsStatus, authorized], async ([status, authorized]) => {
        if (authorized) {
            if (status === MigrationsStatus.UNDEFINED) {
                return migrationsStore.getMigrationStatus();
            }
            if (status !== MigrationsStatus.NOT_STARTED) {
                return Promise.all([
                    schemaPresetsStore.getSchemaPresets(),
                    fieldSchemasStore.getFieldSchemas(),
                ]);
            }
        }
    }, { immediate: true });
}
export default useContactsInitialData;

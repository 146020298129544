import { defineComponent } from 'vue';
import { getDefaultIdentifier } from '@/utils';
export default defineComponent({
    name: 'ContactsTableDataCellIdentifierObject',
    props: {
        value: {
            type: Object,
            required: false,
            default: () => getDefaultIdentifier(),
        },
    },
});

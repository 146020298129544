import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-1ff35aa8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "schema-item-container",
    "data-test": "schema-editor-item"
};
const _hoisted_2 = { class: "header" };
const _hoisted_3 = { class: "actions" };
const _hoisted_4 = { class: "row table-props" };
const _hoisted_5 = { class: "row" };
const _hoisted_6 = {
    key: 0,
    class: "row"
};
const _hoisted_7 = {
    key: 1,
    class: "row"
};
const _hoisted_8 = {
    key: 2,
    class: "row actions"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    const _component_or_input = _resolveComponent("or-input");
    const _component_or_select = _resolveComponent("or-select");
    const _component_contact_books_schema_editor_item_properties = _resolveComponent("contact-books-schema-editor-item-properties");
    const _component_contact_books_schema_editor_item_triggers = _resolveComponent("contact-books-schema-editor-item-triggers");
    const _component_or_collapse = _resolveComponent("or-collapse");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_or_button, {
                "data-test": "schema-editor-item-expand-button",
                type: "text",
                icon: _ctx.isOpen ? 'expand_more' : 'chevron_right',
                "icon-position": "left",
                class: "toggle",
                color: _ctx.v$.$error ? 'error' : 'neutral-6',
                content: _ctx.item.key ? _ctx.item.key : _ctx.$t('contactBooks.schemaEditor.newColumn'),
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.toggleOpen()))
            }, null, 8, ["icon", "color", "content"]),
            _createElementVNode("div", _hoisted_3, [
                (!_ctx.localState.isNew && _ctx.isChanged)
                    ? (_openBlock(), _createBlock(_component_or_icon_button, {
                        key: 0,
                        "data-test": "schema-editor-item-restore-button",
                        icon: "restore",
                        "is-disabled": !_ctx.isChanged,
                        onClick: _ctx.reset
                    }, null, 8, ["is-disabled", "onClick"]))
                    : _createCommentVNode("", true),
                _createVNode(_component_or_icon_button, {
                    "data-test": "schema-editor-item-visibility-button",
                    icon: _ctx.localState.visibility ? 'visibility' : 'visibility_off',
                    "icon-color": "neutral-4",
                    onClick: _ctx.handleVisibilityFlip
                }, null, 8, ["icon", "onClick"]),
                (_ctx.isDeletable || _ctx.localState.isNew)
                    ? (_openBlock(), _createBlock(_component_or_icon_button, {
                        key: 1,
                        "data-test": "schema-editor-delete-item-icon",
                        icon: "delete",
                        "icon-color": "neutral-4",
                        onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('delete')))
                    }))
                    : _createCommentVNode("", true),
                (_ctx.isDraggable)
                    ? (_openBlock(), _createBlock(_component_or_icon_button, {
                        key: 2,
                        class: "dragger",
                        icon: "drag_indicator",
                        "icon-color": "neutral-4"
                    }))
                    : _createCommentVNode("", true)
            ])
        ]),
        _createVNode(_component_or_collapse, {
            "is-visible": _ctx.isOpen,
            class: "body"
        }, {
            default: _withCtx(() => {
                var _a, _b, _c;
                return [
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_or_input, {
                            "data-test": "schema-editor-column-name-input",
                            "is-disabled": !_ctx.localState.isNew,
                            label: _ctx.$t('contactBooks.schemaEditor.columnName'),
                            "model-value": _ctx.localState.key,
                            "is-invalid": _ctx.v$.localState.key.$error,
                            error: (_a = _ctx.v$.localState.key.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => (_ctx.localState.key = $event)),
                            onBlur: _ctx.v$.localState.key.$touch
                        }, null, 8, ["is-disabled", "label", "model-value", "is-invalid", "error", "onBlur"]),
                        _createVNode(_component_or_select, {
                            modelValue: _ctx.localState.typeOf,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.localState.typeOf) = $event)),
                            "data-test": "schema-editor-type-select",
                            "is-disabled": !_ctx.localState.isNew,
                            label: _ctx.$t('contactBooks.schemaEditor.columnType'),
                            options: _ctx.schemaItemTypes,
                            onChange: _cache[4] || (_cache[4] = ($event) => (_ctx.localState.typeOf = $event))
                        }, null, 8, ["modelValue", "is-disabled", "label", "options"])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_or_input, {
                            "data-test": "schema-editor-column-label-input",
                            label: _ctx.$t('contactBooks.schemaEditor.columnLabel'),
                            "model-value": _ctx.localState.label,
                            "is-invalid": _ctx.v$.localState.label.$error,
                            error: (_b = _ctx.v$.localState.label.$errors[0]) === null || _b === void 0 ? void 0 : _b.$message,
                            onBlur: _ctx.v$.localState.label.$touch,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => (_ctx.localState.label = $event))
                        }, null, 8, ["label", "model-value", "is-invalid", "error", "onBlur"]),
                        (_ctx.showSingularLabel)
                            ? (_openBlock(), _createBlock(_component_or_input, {
                                key: 0,
                                "data-test": "schema-editor-singular-label-input",
                                label: _ctx.$t('contactBooks.schemaEditor.singularLabel'),
                                "model-value": _ctx.localState.singularLabel,
                                "is-invalid": _ctx.v$.localState.singularLabel.$error,
                                error: (_c = _ctx.v$.localState.singularLabel.$errors[0]) === null || _c === void 0 ? void 0 : _c.$message,
                                onBlur: _ctx.v$.localState.singularLabel.$touch,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => (_ctx.localState.singularLabel = $event))
                            }, null, 8, ["label", "model-value", "is-invalid", "error", "onBlur"]))
                            : _createCommentVNode("", true)
                    ]),
                    (_ctx.showProperties)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_contact_books_schema_editor_item_properties, {
                                properties: _ctx.localState.properties,
                                "is-deletable": _ctx.isDeletable,
                                "undeletable-properties": _ctx.undeletableProperties,
                                onUpdate: _cache[7] || (_cache[7] = ($event) => (_ctx.localState.properties = $event))
                            }, null, 8, ["properties", "is-deletable", "undeletable-properties"])
                        ]))
                        : _createCommentVNode("", true),
                    (_ctx.showTriggers)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_contact_books_schema_editor_item_triggers, {
                                triggers: _ctx.localState.triggers,
                                "init-triggers": _ctx.initTriggers,
                                onUpdate: _cache[8] || (_cache[8] = ($event) => (_ctx.localState.triggers = $event))
                            }, null, 8, ["triggers", "init-triggers"])
                        ]))
                        : _createCommentVNode("", true),
                    (_ctx.isChanged)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_or_button, {
                                "data-test": "schema-editor-apply-changes-button",
                                class: "apply-changes",
                                "is-disabled": !_ctx.isChanged || _ctx.v$.$invalid,
                                content: _ctx.$t('common.apply'),
                                onClick: _ctx.handleApply
                            }, null, 8, ["is-disabled", "content", "onClick"])
                        ]))
                        : _createCommentVNode("", true)
                ];
            }),
            _: 1
        }, 8, ["is-visible"])
    ]));
}

import { OrCollapse, OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';
import useVuelidate from '@vuelidate/core';
import { useVModel } from '@vueuse/core';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactBooksSchemaEditorItemListGroup',
    components: {
        OrCollapse,
        OrButton,
    },
    props: {
        groupName: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        isOpened: {
            type: Boolean,
            required: false,
            default: false,
        },
        withAddButton: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['update:isOpened', 'addColumn'],
    setup(props, { emit }) {
        const isOpenedModel = useVModel(props, 'isOpened', emit);
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            v$,
            isOpenedModel,
        };
    },
});

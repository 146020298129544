import { OrModalV3 } from '@onereach/ui-components';
import { OrButtonV2 as OrButton, OrRadio } from '@onereach/ui-components-legacy';
import { defineComponent, ref, computed } from 'vue';
import { CompositeColumnKeys, SchemaItemTypes } from '@/constants';
import { getCompositeColumnsMapping, getContactPropertiesToShow, convertSchemaToOrderedArray } from '@/utils';
import { ContactsViewField, ContactsViewModalProfileInfo } from '../ContactsView/ContactsViewField';
const alreadyShownKeys = getCompositeColumnsMapping()[CompositeColumnKeys.NAME].parts;
const unionTypes = [
    SchemaItemTypes.ARRAY_OF_OBJECTS,
    SchemaItemTypes.ARRAY_OF_STRINGS,
    SchemaItemTypes.ARRAY_OF_IDENTIFIERS,
];
export default defineComponent({
    name: 'ContactMergeModal',
    components: {
        OrModalV3,
        OrButton,
        OrRadio,
        ContactsViewModalProfileInfo,
        ContactsViewField,
    },
    props: {
        isMerging: {
            type: Boolean,
            required: false,
            default: false,
        },
        schema: {
            type: Object,
            required: false,
            default: () => null,
        },
        contacts: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['merge', 'close'],
    expose: ['modalComponent'],
    setup(props) {
        let mainContact = ref('');
        const selected = computed(() => {
            var _a;
            return (mainContact.value === '') ? (_a = props.contacts[0]) === null || _a === void 0 ? void 0 : _a.contactId : mainContact.value;
        });
        return {
            modalComponent: ref(),
            unionTypes,
            mainContact,
            selected,
        };
    },
    computed: {
        selectedId() {
            var _a, _b;
            return (_b = (_a = this.selected) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : this.selected;
        },
        unselectedId() {
            var _a, _b, _c;
            return ((_a = this.contacts[0]) === null || _a === void 0 ? void 0 : _a.contactId) === this.selectedId ?
                (_b = this.contacts[1]) === null || _b === void 0 ? void 0 : _b.contactId :
                (_c = this.contacts[0]) === null || _c === void 0 ? void 0 : _c.contactId;
        },
        orderedSchemaItems() {
            return convertSchemaToOrderedArray(this.schema)
                .filter(({ key }) => !alreadyShownKeys.includes(key) && this.propertiesToShow[key]);
        },
        propertiesToShow() {
            return getContactPropertiesToShow(this.contacts);
        },
    },
    methods: {
        selectContact(contactId) {
            this.mainContact = contactId;
        },
    },
});

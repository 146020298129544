import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "contact-view-field text-ellipsis theme-border-outline-variant dark:theme-border-outline-variant-dark !border-0 !border-b-1 last-of-type:!border-b-0"
};
const _hoisted_2 = {
    class: "contact-view-field-title truncate typography-body-2-bold",
    "data-test": "contact-view-field-title"
};
const _hoisted_3 = { class: "contact-view-field-content typography-body-2-regular" };
const _hoisted_4 = {
    key: 1,
    class: "contact-view-field-title truncate typography-body-2-bold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.alwaysShow || !_ctx.isEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.fieldSchema.label), 1),
            _createElementVNode("div", _hoisted_3, [
                (_ctx.isComponentImplemented)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
                        key: 0,
                        "field-schema": _ctx.oldFieldSchema,
                        data: _ctx.data || undefined
                    }, null, 8, ["field-schema", "data"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.fieldSchema.type), 1))
            ])
        ]))
        : _createCommentVNode("", true);
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "actions flex gap-sm text-outline dark:text-outline-dark" };
const _hoisted_2 = { class: "field-schemas-container flex flex-col gap-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    const _component_or_input = _resolveComponent("or-input");
    const _component_or_expansion_panel = _resolveComponent("or-expansion-panel");
    return (_openBlock(), _createBlock(_component_or_expansion_panel, {
        class: "schema-preset-item-container",
        "data-test": "schema-editor-preset-item",
        label: _ctx.schemaPreset.label,
        variant: "card"
    }, {
        addon: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_icon_button, {
                    "data-test": "schema-editor-preset-unlink-item-icon",
                    tooltip: _ctx.$t('contactBooks.schemaEditor.unlink'),
                    icon: "link_off",
                    color: "inherit",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('unlink')))
                }, null, 8, ["tooltip"]),
                _createVNode(_component_or_icon_button, {
                    "data-test": "schema-editor-preset-delete-item-icon",
                    tooltip: _ctx.$t('contactBooks.schemaEditor.delete'),
                    icon: "delete",
                    color: "inherit",
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('delete')))
                }, null, 8, ["tooltip"])
            ])
        ]),
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldSchemasNames, (fieldSchema) => {
                    return (_openBlock(), _createBlock(_component_or_input, {
                        key: fieldSchema,
                        "model-value": fieldSchema,
                        readonly: ""
                    }, null, 8, ["model-value"]));
                }), 128))
            ])
        ]),
        _: 1
    }, 8, ["label"]));
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-64640db6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "condition-item",
    "data-test": "condition-item"
};
const _hoisted_2 = { class: "composite-box" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a, _b, _c, _d, _e, _f;
    const _component_or_select = _resolveComponent("or-select");
    const _component_or_chips = _resolveComponent("or-chips");
    const _component_or_date_time_picker = _resolveComponent("or-date-time-picker");
    const _component_or_input = _resolveComponent("or-input");
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_or_select, {
            modelValue: _ctx.conditionModel.property,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.conditionModel.property) = $event)),
            "data-test": "column-label-select",
            class: "column-label-select",
            options: _ctx.schemaOptions,
            label: _ctx.$t('contacts.filterModal.conditions.dbColumnLabel'),
            placeholder: _ctx.$t('contacts.filterModal.conditions.dbColumnLabelPlaceholder'),
            "is-disabled": _ctx.isLoading,
            "is-invalid": _ctx.v$.conditionModel.property.$error,
            error: (_a = _ctx.v$.conditionModel.property.$silentErrors[0]) === null || _a === void 0 ? void 0 : _a.$message,
            onClose: _ctx.v$.conditionModel.property.$touch
        }, null, 8, ["modelValue", "options", "label", "placeholder", "is-disabled", "is-invalid", "error", "onClose"]),
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_or_select, {
                modelValue: _ctx.conditionModel.operator,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.conditionModel.operator) = $event)),
                "data-test": "operator-select",
                class: "operator-select",
                options: _ctx.operatorOptions,
                label: _ctx.$t('contacts.filterModal.conditions.operator'),
                placeholder: _ctx.$t('contacts.filterModal.conditions.operatorPlaceholder'),
                "is-disabled": _ctx.isLoading,
                "is-invalid": _ctx.v$.conditionModel.operator.$error,
                error: (_b = _ctx.v$.conditionModel.operator.$silentErrors[0]) === null || _b === void 0 ? void 0 : _b.$message,
                onClose: _ctx.v$.conditionModel.operator.$touch
            }, null, 8, ["modelValue", "options", "label", "placeholder", "is-disabled", "is-invalid", "error", "onClose"]),
            (_ctx.isArray)
                ? (_openBlock(), _createBlock(_component_or_chips, {
                    key: 0,
                    modelValue: _ctx.conditionModel.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.conditionModel.value) = $event)),
                    class: "value-chips",
                    label: _ctx.valueLabel,
                    "is-disabled": _ctx.isLoading,
                    "is-invalid": _ctx.v$.conditionModel.value.$error,
                    error: (_c = _ctx.v$.conditionModel.value.$silentErrors[0]) === null || _c === void 0 ? void 0 : _c.$message,
                    onBlur: _ctx.onBlur
                }, null, 8, ["modelValue", "label", "is-disabled", "is-invalid", "error", "onBlur"]))
                : (((_d = _ctx.schemaItem) === null || _d === void 0 ? void 0 : _d.typeOf) === 'date')
                    ? (_openBlock(), _createBlock(_component_or_date_time_picker, {
                        key: 1,
                        modelValue: _ctx.dateTimeValue,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.dateTimeValue) = $event)),
                        class: "value-date-time-picker",
                        label: _ctx.valueLabel,
                        "is-disabled": _ctx.isLoading,
                        "is-invalid": _ctx.v$.conditionModel.value.$error,
                        error: (_e = _ctx.v$.conditionModel.value.$silentErrors[0]) === null || _e === void 0 ? void 0 : _e.$message,
                        onBlur: _ctx.onBlur
                    }, null, 8, ["modelValue", "label", "is-disabled", "is-invalid", "error", "onBlur"]))
                    : (_openBlock(), _createBlock(_component_or_input, {
                        key: 2,
                        modelValue: _ctx.conditionModel.value,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.conditionModel.value) = $event)),
                        "data-test": "condition-value-textbox",
                        class: "value-textbox",
                        label: _ctx.valueLabel,
                        "is-disabled": _ctx.isLoading,
                        "is-invalid": _ctx.v$.conditionModel.value.$error,
                        error: (_f = _ctx.v$.conditionModel.value.$silentErrors[0]) === null || _f === void 0 ? void 0 : _f.$message,
                        onBlur: _ctx.onBlur
                    }, null, 8, ["modelValue", "label", "is-disabled", "is-invalid", "error", "onBlur"]))
        ]),
        _createVNode(_component_or_icon_button, {
            "data-test": "delete-item-button",
            class: "delete-button",
            "icon-color": "neutral-3",
            size: "small",
            icon: "delete",
            "is-disabled": _ctx.isLoading,
            onClick: _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('delete')))
        }, null, 8, ["is-disabled"])
    ]));
}

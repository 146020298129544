import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon = _resolveComponent("or-icon");
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createBlock(_component_or_button, {
        variant: "outlined",
        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('click')))
    }, {
        default: _withCtx(() => [
            _createVNode(_component_or_icon, { icon: "share" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('contactBooks.controls.share')), 1)
        ]),
        _: 1
    }));
}

import _ from 'lodash';
import { SchemaItemTypes } from '@/constants';
import getDefaultIdentifier from './getDefaultIdentifier';
function getEmptyContactFormValues(schema) {
    const arrayItemsTypes = [
        SchemaItemTypes.ARRAY_OF_STRINGS,
        SchemaItemTypes.ARRAY_OF_IDENTIFIERS,
        SchemaItemTypes.ARRAY_OF_OBJECTS,
    ];
    return _.reduce(schema, (acc, item, key) => {
        if (arrayItemsTypes.indexOf(item.typeOf) != -1) {
            acc[key] = [];
        }
        else if (item.typeOf === SchemaItemTypes.IDENTIFIER_OBJECT) {
            acc[key] = getDefaultIdentifier();
        }
        else {
            acc[key] = undefined;
        }
        return acc;
    }, {});
}
export default getEmptyContactFormValues;

import { ColumnTypes } from '@onereach/types-contacts-api';
import { defineComponent } from 'vue';
import { hasOwnProperty, isPropertyEmpty } from '@/utils';
import ContactsViewFieldArrayOfIdentifiers from './ContactsViewFieldArrayOfIdentifiers.vue';
import ContactsViewFieldArrayOfObjects from './ContactsViewFieldArrayOfObjects.vue';
import ContactsViewFieldArrayOfStrings from './ContactsViewFieldArrayOfStrings.vue';
import ContactsViewFieldDate from './ContactsViewFieldDate.vue';
import ContactsViewFieldIdentifierObject from './ContactsViewFieldIdentifierObject.vue';
import ContactsViewFieldMD from './ContactsViewFieldMD.vue';
import ContactsViewFieldString from './ContactsViewFieldString.vue';
const ViewComponents = {
    [ColumnTypes.string]: ContactsViewFieldString,
    [ColumnTypes.date]: ContactsViewFieldDate,
    [ColumnTypes.double]: ContactsViewFieldString,
    [ColumnTypes.md]: ContactsViewFieldMD,
    [ColumnTypes.integer]: ContactsViewFieldString,
    [ColumnTypes['array of strings']]: ContactsViewFieldArrayOfStrings,
    [ColumnTypes['array of objects']]: ContactsViewFieldArrayOfObjects,
    [ColumnTypes['array of identifiers']]: ContactsViewFieldArrayOfIdentifiers,
    [ColumnTypes['identifier object']]: ContactsViewFieldIdentifierObject,
};
export default defineComponent({
    name: 'ContactsViewField',
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: [Object, String, Number],
            required: false,
            default: () => undefined,
        },
        alwaysShow: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        return {
            ViewComponents,
        };
    },
    computed: {
        oldFieldSchema() {
            return {
                visibility: true,
                label: this.fieldSchema.label,
                singularLabel: this.fieldSchema.singular_label,
                order: 0,
                properties: this.fieldSchema.properties,
                triggers: [],
                typeOf: this.fieldSchema.type,
            };
        },
        isEmpty() {
            return isPropertyEmpty(this.data);
        },
        isComponentImplemented() {
            return this.fieldSchema.type in ViewComponents;
        },
        component() {
            return hasOwnProperty(ViewComponents, this.fieldSchema.type)
                ? ViewComponents[this.fieldSchema.type]
                : null;
        },
    },
});

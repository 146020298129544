import { OrTagV3 as OrTag } from '@onereach/ui-components';
import _ from 'lodash';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsViewFieldArrayOfObjects',
    components: {
        OrTag,
    },
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        convertedData() {
            var _a, _b;
            return (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.map(objValue => {
                var _a;
                return ({
                    val: _.join(_.toPairs(objValue).filter(([key]) => key !== 'type')
                        .map(([, value]) => value).filter(value => !_.isEmpty(value)), ', '),
                    type: (_a = objValue.type) !== null && _a !== void 0 ? _a : [],
                });
            })) !== null && _b !== void 0 ? _b : [];
        },
    },
});

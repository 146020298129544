import useVuelidate from '@vuelidate/core';
import { defineComponent, ref } from 'vue';
import ContactBooksSchemaEditor from '../ContactBooksSchemaEditor';
export default defineComponent({
    name: 'ContactBooksSettingsForm',
    components: {
        ContactBooksSchemaEditor,
    },
    props: {
        book: {
            type: Object,
            required: true,
        },
        initTriggers: {
            type: Array,
            required: true,
        },
        groupMapping: {
            type: Object,
            required: true,
        },
        requiredFields: {
            type: Object,
            required: true,
        },
        requiredFieldsProperties: {
            type: Object,
            required: true,
        },
    },
    emits: ['submit'],
    setup() {
        const schemaEditor = ref();
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            schemaEditor,
            v$,
        };
    },
    validations() {
        return {
            schemaEditor: {
                notChanged: (editor) => editor.isChanged,
            },
        };
    },
    methods: {
        submit() {
            var _a;
            this.$emit('submit', (_a = this.schemaEditor) === null || _a === void 0 ? void 0 : _a.schemaToOutput);
        },
        scrollToFirstInvalidItem() {
            var _a;
            return (_a = this.schemaEditor) === null || _a === void 0 ? void 0 : _a.scrollToFirstInvalidItem();
        },
    },
});

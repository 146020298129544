import { OrPaginationV3 as OrPagination } from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import { computed, defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsPagination2',
    components: {
        OrPagination,
    },
    props: {
        length: {
            type: Number,
            required: true,
        },
        pagination: {
            type: Object,
            required: true,
        },
    },
    emits: ['update:pagination'],
    setup(props, { emit }) {
        const localPagination = useVModel(props, 'pagination', emit);
        const localLength = computed(() => props.length || 0);
        return {
            localPagination,
            localLength,
        };
    },
});

import { OrButtonV3 as OrButton, OrTagV3 as OrTag } from '@onereach/ui-components';
import { defineComponent } from 'vue';
import { getDeletedFromText } from '@/components/Contacts/ContactsShare/const';
export default defineComponent({
    name: 'ArchivedShareItemActions',
    components: {
        OrButton,
        OrTag,
    },
    props: {
        meta: {
            type: Object,
            required: false,
            default: () => { },
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        showDeletedTag: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['restore'],
    computed: {
        getDeletedFromText() {
            return getDeletedFromText(this.$t, this.meta);
        },
    },
    methods: {
        restoreSharingMeta() {
            var _a;
            this.$emit('restore', (_a = this.meta) === null || _a === void 0 ? void 0 : _a.id);
        },
    },
});

import { OrIconButtonV3 } from '@onereach/ui-components';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsExportTrigger',
    components: {
        OrIconButtonV3,
    },
    props: {
        count: {
            type: Number,
            required: false,
            default: () => null,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['click'],
});

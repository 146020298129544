import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsTableDataCellDate',
    props: {
        value: {
            type: [String, Date, Number],
            required: false,
            default: null,
        },
    },
    computed: {
        text() {
            return this.value
                ? new Date(this.value).toLocaleString('en-US', { timeZone: 'UTC' })
                : '';
        },
    },
});

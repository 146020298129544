import { defineComponent } from 'vue';
import _ from 'lodash';
import ContactFormField from './ContactFormField';
import { getEmptyContactFormValues, isPropertyEmpty, convertSchemaToOrderedArray } from '@/utils';
const hiddenFieldKeys = [
    'contactId',
    'created',
    'modified',
    'lastHitlEventTimestamp',
];
export default defineComponent({
    name: 'ContactsForm',
    components: {
        ContactFormField,
    },
    props: {
        schema: {
            type: Object,
            required: true,
        },
        defaultValue: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data(props) {
        return {
            localValues: _.defaults(_.cloneDeep(props.defaultValue), getEmptyContactFormValues(this.schema)),
        };
    },
    computed: {
        orderedSchemaItems() {
            return convertSchemaToOrderedArray(this.schema).filter(({ key }) => !hiddenFieldKeys.includes(key));
        },
        cleanDefaultValue() {
            return _.pickBy(this.defaultValue, _.negate(isPropertyEmpty));
        },
        cleanLocalValue() {
            return _.pickBy(this.localValues, _.negate(isPropertyEmpty));
        },
        isChanged() {
            return !_.isEqual(this.cleanDefaultValue, this.cleanLocalValue);
        },
    },
    methods: {
        submit() {
            this.$emit('submit', this.localValues);
        },
        handleUpdate(key, value) {
            this.localValues[key] = value;
        },
    },
});

import { defineComponent, computed } from 'vue';
import { SchemaItemTypes } from '@/constants';
import { hasOwnProperty } from '@/utils';
import ContactFormFieldArrayOfIdentifiers from './ContactFormFieldArrayOfIdentifiers.vue';
import ContactFormFieldArrayOfObjects from './ContactFormFieldArrayOfObjects.vue';
import ContactFormFieldArrayOfStrings from './ContactFormFieldArrayOfStrings.vue';
import ContactFormFieldDate from './ContactFormFieldDate.vue';
import ContactFormFieldDouble from './ContactFormFieldDouble.vue';
import ContactFormFieldIdentifierObject from './ContactFormFieldIdentifierObject.vue';
import ContactFormFieldInteger from './ContactFormFieldInteger.vue';
import ContactFormFieldMD from './ContactFormFieldMD.vue';
import ContactFormFieldString from './ContactFormFieldString.vue';
const FieldComponents = {
    [SchemaItemTypes.STRING]: ContactFormFieldString,
    [SchemaItemTypes.DATE]: ContactFormFieldDate,
    [SchemaItemTypes.DOUBLE]: ContactFormFieldDouble,
    [SchemaItemTypes.MD]: ContactFormFieldMD,
    [SchemaItemTypes.INTEGER]: ContactFormFieldInteger,
    [SchemaItemTypes.ARRAY_OF_STRINGS]: ContactFormFieldArrayOfStrings,
    [SchemaItemTypes.ARRAY_OF_OBJECTS]: ContactFormFieldArrayOfObjects,
    [SchemaItemTypes.ARRAY_OF_IDENTIFIERS]: ContactFormFieldArrayOfIdentifiers,
    [SchemaItemTypes.IDENTIFIER_OBJECT]: ContactFormFieldIdentifierObject,
};
export default defineComponent({
    name: 'ContactFormField',
    props: {
        fieldSchema: {
            type: Object,
            required: true,
        },
        data: {
            type: [Object, String, Number],
            required: false,
            default: () => undefined,
        },
    },
    emits: ['update'],
    setup(props) {
        const isComponentImplemented = computed(() => props.fieldSchema.typeOf in FieldComponents);
        const component = computed(() => hasOwnProperty(FieldComponents, props.fieldSchema.typeOf)
            ? FieldComponents[props.fieldSchema.typeOf]
            : null);
        const showTitle = computed(() => ![
            SchemaItemTypes.STRING,
            SchemaItemTypes.DOUBLE,
            SchemaItemTypes.INTEGER,
            SchemaItemTypes.DATE,
        ].includes(props.fieldSchema.typeOf));
        return {
            component,
            FieldComponents,
            isComponentImplemented,
            showTitle,
        };
    },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_date_time_picker = _resolveComponent("or-date-time-picker");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_or_date_time_picker, {
            modelValue: _ctx.localData,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localData) = $event)),
            label: _ctx.fieldSchema.label
        }, null, 8, ["modelValue", "label"])
    ]));
}

import { ColumnTypes } from '@onereach/types-contacts-api';
function getDefaultNewFieldV2() {
    return {
        label: '',
        type: ColumnTypes.string,
        machine_name: '',
        properties: {},
    };
}
export default getDefaultNewFieldV2;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.94.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts-tabs[data-v-d75d15b8] .or-tabs-v3 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    min-height: 36px;
    width: 100%;
    max-width: min(1376px,calc(100% - 64px));
    padding-top: 16px
}
@media (min-width: 900px) {
.contacts-tabs[data-v-d75d15b8] .or-tabs-v3 {
        padding-top: 32px
}
}
[force-screen~="md"] .contacts-tabs[data-v-d75d15b8] .or-tabs-v3 {
    padding-top: 32px
}
.contacts-tabs[data-v-d75d15b8] .or-tabs-v3[force-screen~="md"] {
    padding-top: 32px
}`, "",{"version":3,"sources":["webpack://./src/layouts/RoutingTabsLayout.vue"],"names":[],"mappings":"AAGE;IAAA,eAAA;IAAA,kBAAA;IAAA,iBAAA;IAAA,kBAAA;IAAA,gBAAA;IAAA,WAAA;IAAA,wCAAA;IAAA;AAAA;AAAA;AAAA;QAAA;AAAA;AAAA;AAAA;IAAA;AAAA;AAAA;IAAA;AAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

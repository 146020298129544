import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-51847cd2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "grouped-list" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_books_schema_editor_item_list_group = _resolveComponent("contact-books-schema-editor-item-list-group");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupNames, (groupName) => {
            return (_openBlock(), _createBlock(_component_contact_books_schema_editor_item_list_group, {
                key: groupName,
                items: _ctx.groupedItems[groupName],
                "group-name": groupName,
                "is-opened": _ctx.openedGroups[groupName],
                "with-add-button": groupName === _ctx.customGroupName,
                "onUpdate:isOpened": ($event) => (_ctx.toggle(groupName, $event)),
                onAddColumn: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('addColumn')))
            }, {
                item: _withCtx((itemAttrs) => [
                    _renderSlot(_ctx.$slots, "item", _mergeProps({ ref_for: true }, itemAttrs), undefined, true)
                ]),
                _: 2
            }, 1032, ["items", "group-name", "is-opened", "with-add-button", "onUpdate:isOpened"]));
        }), 128))
    ]));
}

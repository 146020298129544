import { OrTagInputV3, OrInputV3 as OrInput, OrIconButtonV3 as OrIconButton, OrExpansionPanelV3 as OrExpansionPanel, } from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import _ from 'lodash';
import { defineComponent } from 'vue';
import { ArrayOfObjectPropertyTypes } from '@/constants';
import { getDefaultObjectValue } from '@/utils';
export default defineComponent({
    name: 'ContactFormFieldObjectArrayItem',
    components: {
        OrInput,
        OrIconButton,
        OrExpansionPanel,
        OrTagInputV3,
    },
    props: {
        properties: {
            type: Object,
            required: true,
        },
        singularLabel: {
            type: String,
            required: false,
            default: 'Value',
        },
        index: {
            type: Number,
            required: false,
            default: 0,
        },
        item: {
            type: Object,
            required: false,
            default: () => { },
        },
        capitalizeProperties: {
            type: Boolean,
            required: false,
            default: false,
        },
        nameToReplaceWithSingular: {
            type: String,
            required: false,
            default: null,
        },
    },
    emits: ['update:item', 'delete:item'],
    setup(props, { emit }) {
        const itemModel = useVModel(props, 'item', emit);
        _.defaults(itemModel.value, getDefaultObjectValue(props.properties));
        return {
            ArrayOfObjectPropertyTypes,
            itemModel,
        };
    },
    computed: {
        itemName() {
            return `${this.singularLabel} ${this.index + 1}`;
        },
    },
    methods: {
        getLabel(name) {
            return this.nameToReplaceWithSingular === name ? this.singularLabel :
                this.capitalizeProperties ? _.capitalize(name) : name;
        },
        handleDelete() {
            this.$emit('delete:item', this.index);
        },
    },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "layout-column" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrMenuItemV3 = _resolveComponent("OrMenuItemV3");
    const _component_RouterLink = _resolveComponent("RouterLink");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
            return (_openBlock(), _createBlock(_component_RouterLink, {
                key: menuItem.label,
                to: menuItem.to,
                "exact-active-class": "!typography-body-2-bold bg-primary-opacity-0-08 dark:bg-primary-opacity-0-08-dark md:rounded-md"
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_OrMenuItemV3, {
                        icon: menuItem.icon
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(menuItem.label), 1)
                        ]),
                        _: 2
                    }, 1032, ["icon"])
                ]),
                _: 2
            }, 1032, ["to"]));
        }), 128))
    ]));
}

import _ from 'lodash';
import { defineStore } from 'pinia';
import { ContactsApi, ContactsSettingsApi } from '@/api';
import { TranslatableError } from '@/errors';
import { getStoreIdWithoutVersion } from '@/utils';
export const useAccessSettingsStore = defineStore('accessSettings', {
    state: () => ({
        settings: {},
        sharedUsers: {},
    }),
    getters: {
        getUsersByBookName: (state) => {
            return (bookName) => {
                return state.settings[bookName];
            };
        },
        getSharedUsers: (state) => {
            return (bookName, accountId) => {
                return _.get(state.sharedUsers, `[${bookName}][${accountId}]`);
            };
        },
        getUserById: (state) => {
            return (bookName, id) => {
                return _.find(state.settings[bookName], { id });
            };
        },
        getUserByAccountId: (state) => {
            return (bookName, accountId) => {
                return _.find(state.settings[bookName], { accountId });
            };
        },
    },
    actions: {
        async getUsers(bookName) {
            this.settings[bookName] = await ContactsApi.getUsersList({
                bookName,
            });
        },
        async updateUserRuleGroups(payload) {
            var _a;
            const updateInfo = await ContactsApi.updateUserContactRuleGroups(payload);
            const users = this.settings[updateInfo.bookName];
            const user = (_a = _.find(users, {
                accountId: updateInfo.accountId,
                id: updateInfo.userId,
            })) !== null && _a !== void 0 ? _a : _.find(this.getSharedUsers(updateInfo.bookName, updateInfo.accountId), {
                accountId: updateInfo.accountId,
                id: updateInfo.userId,
            });
            if (user)
                _.set(user, 'data.meta.contacts.ruleGroups', updateInfo.ruleGroups);
        },
        async patchUserRuleGroups(payload) {
            var _a;
            const patchInfo = await ContactsApi.getUsersList(payload);
            const { userId, bookName, userAccountId: accountId, } = payload;
            const users = this.settings[bookName];
            const user = (_a = _.find(users, {
                accountId,
                id: userId,
            })) !== null && _a !== void 0 ? _a : _.find(this.getSharedUsers(bookName, accountId), {
                accountId: accountId,
                id: userId,
            });
            if (user)
                _.set(user, 'data.meta.contacts.ruleGroups', patchInfo.contactRuleGroups);
        },
        async getUsersListFromAnotherAccount(dto) {
            try {
                _.set(this.sharedUsers, `[${dto.name}][${dto.accountId}]`, await ContactsSettingsApi.getUsersListFromAnotherAccount(dto));
            }
            catch (e) {
                if (e instanceof Error && e.message.includes('status code 400')) {
                    _.set(this.sharedUsers, `[${dto.name}][${dto.accountId}]`, undefined);
                    if ('cause' in e && _.isFunction(e.cause)) {
                        const cause = e.cause();
                        const errorMessage = _.get(cause, 'response.data.error');
                        if (typeof errorMessage === 'string') {
                            if (errorMessage.includes('not approved to share'))
                                throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.notApprovedToShare`, e);
                        }
                    }
                }
                throw e;
            }
        },
        deleteCachedUsersListFromAnotherAccount(dto) {
            _.set(this.sharedUsers, `[${dto.name}][${dto.bookAccountId}]`, undefined);
        },
    },
});
export default useAccessSettingsStore;

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createBlock(_component_or_button, {
        "data-test": "contact-table-settings-button",
        type: "text",
        color: "neutral-4",
        icon: "visibility_off",
        "icon-position": "left",
        content: _ctx.$t('contacts.hideFields'),
        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('click')))
    }, null, 8, ["content"]));
}

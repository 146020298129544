import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-0635502f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "book-status-container"
};
const _hoisted_2 = { class: "status-text" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_ctx.isStatusShown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
                class: _normalizeClass(["status-lamp", _ctx.lampClass])
            }, null, 2),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.labelText), 1)
        ]))
        : _createCommentVNode("", true);
}

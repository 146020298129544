import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex justify-end gap-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_book_settings_form = _resolveComponent("contact-book-settings-form");
    const _component_or_button = _resolveComponent("or-button");
    const _component_OrModalV3 = _resolveComponent("OrModalV3");
    return (_openBlock(), _createBlock(_component_OrModalV3, {
        ref: "modalComponent",
        size: "m"
    }, {
        header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('contactBooks.settingsModal.title')), 1)
        ]),
        footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_or_button, {
                    type: "outline",
                    content: _ctx.$t('common.cancel'),
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close')))
                }, null, 8, ["content"]),
                _createVNode(_component_or_button, {
                    content: _ctx.$t('common.save'),
                    "is-disabled": _ctx.notChanged,
                    "is-loading": _ctx.isSubmitting,
                    onClick: _ctx.triggerSubmit
                }, null, 8, ["content", "is-disabled", "is-loading", "onClick"])
            ])
        ]),
        default: _withCtx(() => [
            _createVNode(_component_contact_book_settings_form, {
                ref: "bookSettingsForm",
                "error-reference": "bookSettingsForm",
                book: _ctx.book,
                "schema-presets": _ctx.schemaPresets,
                "group-mapping": _ctx.groupMapping,
                "init-triggers": _ctx.initTriggers,
                "required-fields": _ctx.requiredFields,
                "required-fields-properties": _ctx.requiredFieldsProperties,
                onSubmit: _ctx.handleSubmit
            }, null, 8, ["book", "schema-presets", "group-mapping", "init-triggers", "required-fields", "required-fields-properties", "onSubmit"])
        ]),
        _: 1
    }, 512));
}

function getDefaultObjectValue(properties) {
    const object = {};
    Object.keys(properties).forEach((k) => {
        object[k] = properties[k] === 'string'
            ? ''
            : [];
    });
    return object;
}
export default getDefaultObjectValue;

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-b1eb7cac"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "merge-switch-item" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_or_switch = _resolveComponent("or-switch");
    const _component_or_select = _resolveComponent("or-select");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_or_switch, {
            modelValue: _ctx.isMergingEnabled,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.isMergingEnabled) = $event)),
            label: _ctx.$t('contacts.importModal.mergeDuplicatesBy')
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_or_select, {
            modelValue: _ctx.localValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.localValue) = $event)),
            class: "field-select",
            options: _ctx.columnsOptions,
            placeholder: _ctx.$t('contacts.importModal.selectColumnToMergeBy'),
            "is-loading": _ctx.isLoading,
            "is-disabled": !_ctx.isMergingEnabled,
            "is-invalid": _ctx.v$.localValue.$error,
            error: (_a = _ctx.v$.localValue.$errors[0]) === null || _a === void 0 ? void 0 : _a.$message,
            "use-search": "",
            "search-function": _ctx.searchColumnsOptions,
            onClose: _ctx.v$.localValue.$touch
        }, null, 8, ["modelValue", "options", "placeholder", "is-loading", "is-disabled", "is-invalid", "error", "search-function", "onClose"])
    ]));
}

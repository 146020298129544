import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_input = _resolveComponent("or-input");
    const _component_contacts_filter_form_match = _resolveComponent("contacts-filter-form-match");
    const _component_contacts_filter_form_condition_item = _resolveComponent("contacts-filter-form-condition-item");
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createElementBlock("form", {
        class: "filter-form",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => { }, ["prevent"]))
    }, [
        _createVNode(_component_or_input, {
            modelValue: _ctx.localValue.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.localValue.name) = $event)),
            "data-test": "filter-name",
            label: _ctx.$t('contacts.filterModal.name'),
            "is-invalid": _ctx.isNameInvalid,
            error: _ctx.nameErrorMessage,
            onBlur: _ctx.onNameBlur
        }, null, 8, ["modelValue", "label", "is-invalid", "error", "onBlur"]),
        _createVNode(_component_contacts_filter_form_match, {
            modelValue: _ctx.localValue.matchAllConditions,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.localValue.matchAllConditions) = $event)),
            "is-loading": _ctx.isLoading
        }, null, 8, ["modelValue", "is-loading"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.conditions, (condition, index) => {
            var _a;
            return (_openBlock(), _createBlock(_component_contacts_filter_form_condition_item, {
                key: (_a = _ctx.tryGetKey(condition)) !== null && _a !== void 0 ? _a : index,
                modelValue: _ctx.conditions[index],
                "onUpdate:modelValue": ($event) => ((_ctx.conditions[index]) = $event),
                schema: _ctx.schema,
                "form-items": _ctx.conditionItems,
                "is-loading": _ctx.isLoading,
                onDelete: ($event) => (_ctx.deleteConditionByIndex(index))
            }, null, 8, ["modelValue", "onUpdate:modelValue", "schema", "form-items", "is-loading", "onDelete"]));
        }), 128)),
        _createVNode(_component_or_button, {
            class: "add-button",
            type: "text",
            color: "primary",
            size: "small",
            "data-test": "add-condition",
            "is-disabled": _ctx.isLoading,
            icon: "add",
            "icon-position": "left",
            content: _ctx.$t('contacts.filterModal.conditions.add'),
            onClick: _ctx.addCondition
        }, null, 8, ["is-disabled", "content", "onClick"])
    ], 32));
}

import { OrButtonV3 as OrButton, useResponsive } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
import OrConditionalSidebarModal from '../../OrConditionalSidebarModal.vue';
import { ContactFormV2 } from '../ContactForm';
export default defineComponent({
    name: 'ContactAddModal',
    components: {
        OrConditionalSidebarModal,
        OrButton,
        ContactForm: ContactFormV2,
    },
    props: {
        isSubmitting: {
            type: Boolean,
            required: false,
            default: false,
        },
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['add', 'close'],
    expose: ['openComponent', 'closeComponent'],
    setup() {
        const contactForm = ref();
        const conditionalSidebar = ref();
        const openComponent = () => { var _a; (_a = conditionalSidebar.value) === null || _a === void 0 ? void 0 : _a.openCom(); };
        const closeComponent = () => { var _a; (_a = conditionalSidebar.value) === null || _a === void 0 ? void 0 : _a.closeCom(); };
        const { isMobile } = useResponsive();
        return {
            contactForm,
            conditionalSidebar,
            openComponent,
            closeComponent,
            isMobile,
        };
    },
    computed: {
        isChanged() {
            var _a, _b;
            return (_b = (_a = this.contactForm) === null || _a === void 0 ? void 0 : _a.isChanged) !== null && _b !== void 0 ? _b : false;
        },
    },
    methods: {
        triggerSubmit() {
            var _a;
            (_a = this.contactForm) === null || _a === void 0 ? void 0 : _a.submit();
        },
        handleClose() {
            this.closeComponent();
            this.$emit('close');
        },
    },
});

import { defineComponent } from 'vue';
import { OrCheckbox } from '@onereach/ui-components-legacy';
import { useVModel } from '@vueuse/core';
import _ from 'lodash';
export default defineComponent({
    name: 'ContactsColumnsForm',
    components: {
        OrCheckbox,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        modelValue: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        // something is wrong with 'deep' in useVModel, so have to emit update manually
        const columnsModel = useVModel(props, 'modelValue', emit, { deep: true });
        return {
            columnsModel,
        };
    },
    computed: {
        mainCheckboxState() {
            if (this.isAllSelected)
                return { icon: 'done' };
            if (this.isAnySelected)
                return { icon: 'remove' };
            return { icon: '' };
        },
        isAnySelected() {
            return _.some(this.columnsModel, 'value');
        },
        isAllSelected() {
            return _.every(this.columnsModel, 'value');
        },
    },
    methods: {
        handleSelect(index) {
            this.columnsModel[index].value = !this.columnsModel[index].value;
            this.$emit('update:modelValue', this.columnsModel);
        },
        handleSelectAll() {
            const value = !this.isAllSelected && !this.isAnySelected;
            _.forEach(this.columnsModel, (column) => _.set(column, 'value', value));
            this.$emit('update:modelValue', this.columnsModel);
        },
    },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-3ab7cdff"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "header-cell-content" };
const _hoisted_2 = { class: "text-content" };
const _hoisted_3 = {
    key: 1,
    class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.canBeOrdered)
                ? (_openBlock(), _createBlock(_component_or_button, {
                    key: 0,
                    label: _ctx.value,
                    size: "small",
                    type: "text",
                    content: _ctx.value,
                    icon: _ctx.orderMode !== null ? _ctx.icon : '',
                    "icon-position": "right",
                    color: "neutral-6",
                    "is-text": "",
                    onClick: _ctx.changeOrder
                }, null, 8, ["label", "content", "icon", "onClick"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.value), 1))
        ]),
        _createElementVNode("div", {
            ref: "resizeBarRef",
            class: _normalizeClass(["resize-bar", { resizing: _ctx.isResizing, 'can-be-resized': _ctx.canBeResizedNow }])
        }, null, 2)
    ]));
}

import { defineComponent } from 'vue';
import ContactBooksGridItem from './ContactBooksGridItem.vue';
export default defineComponent({
    name: 'ContactBooksGrid',
    components: {
        ContactBooksGridItem,
    },
    props: {
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['open', 'migrate', 'delete', 'share'],
});

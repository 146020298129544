import _ from 'lodash';
import { isPropertyEmpty } from './index';
function getContactPropertiesToShow(contacts) {
    return _.reduce(contacts, (result, contact) => {
        _.each(contact.fieldValues, (value) => { if (!isPropertyEmpty(value.value))
            result[value.schemaId] = true; });
        return result;
    }, { contactId: true });
}
export default getContactPropertiesToShow;

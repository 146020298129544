import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-6f64c0a4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "profile-container" };
const _hoisted_2 = { class: "name" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_avatar = _resolveComponent("or-avatar");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_or_avatar, {
            src: _ctx.profileImage,
            class: "picture"
        }, null, 8, ["src"]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1)
    ]));
}

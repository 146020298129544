import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["href"];
const _hoisted_2 = { class: "flex flex-row justify-between" };
const _hoisted_3 = { class: "self-center text-center flex flex-col gap-[8px] max-w-full" };
const _hoisted_4 = {
    class: "typography-headline-4 overflow-hidden whitespace-nowrap text-ellipsis",
    "data-test": "contact-book-item-name"
};
const _hoisted_5 = { class: "typography-caption-regular text-outline dark:text-outline-dark" };
const _hoisted_6 = {
    class: "typography-caption-regular text-outline dark:text-outline-dark",
    "data-test": "contact-book-created-date"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_books_status = _resolveComponent("contact-books-status");
    const _component_contact_book_overflow_menu = _resolveComponent("contact-book-overflow-menu");
    const _component_or_card = _resolveComponent("or-card");
    return (_openBlock(), _createElementBlock("a", {
        href: _ctx.needConfirmation ? undefined : _ctx.resolvedRoute,
        class: _normalizeClass(_ctx.needConfirmation ? 'cursor-default' : 'cursor-pointer'),
        "data-test": "contact-book-item",
        onClick: _cache[4] || (_cache[4] =
            //@ts-ignore
            (...args) => (_ctx.beforeOpen && _ctx.beforeOpen(...args)))
    }, [
        _createVNode(_component_or_card, {
            interactive: "",
            class: "flex flex-col !justify-between h-[184px] md:h-[272px] overflow-hidden"
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_contact_books_status, {
                        sharer: _ctx.item.sharedBy,
                        state: _ctx.bookSharingState
                    }, null, 8, ["sharer", "state"]),
                    _createVNode(_component_contact_book_overflow_menu, {
                        "is-readonly": _ctx.isReadonly,
                        "share-status": _ctx.bookSharingState,
                        class: "ml-auto",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["stop"])),
                        onDelete: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('delete', _ctx.item.id))),
                        onDeleteWithContacts: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('deleteWithContacts', _ctx.item.id))),
                        onOpen: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('open', _ctx.item.id))),
                        onUpdate: _ctx.handleUpdate
                    }, null, 8, ["is-readonly", "share-status", "onUpdate"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.item.label), 1),
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$tc('contactBooks.contact', _ctx.item.contactsCount)), 1)
                ]),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.updatedAtTimeString), 1)
            ]),
            _: 1
        })
    ], 10, _hoisted_1));
}

import { OrButtonV2 as OrButton, OrSwitch } from '@onereach/ui-components-legacy';
import { useLocalStorage } from '@vueuse/core';
import _ from 'lodash';
import { defineComponent, ref } from 'vue';
import draggable from 'vuedraggable';
import { convertSchemaToOrderedArray, convertOrderedArrayToSchema, getDefaultNewField } from '@/utils';
import ContactBooksSchemaEditorItem from './ContactBooksSchemaEditorItem.vue';
import ContactBooksSchemaEditorItemListGrouped from './ContactBooksSchemaEditorItemListGrouped.vue';
export default defineComponent({
    name: 'ContactBooksSchemaEditor',
    components: {
        ContactBooksSchemaEditorItem,
        ContactBooksSchemaEditorItemListGrouped,
        OrButton,
        OrSwitch,
        draggable,
    },
    props: {
        schema: {
            type: Object,
            required: true,
        },
        initTriggers: {
            type: Array,
            required: true,
        },
        groupMapping: {
            type: Object,
            required: false,
            default: null,
        },
        requiredFields: {
            type: Array,
            required: false,
            default: () => [],
        },
        requiredFieldsProperties: {
            type: Object,
            required: false,
            default: () => { },
        },
    },
    emits: ['update'],
    setup() {
        const isGroupedView = useLocalStorage('isGroupedViewOnSchemaEditor', true);
        const groupedList = ref();
        return {
            isGroupedView,
            groupedList,
        };
    },
    data(props) {
        return {
            items: convertSchemaToOrderedArray(props.schema),
            lastKey: 0,
            keysMap: new WeakMap(),
            editorItems: new Set(),
        };
    },
    computed: {
        schemaToOutput() {
            return convertOrderedArrayToSchema(this.items);
        },
        hasNewItem() {
            return _.some(this.items, ({ isNew = false }) => isNew);
        },
        isChanged() {
            return !_.isEqual(this.schema, this.schemaToOutput);
        },
        hasUnsaved() {
            return this.hasNewItem;
        },
        isGroupViewAvailable() {
            return !_.isEmpty(this.groupMapping);
        },
        allItemsNames() {
            return _.map(this.items, item => item.key);
        },
    },
    watch: {
        schema(val) {
            this.items = _.unionBy(convertSchemaToOrderedArray(val), this.items, 'key');
        },
    },
    methods: {
        handleDelete(index) {
            this.items.splice(index, 1);
        },
        handleUpdate(index, value) {
            const oldVal = this.items[index];
            const newVal = _.clone(value);
            this.items[index] = newVal;
            this.keysMap.set(newVal, this.keysMap.get(oldVal));
        },
        handleAddColumn() {
            this.items.push(getDefaultNewField(this.items.length));
        },
        getOrCreateTemporaryKey(item) {
            if (this.keysMap.has(item))
                return this.keysMap.get(item);
            const newTempKey = 'tempKey' + this.lastKey++;
            this.keysMap.set(item, newTempKey);
            return newTempKey;
        },
        scrollToFirstInvalidItem() {
            var _a;
            for (const item of this.editorItems)
                if (item.v$.$invalid) {
                    if (this.isGroupedView) {
                        //TODO: cannot scroll to item inside closed OrCollapse
                        (_a = this.groupedList) === null || _a === void 0 ? void 0 : _a.toggleGroupByItemKey(item.item.key, true);
                        item.$el.parentElement.parentElement.scrollIntoView({ behavior: 'smooth' });
                    }
                    item.$el.scrollIntoView({ behavior: 'smooth' });
                    item.toggleOpen(true);
                    return;
                }
        },
    },
});

import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactsTableDataCellArrayOfStrings',
    props: {
        contact: {
            type: Object,
            required: true,
        },
        columnKey: {
            type: String,
            required: true,
        },
    },
    computed: {
        value() {
            var _a;
            return ((_a = this.contact[this.columnKey]) !== null && _a !== void 0 ? _a : []);
        },
    },
});

import { defineStore } from 'pinia';
import ContactsApi from '@/api/ContactsApi';
const useFiltersStore = defineStore('filtersV1', {
    state: () => ({
        collections: {},
        filters: {},
    }),
    getters: {
        getFiltersByBookName: (state) => {
            return (bookName) => {
                return state.collections[bookName]
                    ? state.collections[bookName]
                    : [];
            };
        },
        getFilterById: (state) => (filterId) => state.filters[filterId],
    },
    actions: {
        async getFilters(bookName) {
            const data = await ContactsApi.getContactFiltersList({ bookName });
            this.collections[bookName] = data;
            data.forEach(filter => this.filters[filter.filterId] = filter);
        },
        async getFilter(filterId) {
            this.filters[filterId] = await ContactsApi.getContactFilter({ filterId });
        },
        async addFilter({ name, bookName, conditions, matchAllConditions }) {
            return await ContactsApi.addContactFilter({
                name,
                bookName,
                conditions,
                matchAllConditions,
            });
        },
        async updateFilter({ filterId, name, conditions, matchAllConditions }) {
            await ContactsApi.updateContactFilter({
                filterId,
                name,
                conditions,
                matchAllConditions,
            });
        },
        async deleteFilter(filterId) {
            await ContactsApi.deleteContactFilter({ filterId });
            delete this.filters[filterId];
        },
    },
});
export default useFiltersStore;

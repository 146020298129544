import { defineComponent, ref } from 'vue';
import _ from 'lodash';
import { OrIconButton, OrButtonV2 as OrButton, OrInput, OrSelect } from '@onereach/ui-components-legacy';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { ArrayOfObjectPropertyTypes, SpecialCharactersRegexp } from '@/constants';
import { alreadyExists, forbiddenChar } from '@/utils/validators';
export default defineComponent({
    name: 'ContactBooksSchemaEditorItemPropertiesItem',
    components: {
        OrIconButton,
        OrButton,
        OrInput,
        OrSelect,
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
        isDeletable: {
            type: Boolean,
            required: false,
            default: true,
        },
        otherProperties: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['delete', 'save'],
    setup(props) {
        const key = ref(props.property.key);
        const type = ref(ArrayOfObjectPropertyTypes.STRING);
        const v$ = useVuelidate({ $scope: 'ContactBooksSchemaEditor' });
        return {
            v$,
            key,
            type,
        };
    },
    computed: {
        typeOptions() {
            return _.map(ArrayOfObjectPropertyTypes, (item) => ({
                value: item,
                label: item,
            }));
        },
    },
    validations() {
        const other = ref(this.otherProperties);
        return {
            key: {
                required,
                minLengthValue: minLength(3),
                notSpecialChar: forbiddenChar(SpecialCharactersRegexp, this.$t('contactBooks.schemaEditor.propertyCharNotAllowed')),
                alreadyExists: alreadyExists(other, this.$t('contactBooks.schemaEditor.propertyAlreadyExists')),
            },
        };
    },
    methods: {
        handleAddProperty() {
            this.$emit('save', {
                type: this.type,
                key: this.key,
            });
        },
    },
});

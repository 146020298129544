import { OrSidebarV3 } from '@onereach/ui-components';
import { defineComponent } from 'vue';
import SettingsMenu from '@/components/Settings/SettingsMenu/SettingsMenu.vue';
import SettingsContainer from '@/containers/Settings/SettingsContainer.vue';
export default defineComponent({
    name: 'SettingsLayout',
    components: {
        OrSidebarV3,
        SettingsMenu,
        SettingsContainer,
    },
    setup() {
        return {};
    },
});

import { OrModalV3, OrNotificationV3, OrButtonV3, OrCheckboxGroupV3, OrSegmentedControlV3 } from '@onereach/ui-components';
import _ from 'lodash';
import { defineComponent, ref, computed } from 'vue';
import { ExportFormats } from '@/constants';
export default defineComponent({
    name: 'ContactExportModal',
    components: {
        OrModalV3,
        OrNotificationV3,
        OrButtonV3,
        OrCheckboxGroupV3,
        OrSegmentedControlV3,
    },
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        isError: {
            type: Boolean,
            required: false,
            default: false,
        },
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['export', 'close'],
    expose: ['close', 'open'],
    setup(props, { emit }) {
        const modal = ref();
        const fieldsToExport = ref(_.map(props.fieldSchemas, field => field.id));
        const formatModel = ref(ExportFormats.CSV);
        const exportFormatOptions = computed(() => {
            return [
                {
                    label: '.CSV',
                    value: ExportFormats.CSV,
                },
                {
                    label: '.JSON',
                    value: ExportFormats.JSON,
                },
            ];
        });
        const options = computed(() => {
            return [{
                    label: 'All fields',
                    children: _.map(props.fieldSchemas, field => {
                        var _a;
                        return ({
                            label: (_a = field.label) !== null && _a !== void 0 ? _a : field.id,
                            value: field.id,
                        });
                    }),
                }];
        });
        function open() {
            var _a;
            (_a = modal.value) === null || _a === void 0 ? void 0 : _a.open();
        }
        function close() {
            var _a;
            (_a = modal.value) === null || _a === void 0 ? void 0 : _a.close();
        }
        function handleExport() {
            emit('export', {
                format: formatModel.value,
                fields: fieldsToExport.value,
            });
        }
        return {
            modal,
            fieldsToExport,
            formatModel,
            open,
            close,
            exportFormatOptions,
            options,
            handleExport,
        };
    },
});

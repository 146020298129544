import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_icon = _resolveComponent("or-icon");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_overflow_menu = _resolveComponent("or-overflow-menu");
    return (_openBlock(), _createBlock(_component_or_overflow_menu, {
        items: _ctx.items,
        "is-disabled": _ctx.isDisabled
    }, {
        trigger: _withCtx(() => [
            _createVNode(_component_or_button, {
                type: "text",
                color: "neutral-4",
                "is-disabled": _ctx.isDisabled,
                content: _ctx.count ? _ctx.$t('contacts.export', { count: _ctx.count }) : _ctx.$t('contacts.exportAll')
            }, {
                prepend: _withCtx(() => [
                    _createVNode(_component_or_icon, {
                        icon: "upload",
                        class: "or-button-left-icon"
                    })
                ]),
                append: _withCtx(() => [
                    _createVNode(_component_or_icon, {
                        icon: "arrow_drop_down",
                        class: "or-button-right-icon"
                    })
                ]),
                _: 1
            }, 8, ["is-disabled", "content"])
        ]),
        _: 1
    }, 8, ["items", "is-disabled"]));
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_or_icon = _resolveComponent("or-icon");
    const _component_or_button = _resolveComponent("or-button");
    const _component_or_menu_item = _resolveComponent("or-menu-item");
    const _component_or_menu = _resolveComponent("or-menu");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_or_button, {
            ref: "button",
            "data-test": "add-contacts-button",
            onClick: _cache[0] || (_cache[0] = ($event) => { var _a; return ((_a = _ctx.menu) === null || _a === void 0 ? void 0 : _a.toggle()); })
        }, {
            default: _withCtx(() => {
                var _a;
                return [
                    _createVNode(_component_or_icon, { icon: "add" }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('contacts.add')) + " ", 1),
                    _createVNode(_component_or_icon, {
                        icon: ((_a = _ctx.menu) === null || _a === void 0 ? void 0 : _a.isOpen) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                    }, null, 8, ["icon"])
                ];
            }),
            _: 1
        }, 512),
        _createVNode(_component_or_menu, {
            ref: "menu",
            trigger: (_a = _ctx.button) === null || _a === void 0 ? void 0 : _a.root
        }, {
            default: _withCtx(() => [
                (!_ctx.isImportDisabled)
                    ? (_openBlock(), _createBlock(_component_or_menu_item, {
                        key: 0,
                        icon: "download",
                        onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('import')))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('contacts.import')), 1)
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true),
                _createVNode(_component_or_menu_item, {
                    icon: "person_add",
                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('add')))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('contacts.addSingle')), 1)
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["trigger"])
    ], 64));
}

import { AUTH_API_INJECTION_TOKEN, ACCOUNTS_API_INJECTION_TOKEN, USERS_API_INJECTION_TOKEN, APP_CONFIG, ExpirationOverlay, UserRoles, } from '@onereach/auth-ui-module';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, watch, provide, ref, onErrorCaptured } from 'vue';
import AccountsApi from '@/api/AccountsApi';
import AuthApi from '@/api/Auth';
import UsersApi from '@/api/UsersApi';
import { AppPage } from '@/components';
import { useContactsInitialData, useContactsInitialDataLegacy, useI18n } from '@/composables';
import config from '@/config';
import { TranslatableError } from '@/errors';
import SentryApi from '@/sentry';
import { useAuth } from '@/stores';
import * as stores from '@/stores';
import pkg from '../package.json';
export default defineComponent({
    components: {
        AppPage,
        ExpirationOverlay,
    },
    setup() {
        // Fix of Pinia plugin disabling
        _.entries(stores)
            .filter(([key, value]) => key.startsWith('use') && _.isFunction(value))
            .forEach(([, useCurrentStore]) => useCurrentStore());
        //provide api services to AuthPopover
        provide(AUTH_API_INJECTION_TOKEN, AuthApi);
        provide(ACCOUNTS_API_INJECTION_TOKEN, AccountsApi);
        provide(USERS_API_INJECTION_TOKEN, UsersApi);
        provide(APP_CONFIG, config);
        const lockScreenUsername = ref('');
        const showError = ref(false);
        const errorText = ref('');
        const { t } = useI18n();
        const userHasPermission = computed(() => role.value && role.value !== UserRoles.guest);
        const authStore = useAuth();
        const { authorized: isAuthorized, username, identityProvider, reactiveToken: token, cookiesAnotherUser, cookiesNoRights, cookiesExpired, userId, accountId, role, } = storeToRefs(authStore);
        useContactsInitialDataLegacy();
        useContactsInitialData();
        watch(isAuthorized, async (newIsAuthorized) => {
            if (newIsAuthorized) {
                lockScreenUsername.value = await authStore.getUserNameForLockScreen();
            }
        });
        function cookiesRenewHandler() {
            authStore.updateCookieExpiration();
        }
        onErrorCaptured((e) => {
            if (e instanceof TranslatableError) {
                // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
                errorText.value = t(e.translationKey);
                SentryApi.captureException(e.innerError);
            }
            else {
                errorText.value = t('errors.commonText');
                SentryApi.captureException(e);
            }
            showError.value = true;
            return false;
        });
        return {
            isAuthorized,
            cookiesAnotherUser,
            cookiesNoRights,
            username,
            accountId,
            authUrl: config.AUTH_UI_URL,
            identityProvider,
            token,
            userId,
            cookiesExpired,
            cookiesRenewHandler,
            role,
            config,
            userHasPermission,
            serviceTranslations: pkg.projectConfig.translations,
            lockScreenUsername,
            showError,
            errorText,
        };
    },
});

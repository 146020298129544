import { OrButtonV3 as OrButton, OrIconV3 as OrIcon } from '@onereach/ui-components';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ContactBooksShareButton',
    components: {
        OrButton,
        OrIcon,
    },
    emits: ['click'],
});

import { defineComponent } from 'vue';
import { BookSharingState } from '@/constants';
export default defineComponent({
    name: 'ContactBooksStatus',
    props: {
        state: {
            type: String,
            required: false,
            default: BookSharingState.NOT_SHARED,
        },
        sharer: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        isStatusShown() {
            return this.state !== BookSharingState.NOT_SHARED;
        },
        labelText() {
            switch (this.state) {
                case BookSharingState.NEED_CONFIRMATION:
                    return this.$t('contactBooks.needConfirmation');
                case BookSharingState.SHARED:
                    return this.$t('contactBooks.sharedBy', { name: this.sharer });
                case BookSharingState.DELETED_FROM_SOURCE:
                    return this.$t('contactBooks.deletedFromSource');
                case BookSharingState.CANCELED:
                    return this.$t('contactBooks.canceledFromSource');
                default:
                    return '';
            }
        },
        lampClass() {
            switch (this.state) {
                case BookSharingState.NEED_CONFIRMATION:
                case BookSharingState.CANCELED:
                    return 'is-need-confirmation';
                case BookSharingState.SHARED:
                    return 'is-shared';
                case BookSharingState.DELETED_FROM_SOURCE:
                    return 'is-deleted';
                default:
                    return '';
            }
        },
    },
});
